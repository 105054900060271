import React from 'react'

const PatientAdmitSummary = ({ patientAdmitData }) => {
    const total = (patientAdmitData?.SaleBill || []).flatMap(dataObj => dataObj?.SalesBillItems || []).reduce((a, c) => a +
        (parseFloat(c.pcsQty || 0) * parseFloat(c.salePrice)) - parseFloat(c.discountInAmount || 0)
        , 0)
    const patientLedgerDetails = patientAdmitData?.patientLedgerDetails;
    return (
        <div>
            <div className='text-center'>
                <div className='grid grid-cols-4'>
                    <table className="table-fixed w-full mt-5 text-left">
                        <tbody className="border-2">
                            <tr
                                className="border-2 table-row cursor-pointer"
                            >
                                <td className='py-1'> Pharma Bill </td>
                                <td className='py-1 text-right'> {parseFloat(patientLedgerDetails?.salesAmount || 0).toFixed(2)}</td>
                            </tr>
                            <tr
                                className="border-2 table-row cursor-pointer"
                            >
                                <td className='py-1'> Room Rent </td>
                                <td className='py-1 text-right'> {parseFloat(patientLedgerDetails?.totalRoomRent).toFixed(2)}</td>
                            </tr>
                            <tr
                                className="border-2 table-row cursor-pointer"
                            >
                                <td className='py-1'> Total Bill </td>
                                <td className='py-1 text-right'> {parseFloat(patientLedgerDetails?.totalPayableAmount).toFixed(2)}</td>
                            </tr>
                            <tr
                                className="border-2 table-row cursor-pointer"
                            >
                                <td className='py-1'> Paid Amount </td>
                                <td className='py-1 text-right'> {parseFloat(patientLedgerDetails?.paidAmount).toFixed(2)}</td>
                            </tr>
                            <tr
                                className="border-2 font-bold table-row cursor-pointer"
                            >
                                <td className='py-1 '> Balance Amount </td>
                                <td className='py-1 text-right'> {parseFloat(patientLedgerDetails?.balanceAmount).toFixed(2)}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    )
}

export default PatientAdmitSummary
