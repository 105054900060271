import React from 'react';
import { DropdownWithSearch } from '../Inputs';

export const TabletCosumptionData = ({ value, setValue, readOnly }) => {
    const medicineList = [
        { name: "1", value: "1" },
        { name: "1/2", value: "1/2" },

    ];
    return (
        <div>
            <DropdownWithSearch readOnly={readOnly} options={medicineList} value={value} setValue={(value) => setValue(value)} labelField='name' valueField='value' />
        </div>
    )
}




export const TonicCosumptionData = ({ value, setValue, readOnly }) => {
    const medicineList = [
        { name: "2.5", value: "2.5" },
        { name: "5", value: "5" },
        { name: "7.5", value: "7.5" },
        { name: "10", value: "10" },

    ];
    return (
        <div>
            <DropdownWithSearch readOnly={readOnly} options={medicineList} value={value} setValue={(value) => setValue(value)} labelField='name' valueField='value' />
        </div>
    )
}


