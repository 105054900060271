import React, { useMemo, useState } from 'react'
import { substract } from '../../../Utils/helper';
import { useGetStockDeliveryByIdQuery } from '../../../redux/PharmacyServices/StockDeliveryServices';

const FillGridPopup = ({ gridDetails, setGridDetails, setFillGrid, id, deliveryId }) => {
    const [localProductionDeliveryDetails, setLocaProductionDeliveryDetails] = useState(gridDetails);

    function handleDone() {
        setGridDetails(localProductionDeliveryDetails);
        setFillGrid(false);
    }
    const { data: StockTransfer } = useGetStockDeliveryByIdQuery({ id: deliveryId, params: { stockReceiptId: id, balQtyFilter: true } }, { skip: !deliveryId })

    let fillGridDetails = useMemo(() => StockTransfer?.data?.stockDeliveryItems ? StockTransfer?.data?.stockDeliveryItems.map(i => ({ ...i, stockDeliveryItemsId: i.id })) : [], [StockTransfer])

    function addItem(item) {
        setLocaProductionDeliveryDetails(localInwardItems => {
            let newItems = structuredClone(localInwardItems);
            newItems.push({ ...item, stockDeliveryItemsId: item.id });
            return newItems
        });
    }
    function removeItem(removeItem) {
        setLocaProductionDeliveryDetails(localInwardItems => {
            return localInwardItems.filter(item =>
                !(
                    removeItem.stockDeliveryItemsId === item.stockDeliveryItemsId
                )
            )
        });
    }

    function isItemChecked(checkItem) {
        let item = localProductionDeliveryDetails.find(item =>
            checkItem.stockDeliveryItemsId === item.stockDeliveryItemsId
        )
        if (!item) return false
        return true
    }


    function handleCheckBoxChange(value, item) {
        if (value) {
            addItem(item)
        } else {
            removeItem(item)
        }
    }


    function handleSelectAllChange(value) {
        if (value) {
            fillGridDetails.forEach(item => addItem(item))
        } else {
            fillGridDetails.forEach(item => removeItem(item))
        }
    }

    function getSelectAll() {
        return fillGridDetails.every(item => isItemChecked(item))
    }

    return (
        <div>
            <div className={`bg-gray-200 z-50 overflow-auto w-full`}>
                <div className="md:flex md:items-center md:justify-between page-heading p-1">
                    <div className="heading text-center md:mx-10 text-xs"> Stock Delivery Items</div>
                </div>
                <table className="border border-gray-500 w-full text-xs text-start">
                    <thead className="border border-gray-500">
                        <tr>
                            <th className='w-8 p-5'>
                                Mark All
                                <input type="checkbox" className='w-full' onChange={(e) => handleSelectAllChange(e.target.checked)}
                                    checked={getSelectAll()}
                                />
                            </th>
                            <th className="table-data  w-2 text-center p-0.5">S.no</th>
                            <th className="table-data ">Brand</th>
                            <th className="table-data ">Product<span className="text-red-500 p-5">*</span></th>
                            <th className="table-data  w-16">Batch No.</th>
                            <th className="table-data  w-28">Exp. Date</th>
                            <th className="table-data  w-16">Conversion</th>
                            <th className="table-data  w-16">Mrp</th>
                            <th className="table-data  w-16 p-0.5">Rack</th>
                            <th className="table-data  w-16 p-0.5">Bin</th>
                            <th className="table-data  w-20">Del.Qty</th>
                            <th className="table-data  w-20">A.Rec.Qty</th>
                            <th className="border border-gray-500">Bal. Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fillGridDetails.map((item, index) =>
                            <tr key={index} className='table-row' onClick={() => {
                                handleCheckBoxChange(!isItemChecked(item), { ...item, stockDeliveryItemsId: item.id, pcsQty: 0, rackId: "", binId: '', deliveryQty: item.pcsQty, price: item.purchasePrice })
                            }}>
                                <td className='table-data'>
                                    <input type="checkbox" className='w-full table-data-input'
                                        checked={isItemChecked({ ...item, stockDeliveryItemsId: item.id })} />
                                </td>
                                <td>{index + 1}</td>
                                <td>{item?.brandName}</td>
                                <td className='text-center'>{item?.productName}</td>
                                <td className='text-center'>{item?.batchNo}</td>
                                <td className='text-center'>{item?.expiryDate}</td>
                                <td className='text-right'>{item?.uomName}</td>
                                <td className='text-right'>{item?.salePrice}</td>
                                <td className='text-right'>{item?.rackName}</td>
                                <td className='text-right'>{item?.binName}</td>
                                <td className='text-right'>{item.pcsQty}</td>
                                <td className='text-right'>{item.alreadyReceivedQty}</td>
                                <td className='text-right'>{substract(item.pcsQty, item.alreadyReceivedQty)}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className='flex justify-end'>
                <button className='p-1 bg-blue-400 rounded-lg' onClick={handleDone}>Done</button>
            </div>
        </div>
    )
}

export default FillGridPopup;