import React from 'react'
import { View, Text, Image } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import kmLogo from "../../../assets/pinnacle-full.png";
import { ADDRESS, APP_NAME, CITY_ADDRESS, GST_IN } from '../../../Strings';

const Header = () => {
    const logoSize = '150';
    return (
        <View style={tw("w-full ")}>
            <View style={tw("flex flex-row items-center w-full justify-between pt-3")}>
                <View style={tw("w-[100%] flex flex-row ")}>
                    <View style={tw("flex flex-row justify-center items-center")}>
                        <Image src={kmLogo} style={tw(`w-[${logoSize}px]] `)} />
                    </View>
                    <View style={tw("flex flex-col items-center")}>
                        <Text style={tw(" text-[15px] text-gray-800 text-center font-[Times-Bold]")} >{APP_NAME}</Text>
                        <Text style={tw(" text-[9px]")} >{ADDRESS}</Text>
                        <View style={tw("flex flex-row gap-1")} >
                            <Text style={tw("text-[9px]")} >{CITY_ADDRESS}</Text>
                            <Text style={tw("text-[9px]")} >{GST_IN}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default Header
