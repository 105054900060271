import React from "react";

function RoomSelection({
  selectedRoom,
  setSelectedRoom,
  rooms,
  id,
}) {

  const handleRoomClick = (roomId) => {
    setSelectedRoom(roomId);
  };
  return (
    <div className="p-2 bg-gray-200 ">
      <div className="grid gap-6">
        <div>
          <div className="flex gap-3">
            {rooms.map((room) => (
              id ?
                <div
                  key={room.id}
                  className={`flex items-center p-2 border rounded-md shadow-sm transition 
                ${(selectedRoom == room.id)
                      ? "bg-green-400"
                      : "bg-gray-300"} rounded-full`}
                >
                  <div>
                    <p className="font-semibold">{room.name}</p>
                  </div>
                </div>
                :
                <button
                  key={room.id}
                  className={`flex items-center p-2 border rounded-md shadow-sm cursor-pointer  transition 
                  ${selectedRoom == room.id ? "border-2 border-blue-500" : "border-0"} 
                ${room.isVacant
                      ? "bg-green-400 hover:bg-gray-100"
                      : "bg-red-300"} rounded-full`}
                  disabled={!room.isVacant}
                  onClick={() => handleRoomClick(room.id)}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") { handleRoomClick(room.id) }
                  }}
                >
                  <div>
                    <p className="font-semibold">{room.name}</p>
                  </div>
                </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoomSelection;
