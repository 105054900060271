import React from 'react';
import { push } from '../../../redux/features/opentabs';
import { useDispatch } from 'react-redux';
import { GreenBadge, RedBadge, WEB_LINK } from '../../../icons';
import { PAYMENT_PAGE_NAME } from '../../../Constants';
import { TablePagination } from '@mui/material';


const TemperatureTable = ({ patientList, paymentFilter, setPaymentFilter, consultFilter, setConsultFilter, searchToken, setSearchToken, patientGender, setPatientGender, searchPatientRegNo, setSearchPatientRegNo, searchPatientName, setSearchPatientName, currentPageNumber, setCurrentPageNumber, totalCount, dataPerPage, setDataPerPage, searchPatientVisitDocId, setSearchPatientVisitDocId }) => {
    const patientData = patientList?.data ? patientList.data : [];
    const dispatch = useDispatch();
    const handleChange = (event, value) => {
        setCurrentPageNumber(value);
    };
    const handleChangeRowsPerPage = (event) => {
        setDataPerPage(parseInt(event.target.value, 10));
        setCurrentPageNumber(0);
    };
    const frozenRows = 15;
    return (
        <div className="">
            <div className="md:flex md:items-center md:justify-between page-heading p-1">
                <div className="heading text-center md:mx-10">Patient Visits </div>
            </div>
            <div className='h-[350px] overflow-auto'>
                <table className="border-collapse w-full table-auto">
                    <thead className="block md:table-header-group bg-gray-200 text-black uppercase rounded-lg sticky top-0">
                        <tr className="border border-gray-300 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto md:relative">
                            <th className="w-[100px] text-black font-bold md:border md:border-gray-300 block md:table-cell">
                                <div className="grid justify-center items-center">
                                    <input placeholder="TOKEN" className="input-placeholder-dark w-full focus:outline-none rounded-sm border border-gray-400 bg-gray-200 text-black p-1" value={searchToken} onChange={(e) => setSearchToken(e.target.value)} />
                                </div>
                            </th>

                            <th className="w-[100px] text-black font-bold md:border md:border-gray-300 block md:table-cell">
                                <div className="grid justify-center items-center">
                                    <input placeholder="REG.NO" className="input-placeholder-dark w-full focus:outline-none rounded-sm border border-gray-400 bg-gray-200 text-black p-1" value={searchPatientRegNo} onChange={(e) => setSearchPatientRegNo(e.target.value)} />
                                </div>
                            </th>
                            <th className="w-[100px] text-black font-bold md:border md:border-gray-300 block md:table-cell">
                                <div className="grid justify-center items-center">
                                    <input placeholder="PATIENT NAME" className="input-placeholder-dark w-full focus:outline-none rounded-sm border border-gray-400 bg-gray-200 text-black p-1" value={searchPatientName} onChange={(e) => setSearchPatientName(e.target.value)} />
                                </div>
                            </th>
                            <th className="text-black font-bold md:border md:border-gray-300 w-24 block md:table-cell">
                                <div className="grid">
                                    <select className="w-full focus:outline-none rounded-sm border border-gray-400 bg-gray-200 text-black p-1" value={patientGender} onChange={(e) => setPatientGender(e.target.value)}>
                                        <option value="">GENDER</option>
                                        <option value="MALE">MALE</option>
                                        <option value="FEMALE">FEMALE</option>
                                        <option value="OTHER">OTHER</option>
                                    </select>
                                </div>
                            </th>
                            <th className="text-black font-bold md:border md:border-gray-300 block md:table-cell w-24  bg-gray-200">Temp </th>
                            <th className="text-black font-bold md:border md:border-gray-300 block md:table-cell w-24  bg-gray-200">Pressure</th>
                            <th className="text-black font-bold md:border md:border-gray-300 block md:table-cell w-24  bg-gray-200">Weight</th>
                            <th className="text-black font-bold md:border md:border-gray-300 block md:table-cell  bg-gray-200">Reason For Visit</th>
                            <th className="text-black font-bold md:border md:border-gray-300 block md:table-cell w-5  bg-gray-200">
                                <div className="grid">
                                    <select className="w-40 focus:outline-none rounded-sm border border-gray-400 bg-gray-200 text-black p-1" value={paymentFilter} onChange={(e) => setPaymentFilter(e.target.value)}>
                                        <option value="All" >PAYMENT-(ALL)</option>
                                        <option value="Done">PAYMENT DONE</option>
                                        <option value="Pending">PAYMENT PENDING</option>
                                    </select>
                                </div>
                            </th>
                            <th className="text-black font-bold md:border md:border-gray-300 block md:table-cell w-5">
                                <div className="grid">
                                    <select className="w-40 focus:outline-none rounded-sm border border-gray-400 bg-gray-200 text-black p-1" value={consultFilter} onChange={(e) => setConsultFilter(e.target.value)}>
                                        <option value="All">STATUS-(ALL)</option>
                                        <option value="Consulted">Consulted</option>
                                        <option value="Waiting">Waiting</option>
                                    </select>
                                </div>
                            </th>
                            <th className="text-black font-bold md:border md:border-gray-300 block md:table-cell w-24  bg-gray-200">Transaction</th>
                            <th className="text-black font-bold md:border md:border-gray-300 block md:table-cell w-24  bg-gray-200">Payment</th>
                        </tr>
                    </thead>

                    <tbody className="">
                        {patientData.map((row, index) => (
                            <tr key={index} className={`h-[20px] border border-gray-300 p-1  md:border-none block md:table-row ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                                <td className=" md:border md:border-gray-300 text-center block md:table-cell p-1 ">
                                    {row?.token}
                                </td>
                                <td className=" md:border md:border-gray-300 text-left block md:table-cell p-1">{row?.Patient?.regNo}</td>
                                <td className=" md:border md:border-gray-300 text-left block md:table-cell p-1">{row?.Patient?.name}</td>
                                <td className=" md:border md:border-gray-300 text-left block md:table-cell p-1">{row?.Patient?.gender}</td>
                                <td className={` md:border md:border-gray-300 text-right block md:table-cell p-1 ${row?.temperature > 90 ? 'text-red-500' : 'text-black'}`}>{row?.temperature}</td>
                                <td className=" md:border md:border-gray-300 text-right block md:table-cell p-1 ">{row?.weight}</td>
                                <td className=" md:border md:border-gray-300 text-right block md:table-cell p-1">{row?.bp}</td>
                                <td className=" md:border md:border-gray-300 text-left block md:table-cell p-1">{row?.reasonForVisit}</td>
                                <td className=" md:border md:border-gray-300 text-left block md:table-cell p-1">
                                    <div className='flex items-center justify-center w-full'>
                                        {row?.PatientVisitPayment ? <GreenBadge name={"Done"} /> : <RedBadge name={"Pending"} />}
                                    </div>
                                </td>
                                <td className=" md:border md:border-gray-300 text-left block md:table-cell">
                                    <div className='flex items-center justify-center w-full'>
                                        {row?.consulted ? <GreenBadge name={"Consulted"} /> : <RedBadge name={"Waiting"} />}
                                    </div>
                                </td>
                                <td className=" md:border md:border-gray-300 text-left p-1 block md:table-cell">
                                    <div className='flex justify-center items-center'>

                                        <button className="text-xs font-medium underline text-blue-800" onClick={() => {
                                            dispatch(push({ name: "PATIENT VISIT TRANSACTION", previewId: row?.id }));
                                            dispatch({
                                                type: `patientVisit/invalidateTags`,
                                                payload: ['PatientVisit'],
                                            });
                                        }} >
                                            {WEB_LINK}
                                        </button>
                                    </div>
                                </td>
                                <td className=" md:border md:border-gray-300 text-left block md:table-cell">
                                    <div className='flex justify-center items-center'>
                                        <button className="text-xs font-medium underline text-blue-800" onClick={() => {
                                            dispatch(push({ name: PAYMENT_PAGE_NAME, previewId: row?.id }));
                                            dispatch({
                                                type: `patientVisit/invalidateTags`,
                                                payload: ['PatientVisit'],
                                            })
                                            dispatch({
                                                type: `Payment/invalidateTags`,
                                                payload: ['Payment'],
                                            })
                                        }} >
                                            {WEB_LINK}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        {Array.from({ length: frozenRows - patientData.length }).map((_, i) => (
                            <tr
                                key={i}
                                className={`bg-white border border-gray-300 md:border-none block md:table-row h-[32px]`}
                            >
                                <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                <td className="md:border md:border-gray-300 text-right block md:table-cell p-1"></td>
                                <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                <td className="md:border md:border-gray-300 text-right block md:table-cell p-1"></td>
                                <td className="md:border md:border-gray-300 text-right block md:table-cell p-1"></td>
                                <td className="md:border md:border-gray-300 text-right block md:table-cell p-1"></td>
                                <td className="md:border md:border-gray-300 text-right block md:table-cell p-1"></td>
                                <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                <td className="md:border md:border-gray-300 text-left block md:table-cell"></td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
            <div className='flex items-center justify-end'>
                <TablePagination
                    component="div"
                    count={totalCount}
                    page={currentPageNumber}
                    onPageChange={handleChange}
                    rowsPerPage={dataPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

export default TemperatureTable;
