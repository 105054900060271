import React from 'react';
import { useGetProductQuery } from '../../../redux/PharmacyServices/ProductMasterService';
import { useGetProductCategoryQuery } from '../../../redux/PharmacyServices/ProductCategoryServices';
import { useGetProductBrandQuery } from '../../../redux/PharmacyServices/ProductBrandService';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, substract } from '../../../Utils/helper';
import { DELETE } from '../../../icons';
import { PLUS } from '../../../icons';
import { DateInput, DropdownWithSearch } from '../../../Inputs';
import { useGetUomQuery } from '../../../redux/PharmacyServices/UomMasterService';
import RackDropdown from '../../../ReusableComponents/RackDropdown';
import BinDropdown from '../../../ReusableComponents/BinDropdown';
import { toast } from 'react-toastify';

const PoBillItems = ({ id, readOnly, setPoBillItems, poBillItems, storeId, openPopup }) => {
    const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") }
    const { data: productBrandList } =
        useGetProductBrandQuery({ params });

    const { data: productCategoryList } =
        useGetProductCategoryQuery({ params });

    const { data: productList } = useGetProductQuery({ params });

    function handleInputChange(value, index, field) {
        setPoBillItems(poBillItems => {
            const newBlend = structuredClone(poBillItems);
            newBlend[index][field] = value;
            if (field == "pcsQty") {
                newBlend[index]['qty'] = value;
            }
            return newBlend
        });
    };

    function deleteRow(index) {
        setPoBillItems(prev => prev.filter((_, i) => i !== index))
    }
    function addNewRow() {
        openPopup()
    }

    function getTotal() {
        const total = poBillItems.reduce((accumulator, current) => {

            return accumulator + parseFloat(current['qty'] || 0)
        }, 0)
        return parseFloat(total)
    }
    function getProductUomPriceDetails(productId) {
        const items = findFromList(productId, productList?.data ? productList?.data : [], "ProductUomPriceDetails")
        return items ? items : []
    }
    const { data: uomData } = useGetUomQuery({});

    function getEquilentPcs(uomId) {
        const items = findFromList(uomId, uomData?.data ? uomData?.data : [], "equivalentPcs")
        return items ? items : ''
    }

    if (!productBrandList || !productCategoryList || !productList) return <Loader />

    return (
        <>
            <div className={` relative w-full py-1`}>
                <table className=" border border-gray-500 text-sm table-auto  w-full h-full">
                    <thead className=''>
                        <tr className=''>
                            <th className="table-data  w-2 text-center p-0.5">S.no</th>
                            <th className="table-data ">Brand</th>
                            <th className="table-data ">Product<span className="text-red-500 p-5">*</span></th>
                            <th className="table-data  w-16">Batch No.</th>
                            <th className="table-data  w-28">Exp. Date</th>
                            <th className="table-data  w-16">Conversion</th>
                            <th className="table-data  w-16">Mrp</th>
                            <th className="table-data  w-16 p-0.5">Rack</th>
                            <th className="table-data  w-16 p-0.5">Bin</th>
                            <th className="table-data  w-20">Del.Qty</th>
                            <th className="table-data  w-20">A.Rec.Qty</th>
                            <th className="table-data  w-20">Bal.Qty</th>
                            <th className="table-data  w-20">Qty</th>
                            {!readOnly &&
                                <th className="table-data  w-10 p-0.5">  <button onClick={addNewRow}>{PLUS}</button></th>
                            }
                        </tr>
                    </thead>
                    <tbody >
                        {(poBillItems || []).map((item, index) =>
                            <tr key={index} className="w-full table-row h-[10px]">
                                <td className="table-data w-2 text-center px-1 py-1">
                                    {index + 1}
                                </td>
                                <td className='table-data' >
                                    <DropdownWithSearch
                                        readOnly
                                        value={item.productBrandId}
                                        setValue={(value) => handleInputChange(value, index, "productBrandId")}
                                        options={productBrandList?.data ? (
                                            (id ? productBrandList?.data : productBrandList?.data.filter(i => i?.active))
                                        ) : []} />
                                </td>
                                <td className='table-data'>
                                    <DropdownWithSearch
                                        value={item.productId}
                                        readOnly
                                        setValue={(value) => handleInputChange(value, index, "productId")}
                                        options={productList.data.filter(value => parseInt(value.productBrandId) === parseInt(item.productBrandId)).filter(item => item?.active)} />
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="text"
                                        className="text-right rounded py-1 px-1 w-16 table-data-input"
                                        value={item?.batchNo ? item?.batchNo : ""}
                                        disabled
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "batchNo")
                                        }
                                    />
                                </td>
                                <td className='table-data'>
                                    <DateInput hideLabel disabled value={item?.expiryDate ? new Date(item.expiryDate) : new Date()} setValue={(value) =>
                                        handleInputChange(value, index, "expiryDate")} dateFormat='MMM-YYYY' required={true} readOnly={readOnly} showMonthYearPicker />
                                </td>
                                <td className='table-data'>
                                    <select
                                        disabled
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "uomId") } }}
                                        className='text-left w-full rounded py-1 table-data-input'
                                        value={item.uomId}
                                        onChange={(e) => handleInputChange(e.target.value, index, "uomId")}
                                    >
                                        <option value={""}>
                                            Select
                                        </option>
                                        {getProductUomPriceDetails(item.productId).map((uom) =>
                                            <option value={uom.uomId} key={uom.uomId}>
                                                {uom?.Uom?.name}
                                            </option>)}
                                    </select>
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1 w-16 table-data-input"
                                        min={0}
                                        value={(!item.salePrice) ? 0 : item.salePrice}
                                        disabled
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "salePrice")
                                        }
                                        onBlur={(e) => {
                                            handleInputChange(parseFloat(e.target.value).toFixed(2), index, "salePrice");
                                        }
                                        }
                                    />
                                </td>
                                <td className='table-data'>
                                    <RackDropdown multiSelect={false}
                                        storeId={storeId}
                                        readOnly={(!storeId) || readOnly}
                                        selected={item?.rackId}
                                        setSelected={(value) => handleInputChange(value, index, "rackId")}
                                    />
                                </td>
                                <td className='table-data'>
                                    <BinDropdown multiSelect={false}
                                        rackId={item?.rackId}
                                        readOnly={(!item?.rackId) || readOnly}
                                        selected={item?.binId}
                                        setSelected={(value) => handleInputChange(value, index, "binId")}
                                    />
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1 w-16 table-data-input"
                                        value={item.deliveryQty || 0}
                                        disabled
                                        min={0}
                                    />
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1 w-16 table-data-input"
                                        value={(item.alreadyReceivedQty || 0)}
                                        disabled
                                        min={0}
                                    />
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1 w-16 table-data-input"
                                        value={(item?.deliveryQty || 0) - (item.alreadyReceivedQty || 0)}
                                        disabled
                                        min={0}
                                    />
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1 w-16 table-data-input"
                                        value={(!item.pcsQty) ? 0 : item.pcsQty}
                                        disabled={readOnly}
                                        max={(item?.deliveryQty || 0) - (item.alreadyReceivedQty || 0)}
                                        onChange={(e) => {
                                            if (e.target.value > parseFloat(substract(parseFloat(item?.deliveryQty || 0), parseFloat(item.alreadyReceivedQty || 0)))) {
                                                return toast.info("Qty Cannot be More than Bal. Qty...!!!")
                                            }
                                            handleInputChange(e.target.value, index, "pcsQty")
                                        }
                                        }
                                        onBlur={(e) => {
                                            handleInputChange(parseFloat(e.target.value).toFixed(2), index, "pcsQty");
                                        }
                                        }
                                        min={0}
                                    />
                                </td>
                                {!readOnly &&
                                    <td className="text-xs text-center">
                                        <button
                                            type='button'
                                            onClick={() => {
                                                deleteRow(index)
                                            }}
                                            className='text-xs text-red-600 '>{DELETE}
                                        </button>
                                    </td>
                                }
                            </tr>
                        )}
                        <tr className='w-full border border-gray-400 h-7 font-bold'>
                            <td className="table-data text-center w-10 font-bold" colSpan={12}>Total</td>
                            <td className="table-data text-right pr-1 w-10">{getTotal().toFixed(2)} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default PoBillItems