import React from 'react'
import { View, Text } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';

const ToField = ({ data }) => {
    return (
        <View style={tw("w-full flex border-t border-black")}>
            <View style={tw("flex flex-row items-center  justify-between text-left mt-1")}>
                <View style={tw("w-full flex flex-row  text-[9px]")}>
                    <View style={tw("flex flex-row w-1/3 items-center h-1/3 border-black")}>
                        <View style={tw("w-1/3 border-black  flex pl-1 justify-center  font-[Times-Bold]")}>
                            <Text>Invoice No:</Text>
                        </View>
                        <View style={tw("w-2/3 flex pl-1 justify-center")}>
                            <Text>{data?.docId}</Text>
                        </View>
                    </View>
                    <View style={tw("flex flex-row w-1/3 items-center h-1/3 border-black")}>
                        <View style={tw("w-1/3 border-black  flex pl-1 justify-center  font-[Times-Bold]")}>
                            <Text>Date:</Text>
                        </View>
                        <View style={tw("w-2/3  flex pl-1 justify-center ")}>
                            <Text>{getDateFromDateTimeToDisplay(data?.createdAt)}</Text>
                        </View>
                    </View>
                    <View style={tw("flex flex-row w-1/3 items-center h-1/3  border-black")}>
                        <View style={tw("w-1/3 border-black  flex pl-1 justify-center  font-[Times-Bold]")}>
                            <Text>Patient Name:</Text>
                        </View>
                        <View style={tw("w-2/3  flex pl-1 justify-center ")}>
                            <Text>{data?.name}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={tw("flex flex-row items-center w-full justify-between text-left mt-2 mb-2")}>
                <View style={tw("w-full flex flex-row  text-[9px] ")}>
                    <View style={tw("flex flex-row w-1/3 items-center h-1/3 border-black")}>
                        <View style={tw("w-1/3 border-black  flex pl-1 justify-center  font-[Times-Bold]")}>
                            <Text>Patient Id:</Text>
                        </View>
                        <View style={tw("w-2/3 flex pl-1 justify-center ")}>
                            <Text>{data?.PatientVisit?.Patient?.regNo}</Text>
                        </View>
                    </View>
                    <View style={tw("flex flex-row w-1/3 items-center h-1/3 border-black")}>
                        <View style={tw("w-1/3 border-black  flex pl-1 justify-center  font-[Times-Bold]")}>
                            <Text>Patient Ph.no:</Text>
                        </View>
                        <View style={tw("w-2/3  flex pl-1 justify-center ")}>
                            <Text>{data?.contactMobile}</Text>
                        </View>
                    </View>
                    <View style={tw("flex flex-row w-1/3 items-center h-1/3 border-black")}>
                        <View style={tw("w-1/3 border-black  flex pl-1 justify-center  font-[Times-Bold]")}>
                            <Text>Doctor:</Text>
                        </View>
                        <View style={tw("w-2/3 flex pl-1 justify-center")}>
                            <Text>{data?.PatientVisit?.Employee?.name}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default ToField
