import React, { useState } from 'react'
import ExpiryReport from './expired'
import ToBeExpired from './ToBeExpiredData'
import { DateInput, RadioButton } from '../../../Inputs'
import Modal from '../../../UiComponents/Modal';
import Parameter from './Parameter';
import { getCommonParams } from '../../../Utils/helper';
import { useGetLocationMasterQuery } from '../../../redux/PharmacyServices/LocationMasterServices';
import { useGetBranchQuery } from '../../../redux/services/BranchMasterService';
import moment from 'moment';
import { showEntries } from '../../../Utils/DropdownData';
import ParameterButton from '../../../ReusableComponents/ParameterButton';
import { useGetStockQuery } from '../../../redux/PharmacyServices/StockService';
import secureLocalStorage from 'react-secure-storage';
import { REFRESH_ICON } from '../../../icons';
import OutOfStockReport from './outOfStockData';


const ExpiryRegister = () => {
    const [storeId, setStoreId] = useState("");
    const [locationId, setLocationId] = useState('');
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [parameter, setParameter] = useState(false);
    const [selectedReport, setSelectedReport] = useState('toBeExpired');
    const [searchDate, setSearchDate] = useState('')


    const onHandleChange = (reportType) => {
        setSelectedReport(reportType);
    };
    const [dataPerPage, setDataPerPage] = useState("10");







    return (
        <div>
            <Modal isOpen={parameter} onClose={() => setParameter(false)}>
                <Parameter
                    locationId={locationId} setLocationId={setLocationId}
                    storeId={storeId} setStoreId={setStoreId}
                    endDate={endDate} setEndDate={setEndDate}
                    onClose={() => setParameter(false)}
                />
            </Modal>
            <div className="w-full ">
                <div className={`text-lg text-center  flex items-center gap-2 p-2 justify-between ${selectedReport === "expired" ? 'bg-[#9c3030]' : 'bg-gray-700'}`}>
                    <div className='text-white font-semibold'>{selectedReport === "toBeExpired" ? 'To Be Expired' : selectedReport === "outOfStock" ? 'Out Of Stock' : 'Expired'}</div>

                    <div className='flex gap-4 px-4 text-white font-semibold items-center'>
                        <label htmlFor="">Filter : </label>
                        <div className='flex items-center'>

                            <ParameterButton onClick={() => setParameter(true)} />

                        </div>  <RadioButton
                            value={selectedReport === 'expired'}
                            onChange={() => onHandleChange('expired')}
                            label="Expired"
                        />
                        <RadioButton
                            value={selectedReport === 'toBeExpired'}
                            onChange={() => onHandleChange('toBeExpired')}
                            label="To be Expired"
                        />
                        <RadioButton
                            value={selectedReport === 'outOfStock'}
                            onChange={() => onHandleChange('outOfStock')}
                            label="Out Of Stock"
                        />
                        <div className='text-black p-1'> <DateInput name="Search Date" value={searchDate} setValue={setSearchDate} required={true} /></div>
                    </div>
                </div>


            </div>

            {selectedReport === 'expired' && <ExpiryReport storeId={storeId} />}
            {selectedReport === 'toBeExpired' && <ToBeExpired storeId={storeId} searchDate={searchDate} />}
            {selectedReport === 'outOfStock' && <OutOfStockReport storeId={storeId} searchDate={searchDate} />}
        </div>
    );
};

export default ExpiryRegister;
