import React from 'react'
import { Page, Text, View, Document } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import { formatAMPM, getDateFromDateTimeToDisplay } from '../../../Utils/helper';

const Token = ({ data }) => {
    const branchData = data?.Branch;
    return (
        <Document>
            <Page size={"B8"} >
                <View style={tw("text-center p-2")}>
                    <Text>
                        {branchData?.branchName}
                    </Text>
                </View>
                <View style={tw("text-sm text-center")}>
                    <Text>
                        {branchData?.contactAddressBranch}
                    </Text>
                </View>
                <View style={tw("text-xl text-center")}>
                    <Text>
                        OP TOKEN
                    </Text>
                </View>
                <View style={tw("text-sm flex  text-center")}>
                    <Text>
                        {getDateFromDateTimeToDisplay(data?.createdAt)} {formatAMPM(data?.createdAt)}
                    </Text>
                </View>
                <View >
                    <Text style={tw("text-center text-[6rem] m-3")}>{data?.token}</Text>
                </View>
                <View >
                    <Text style={tw("text-center text-2xl")}>OP: {data?.Room?.name}</Text>
                </View>
                <View style={tw("text-sm flex  text-center")}>
                    <Text style={tw("text-sm")}>DOCTOR: {data?.Employee?.name}</Text>
                </View>
                <View style={tw("text-sm flex  text-center")}>
                    <Text style={tw("text-sm")}>Patient: {data?.Patient?.name}</Text>
                </View>
            </Page>
        </Document>
    )
}

export default Token
