import React, { useEffect } from 'react'
import { useGetStockQuery } from '../../../redux/PharmacyServices/StockService'
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import { useState } from 'react';
import { TablePagination } from '@mui/material';
import { RadioButton } from '../../../Inputs';

const InStockItemDet = () => {
    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [outOfStock, setOutOfStock] = useState(false)
    const [selectedReport, setSelectedReport] = useState('expired');


    const { data: expiredData } = useGetStockQuery({ params: { pagination: true, dataPerPage, pageNumber: currentPageNumber + 1, isGetStockDashboardData: true, inStock: true } })

    useEffect(() => {
        if (!expiredData?.totalCount) return
        setTotalCount(expiredData?.totalCount);
    }, [expiredData]);

    const handleChange = (event, value) => {
        setCurrentPageNumber(value);
    };
    const handleChangeRowsPerPage = (event) => {
        setDataPerPage(parseInt(event.target.value, 10));
        setCurrentPageNumber(0);
    };
    const frozenRows = 9;

    const stockItems = expiredData?.data || [];
    return (
        <section className="bg-gray-100 py-4 ">

            <div className='text-lg text-center bg-sky-700 text-white'>
                In-Stock Items
            </div>


            <div className="text-center mx-auto max-w-screen-2xl">
                <div className="relative bg-white shadow-md ">
                    <div className="overflow-x-auto overflow-auto h-[350px] mt-3">
                        <table className="w-full text-xs text-left border border-gray-300 text-gray-500 h-full w-full dark:text-gray-400 border-collapse">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr className={`sticky top-0   font-thin tracking-wider bg-gray-700 text-white`}>
                                    <th scope="col" className="p-2 text-center font-thin border border-gray-300 ">S.No.</th>
                                    <th scope="col" className="p-2 text-center font-thin border border-gray-300 ">Name</th>
                                    <th scope="col" className="p-2 text-center font-thin border border-gray-300 ">Supplier</th>
                                    <th scope="col" className="p-2 text-center font-thin border border-gray-300 w-24 ">Batch No</th>
                                    <th scope="col" className="p-2 text-center font-thin border border-gray-300 ">Expiry Date</th>
                                    <th scope="col" className="p-2 text-center font-thin border border-gray-300 w-48 ">Expired Days</th>
                                    <th scope="col" className="p-2 text-center font-thin border border-gray-300 ">Stock Qty</th>
                                    {/* {isExpiredOrOutOfStock && (
                                        <th scope="col" className="p-2 text-center font-thin border border-gray-300 w-24">Status</th>
                                    )} */}
                                </tr>
                            </thead>
                            <tbody>
                                {stockItems.map((stockItem, index) => (
                                    <tr
                                        key={index}
                                        className={`border-b border-gray-300 dark:border-gray-600 hover:bg-gray-100  dark:hover:bg-gray-700 ${stockItem.expireInDays < 0 ? "bg-red-200" : "even:bg-gray-100"
                                            }`}
                                    >
                                        <td className="p-2 text-center border border-gray-300">{index + 1}</td>
                                        <td className="p-2 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white border border-gray-300">
                                            {stockItem?.name}
                                        </td>
                                        <td className="p-2 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white border border-gray-300">
                                            {stockItem?.supplierName}
                                        </td>
                                        <td className="p-2 text-center border border-gray-300">
                                            {stockItem?.batchNo && (
                                                <span className="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded">{stockItem?.batchNo}</span>
                                            )}
                                        </td>
                                        <td className="p-2 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white border border-gray-300">
                                            <div className="flex items-center">
                                                {stockItem?.expiryDate && (
                                                    <>
                                                        <div className={`inline-block w-4 h-4 mr-2 ${stockItem.expireInDays > 0 ? 'bg-emerald-600' : 'bg-red-700'} rounded-full`}></div>
                                                        {getDateFromDateTimeToDisplay(stockItem.expiryDate)}
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                        <td className="p-2 text-right font-medium text-gray-900 whitespace-nowrap dark:text-white border border-gray-300">
                                            {stockItem?.expireInDays}
                                        </td>
                                        <td className="p-2 text-right font-medium text-gray-900 whitespace-nowrap dark:text-white border border-gray-300">
                                            {stockItem?.stockQty}
                                        </td>
                                        {/* {isExpiredOrOutOfStock && (
                                            <td className="p-2 text-center flex items-center justify-center font-medium text-gray-900 whitespace-nowrap dark:text-white border border-gray-300">
                                                <div className="flex justify-center items-center w-[100px]">
                                                    {parseFloat(stockItem?.stockQty) > 0 ? (
                                                        <span className="inline-flex items-center gap-x-1.5 px-3 rounded-full text-xs font-medium bg-red-500 text-white">Expired</span>
                                                    ) : (
                                                        <span className="inline-flex items-center gap-x-1.5 px-3 rounded-full text-xs font-medium bg-gray-500 text-white">Out Of Stock</span>
                                                    )}
                                                </div>
                                            </td>
                                        )} */}
                                    </tr>
                                ))}
                                {/* {isExpiredOrOutOfStock ? (
                                    Array.from({ length: frozenRows - stockItems.length }).map((_, i) => (
                                        <tr
                                            key={i}
                                            className={`bg-white border border-gray-300 md:border-none block md:table-row h-[32px]`}
                                        >
                                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                            <td className="md:border md:border-gray-300 text-right block md:table-cell p-1"></td>
                                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                        </tr>
                                    ))
                                ) : (
                                    Array.from({ length: frozenRows - stockItems.length }).map((_, i) => (
                                        <tr
                                            key={i}
                                            className={`bg-white border border-gray-300 md:border-none block md:table-row h-[32px]`}
                                        >
                                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                            <td className="md:border md:border-gray-300 text-right block md:table-cell p-1"></td>
                                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                                        </tr>
                                    ))
                                )} */}


                            </tbody>
                        </table>

                    </div>
                    <div className='flex items-center justify-end'>
                        <TablePagination
                            component="div"
                            count={totalCount}
                            page={currentPageNumber}
                            onPageChange={handleChange}
                            rowsPerPage={dataPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InStockItemDet
