import React, { useEffect, useState, useRef, useCallback } from 'react';

import FormHeader from '../../../Basic/components/FormHeader';
import { toast } from "react-toastify"
import { TextInput, DisabledInput, DropdownInput } from "../../../Inputs"
import ReportTemplate from '../../../Basic/components/ReportTemplate';
import { RetailPrintFormatFinishedGoodsSales } from "..";
import {
  useGetStockDeliveryQuery,
  useGetStockDeliveryByIdQuery,
  useAddStockDeliveryMutation,
  useUpdateStockDeliveryMutation,
  useDeleteStockDeliveryMutation,
} from '../../../redux/PharmacyServices/StockDeliveryServices'

import { findFromList, getCommonParams, getDateFromDateTime } from '../../../Utils/helper';
import PoBillItems from './PoBillItems';
import Modal from "../../../UiComponents/Modal";
import PurchaseBillFormReport from './PurchaseBillFormReport';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import LocationDropdown from '../../../ReusableComponents/LocationDropdown';
import StoreDropdown from '../../../ReusableComponents/StoreDropdown';
import StockItemOptionForBarCode from '../../../ReusableComponents/StockItemDropdownForBarCode';

import useSetDefaultLocationStore from '../../../CustomHooks/useSetDefaultLocationStore';
import useIsBarcodeSystem from '../../../CustomHooks/useIsBarcodeSystem';
import SalesTypePharmacyDropdown from '../../../ReusableComponents/SalesTypeDropdown';
import PatientConsultedSelection from './PatientConsultedSelection';
import { useGetPatientVisitByIdQuery } from '../../../redux/pharmaServices/PatientVisitTransaction';
import PrescriptionSelection from './PrescriptionSelection';
import { PDFViewer } from '@react-pdf/renderer';
import tw from "../../../Utils/tailwind-react-pdf";
import PrintFormatSalesBill from '../PrintFormatSalesBill';
import { useGetRoomQuery } from '../../../redux/services/RoomMasterService';
import secureLocalStorage from 'react-secure-storage';
import InPatientSelection from '../Payments/InPatientSelection';
import { useGetPatientAdmitByIdQuery } from '../../../redux/pharmaServices/PatientAdmitTransaction';
import StockDeliveryFillGrid from './StockDeliveryFillGrid';
import Consolidation from './Consolidation';

const MODEL = "Stock Delivery";

export default function Form() {
  const today = new Date()
  const [form, setForm] = useState(true);
  const [date, setDate] = useState(getDateFromDateTime(today));
  const [docId, setDocId] = useState("");
  const [contactMobile, setContactMobile] = useState("");
  const [formReport, setFormReport] = useState(false)
  const [patientVisitPopUp, setPatientVisitPopUp] = useState(false);
  const [prescriptionsPopUp, setPrescriptionsPopUp] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [deliveryToId, setDeliveryToId] = useState("")
  const [storeId, setStoreId] = useState("")
  const [roomId, setRoomId] = useState('')
  const [admittedDate, setAdmittedDate] = useState('')
  const [reasonForAdmit, setReasonForAdmit] = useState('')
  const [searchValue, setSearchValue] = useState("");
  const [poBillItems, setPoBillItems] = useState([])
  const childRecord = useRef(0);
  const [refetchStoreId, setRefetchStoreId] = useState(false);
  const [salesType, setSalesType] = useState("Counter")
  const [patientVisitId, setPatientVisitId] = useState('');
  const [patientAdmitId, setPatientAdmitId] = useState('');
  const [printModalOpen, setPrintModalOpen] = useState(false);
  const [isOp, setIsOp] = useState(false)
  const [isPatientAdmitPopUp, setIsPatientAdmitPopUp] = useState(false)
  const [openStockFillGrid, setOpenStockFillGrid] = useState(false)
  const [vehicleNo, setVehicleNo] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("")
  const { branchId, finYearId } = getCommonParams()
  const [remarks, setRemarks] = useState("")

  const dispatch = useDispatch()

  const { data: allData, isLoading, isFetching } = useGetStockDeliveryQuery({ params: { branchId, finYearId }, searchParams: searchValue });
  const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetStockDeliveryByIdQuery({ id }, { skip: !id });

  const [addData] = useAddStockDeliveryMutation();
  const [updateData] = useUpdateStockDeliveryMutation();
  const [removeData] = useDeleteStockDeliveryMutation();

  const getNextDocId = useCallback(() => {

    if (id || isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const syncFormWithDb = useCallback(
    (data) => {
      if (id) setReadOnly(true);
      else setReadOnly(false);
      if (data?.docId) {
        setDocId(data.docId);
      }
      if (data?.createdAt) setDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
      setPoBillItems(data?.stockDeliveryItems || []);
      setStoreId(data?.storeId ? data.storeId : "")
      setLocationId(data?.Store?.locationId ? data?.Store?.locationId : "")
      setRefetchStoreId(true);
      childRecord.current = data?.childRecord ? data?.childRecord : 0;
    }, [id])


  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData])

  useSetDefaultLocationStore({ setLocationId, setStoreId, refetchStoreId, setRefetchStoreId, id })


  const data = {
    id,
    branchId,
    storeId,
    stockDeliveryItems: poBillItems?.filter(item => item?.productId),
    deliveryToId,
    finYearId,
    vehicleNo,
    specialInstructions,
    remarks,
  }
  const validateData = (data) => {



    return data?.storeId && data?.stockDeliveryItems.length > 0 && data?.deliveryToId
  }



  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData = await callback(data).unwrap();
      if (returnData.statusCode === 0) {
        if (text === "Added") {
          setId(returnData.data?.id);
        } else {
          setId("")
          syncFormWithDb(undefined)
        }
        toast.success(text + "Successfully");
      } else {
        toast.error(returnData?.message)
      }
      dispatch({
        type: `stock/invalidateTags`,
        payload: ['Stock'],
      });
      dispatch({
        type: `patientVisit/invalidateTags`,
        payload: ['PatientVisit'],
      });
    } catch (error) {
      console.log(error)
    }

  }
  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated")
    } else {
      handleSubmitCustom(addData, data, "Added")
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return
      }
      try {
        await removeData(id).unwrap();
        setId("");
        toast.success("Deleted Successfully");
        dispatch({
          type: `patientVisit/invalidateTags`,
          payload: ['PatientVisit'],
        });
      } catch (error) {
        toast.error("something went wrong")
      }
      ;
    }
  }

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      event.preventDefault();
      saveData();
    }
  }

  const onNew = () => {
    getNextDocId();
    setSearchValue("")
    syncFormWithDb(undefined);
    setId("");

  }

  function onDataClick(id) {
    setId(id);
    onNew();
    setForm(true);
  }

  const handlePrint = () => {
    setPrintModalOpen(true);
  }


  const getTotalIssuedQty = () => {
    if (poBillItems?.length == 0) return
    const totalQty = poBillItems?.reduce((accumulation, currentValue) => {
      return (parseFloat(accumulation) + parseFloat(currentValue?.pcsQty ? currentValue?.pcsQty : 0))
    }, 0)
    return totalQty
  }


  return (


    <div onKeyDown={handleKeyDown} className='md:items-start md:justify-items-center grid h-[100%]  '>
      {/* <Modal isOpen={printModalOpen} onClose={() => setPrintModalOpen(false)} widthClass={"w-[90%] h-[90%]"} >
        <PDFViewer style={tw("w-full h-full")}>
          <PrintFormatSalesBill data={singleData?.data ? singleData.data : undefined} />
        </PDFViewer>
      </Modal> */}
      <Modal
        isOpen={formReport}
        onClose={() => setFormReport(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}
      >
        <PurchaseBillFormReport onClick={(id) => { setId(id); setFormReport(false) }} />
      </Modal>



      {/* <Modal
        isOpen={patientVisitPopUp}
        onClose={() => setPatientVisitPopUp(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}
      >
        <PatientConsultedSelection salesBillId={id} isOp={isOp} onClick={(id) => { setPatientVisitId(id); setPatientVisitPopUp(false); }} />
      </Modal>
      <Modal
        isOpen={prescriptionsPopUp}
        onClose={() => setPrescriptionsPopUp(false)}
        widthClass={"h-[90%] w-[90%] border-8 border-solid border-gray-500"}
      >
        <PrescriptionSelection onClose={() => setPrescriptionsPopUp(false)} onClick={() => { setPrescriptionsPopUp(false) }} poBillItems={poBillItems} setPoBillItems={setPoBillItems} medicines={patientVisitData?.data?.Prescriptions || []} />
      </Modal>
      <Modal
        isOpen={isPatientAdmitPopUp}
        onClose={() => setIsPatientAdmitPopUp(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}
      >
        <InPatientSelection onClick={(id) => { setPatientAdmitId(id); setIsPatientAdmitPopUp(false) }} />
      </Modal> */}
      <div className='flex flex-col frame w-full h-full '>
        <FormHeader
          onNew={onNew}
          model={MODEL}
          openReport={() => setFormReport(true)}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          onPrint={id ? handlePrint : null}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <div className='flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 '>
          <div className='col-span-4 grid md:grid-cols-1 border'>
            <div className='mr-1 md:ml-2  h-[100%] overflow-scroll'>
              <fieldset className='my-1 '>
                <div className='grid grid-cols-8 my-2 items-center justify-center'>
                  <DisabledInput name="Bill.No" value={docId} required={true} readOnly={readOnly} />
                  <DisabledInput name="Bill. 
                           Date" value={date} type={"Date"} required={true} readOnly={readOnly} />
                  <LocationDropdown selected={deliveryToId} setSelected={(value) => { setDeliveryToId(value); }} name={"Delivery.To"} multiSelect={false} withoutLabel={false} readOnly={readOnly} />

                  <LocationDropdown selected={locationId} setSelected={(value) => { setLocationId(value); setStoreId("") }} name={"Location"} multiSelect={false} withoutLabel={false} readOnly={readOnly} />
                  <StoreDropdown selected={storeId} setSelected={setStoreId} name={"Store"} multiSelect={false} withoutLabel={false} readOnly={!locationId || readOnly} />
                </div>
              </fieldset>
              <PoBillItems setOpenStockFillGrid={setOpenStockFillGrid} storeId={storeId} key={poBillItems} date={singleData?.data?.createdAt} id={id} readOnly={readOnly} poBillItems={poBillItems} setPoBillItems={setPoBillItems} />



              <Consolidation vehicleNo={vehicleNo} setVehicleNo={setVehicleNo}
                specialInstructions={specialInstructions} setSpecialInstructions={setSpecialInstructions}
                remarks={remarks} setRemarks={setRemarks} totalQty={getTotalIssuedQty()} readOnly={readOnly} />


              {/* <div className="hidden">
                <RetailPrintFormatFinishedGoodsSales
                  innerRef={componentRef} contactMobile={contactMobile} name={name} date={singleData?.data?.createdAt} id={id} poBillItems={poBillItems} readOnly={readOnly} docId={docId ? docId : ''} />
              </div> */}
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
