import React, { useMemo } from 'react'
import { useEffect, useState } from "react";
import { Loader } from "../../../Basic/components";
import { showEntries } from '../../../Utils/DropdownData';
import secureLocalStorage from 'react-secure-storage';
import { getDateFromDateTimeToDisplay, pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { useGetPatientAdmitQuery } from '../../../redux/pharmaServices/PatientAdmitTransaction';



const PatientAdmission = ({
    onClick, params
}) => {
    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )
    const [searchDocDate, setSearchDocDate] = useState("");
    const [searchDocId, setSearchDocId] = useState("");
    const [searchPatientName, setSearchPatientName] = useState("");
    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const handleOnclick = (e) => {
        setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
    }
    const searchFields = useMemo(() => ({ searchDocDate, searchPatientName, searchDocId }), [searchDocDate, searchPatientName, searchDocId])

    useEffect(() => { setCurrentPageNumber(1) }, [dataPerPage, searchDocDate, searchPatientName, searchDocId])


    const { data: allData, isLoading, isFetching } = useGetPatientAdmitQuery({ params: { branchId, ...searchFields, pagination: true, dataPerPage, pageNumber: currentPageNumber, filterInPatientConverted: true, ...params } });
    useEffect(() => {
        if (allData?.totalCount) {
            setTotalCount(allData?.totalCount)
        }
    }, [allData, isLoading, isFetching])

    const isLoadingIndicator = isLoading || isFetching
    return (
        <div id='registrationFormReport' className="flex flex-col w-full h-[95%] overflow-auto">
            <div className="md:flex md:items-center md:justify-between page-heading p-1">
                <div className="heading text-center md:mx-10">Admit PatientList </div>
                <div className=" sub-heading justify-center md:justify-start items-center">
                    <label className="text-white text-sm rounded-md m-1  border-none">Show Entries</label>
                    <select value={dataPerPage}
                        onChange={(e) => setDataPerPage(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
                        {showEntries.map((option, index) => <option key={index} value={option.value} >{option.show}</option>)}
                    </select>
                </div>
            </div>
            <>
                <div
                    className="h-[500px] overflow-auto"
                >
                    <table className="table-fixed text-center w-full">
                        <thead className="border-2 table-header">
                            <tr className='h-2'>
                                <th
                                    className="border-2  top-0 stick-bg w-10"
                                >
                                    S. no.
                                </th>

                                <th
                                    className="border-2  top-0 stick-bg "
                                >
                                    <div>Doc.Id</div><input
                                        type="text"
                                        className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                                        placeholder="Search"
                                        value={searchDocId}
                                        onChange={(e) => {
                                            setSearchDocId(e.target.value);
                                        }}
                                    />
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg "
                                >
                                    <div>Date</div><input
                                        type="text"
                                        className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                                        placeholder="Search"
                                        value={searchDocDate}
                                        onChange={(e) => {
                                            setSearchDocDate(e.target.value);
                                        }}
                                    />
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg"
                                >
                                    <div>Patient Name</div><input
                                        type="text"
                                        className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                                        placeholder="Search"
                                        value={searchPatientName}
                                        onChange={(e) => {
                                            setSearchPatientName(e.target.value);
                                        }}
                                    />
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg"
                                >
                                    <div>Mobile Number</div>
                                </th>
                                <th

                                    className="border-2  top-0 stick-bg"
                                >
                                    <div>Room Type</div>
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg "
                                >
                                    <div>Room No</div>
                                </th>
                            </tr>
                        </thead>
                        {isLoadingIndicator ?
                            <tbody>
                                <tr>
                                    <td>
                                        <Loader />
                                    </td>
                                </tr>
                            </tbody>
                            :
                            <tbody className="border-2">
                                {allData?.data.map((dataObj, index) => (
                                    <tr
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                onClick(dataObj.id);
                                            }
                                        }}
                                        tabIndex={0}
                                        key={dataObj.id}
                                        className="border-2 table-row cursor-pointer"
                                        onClick={() => onClick(dataObj.id)}>
                                        <td className='py-1'> {(index + 1) + (dataPerPage * (currentPageNumber - 1))}</td>
                                        <td className='py-1'> {dataObj?.docId}</td>
                                        <td className='py-1'>{getDateFromDateTimeToDisplay(dataObj?.createdAt)} </td>
                                        <td className='py-1'> {dataObj?.Patient?.name}</td>
                                        <td className='py-1'> {dataObj?.mobile}</td>
                                        <td className='py-1'>{dataObj?.RoomType?.name} </td>
                                        <td className='py-1'>{dataObj?.Room?.name} </td>
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </table>
                </div>
            </>
            <ReactPaginate
                previousdiv={"<"}
                nextdiv={">"}
                breakdiv={"..."}
                breakClassName={"break-me"}
                forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
                pageCount={Math.ceil(totalCount / dataPerPage)}
                marginPagesDisplayed={1}
                onPageChange={handleOnclick}
                containerClassName={"flex justify-center m-2 gap-5 items-center"}
                pageClassName={"border custom-circle text-center"}
                disabledClassName={"p-1 bg-gray-200"}
                previousLinkClassName={"border p-1 text-center"}
                nextLinkClassName={"border p-1"}
                activeClassName={"bg-blue-900 text-white px-2"} />
        </div>
    )
}

export default PatientAdmission