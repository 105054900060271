import { useEffect } from "react"
import { getCommonParams } from "../Utils/helper"
import { useGetUserByIdQuery } from "../redux/services/UsersMasterService"

const useSetDefaultLocationStore = ({ setLocationId, setStoreId, refetchStoreId, setRefetchStoreId, id }) => {
    const { userId } = getCommonParams()
    const { data: userData, isLoading: isUserLoading, isFetching: isUserFetching } = useGetUserByIdQuery(userId)

    useEffect(() => {
        if (isUserFetching || isUserLoading || !(userData?.data) || (!refetchStoreId) || id) return
        setStoreId(userData?.data?.storeId || "");
        setLocationId(userData?.data?.Store?.locationId || "");
        setRefetchStoreId(false);
    }, [userData, isUserFetching, isUserLoading, setLocationId, setStoreId, refetchStoreId, setRefetchStoreId, id])

}

export default useSetDefaultLocationStore