import React from 'react'
import { DropdownInput } from '../Inputs'

const ProductType = ({ productType, setProductType, readOnly, childRecord }) => {
    const options = [
        { show: 'TABLET', value: 'TABLET' },
        { show: 'TONIC', value: 'TONIC' },
    ]
    return (
        <DropdownInput name="Product Type" options={options} value={productType} setValue={setProductType} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
    )
}

export default ProductType