import React, { useEffect, useState, useRef, useCallback } from 'react';
import secureLocalStorage from 'react-secure-storage';
import {
  useGetRoomQuery,
  useGetRoomByIdQuery,
  useAddRoomMutation,
  useUpdateRoomMutation,
  useDeleteRoomMutation,
} from '../../../redux/services/RoomMasterService';
import { useGetRoomTypeQuery } from '../../../redux/services/RoomTypeMasterService';
import FormHeader from '../FormHeader';
import FormReport from "../FormReportTemplate";
import { toast } from "react-toastify"
import { TextInput, CheckBox, DropdownInput } from "../../../Inputs"
import ReportTemplate from '../ReportTemplate';
import { patientTypes } from '../../../Utils/DropdownData';
import { dropDownListObject } from '../../../Utils/contructObject';

const MODEL = "Room Master";

export default function Form() {
  const [form, setForm] = useState(false);
  const [roomCharge, setRoomCharge] = useState("")
  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("")
  const [name, setName] = useState("");
  const [patientType, setPatientType] = useState('');
  const [roomType, setRoomType] = useState('');

  const [active, setActive] = useState(true);

  const [searchValue, setSearchValue] = useState("");

  const childRecord = useRef(0);

  const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") }
  const { data: allData, isLoading, isFetching } = useGetRoomQuery({ params, searchParams: searchValue });
  const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetRoomByIdQuery(id, { skip: !id });
  const { data: roomTypes } = useGetRoomTypeQuery({ params: { ...params, active: true } });
  const [addData] = useAddRoomMutation();
  const [updateData] = useUpdateRoomMutation();
  const [removeData] = useDeleteRoomMutation();

  const syncFormWithDb = useCallback(
    (data) => {
      if (id) setReadOnly(true);
      else setReadOnly(false);
      setName(data?.name ? data.name : "");
      setPatientType(data?.patientType ? data.patientType : "");
      setRoomType(data?.roomType ? data?.roomType : "")
      setActive(id ? (data?.active ? data.active : false) : true);
      setRoomCharge(data?.roomCharge ? data?.roomCharge : 0)
      childRecord.current = data?.childRecord ? data?.childRecord : 0;
    }, [id])


  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData])


  const data = {
    name, patientType, roomType, roomCharge, companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId"), active, id
  }

  const validateData = (data) => {
    if (data.name && data.patientType && data.roomType) {
      return true;
    }
    return false;
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      await callback(data).unwrap();
      toast.success(text + "Successfully");
    } catch (error) {
      console.log("handle")
    }
  }

  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (!window.confirm("Are you sure save the details ...?")) {
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated")
    } else {
      handleSubmitCustom(addData, data, "Added")
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return
      }
      try {
        await removeData(id).unwrap();
        setId("");
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong")
      }
      ;
    }
  }

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      event.preventDefault();
      saveData();
    }
  }

  const onNew = () => { setId(""); setReadOnly(false); setForm(true); setSearchValue(""); syncFormWithDb(undefined) }

  function onDataClick(id) {
    setId(id);
    setForm(true);
  }
  const tableHeaders = [
    "Code", "Name", "Status"
  ]
  const tableDataNames = ["dataObj.patientType", "dataObj.name", 'dataObj.active ? ACTIVE : INACTIVE']

  if (!form)
    return <ReportTemplate
      heading={MODEL}
      tableHeaders={tableHeaders}
      tableDataNames={tableDataNames}
      loading={
        isLoading || isFetching
      }
      setForm={setForm}
      data={allData?.data}
      onClick={onDataClick}
      onNew={onNew}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />

  return (
    <div onKeyDown={handleKeyDown} className='md:items-start md:justify-items-center grid h-full bg-theme'>
      <div className='flex flex-col frame w-full h-full'>
        <FormHeader onNew={onNew} onClose={() => { setForm(false); setSearchValue(""); }} model={MODEL} saveData={saveData} setReadOnly={setReadOnly} deleteData={deleteData} childRecord={childRecord.current} />
        <div className='flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip'>
          <div className='col-span-3 grid md:grid-cols-2 border overflow-auto'>
            <div className='mr-1 md:ml-2'>
              <fieldset className='frame my-1'>
                <legend className='sub-heading'>Room Info</legend>
                <div className='grid grid-cols-1 my-2 w-1/2'>
                  <DropdownInput
                    name="Patient Type"
                    className="text-sm"
                    options={patientTypes}
                    value={patientType}
                    setValue={setPatientType}
                    required={true}
                    readOnly={readOnly}
                  />
                  <DropdownInput
                    name="Room Type"
                    className="text-sm"
                    options={dropDownListObject(roomTypes?.data, "name", "id")}
                    value={roomType}
                    setValue={setRoomType}
                    required={true}
                    readOnly={readOnly}
                  />
                  <TextInput name="Room Name" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} />
                  <div className="pb-2">
                    {/* <TextInput
                      name="Room Charge"
                      className='w-full'
                      type="number"
                      min={0}
                      value={roomCharge}
                      setValue={setRoomCharge}
                      readOnly={readOnly}
                    /> */}
                  </div>
                  <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} />
                </div>
              </fieldset>
            </div>
          </div>
          <div className='frame overflow-x-hidden'>
            <FormReport
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setId={setId}
              tableHeaders={tableHeaders}
              tableDataNames={tableDataNames}
              data={allData?.data}
              loading={
                isLoading || isFetching
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
