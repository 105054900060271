// roomTypeMasterApi.js
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";
import { ROOMTYPE_API } from "../../Api";

const roomTypeMasterApi = createApi({
  reducerPath: "roomTypeMaster",
  baseQuery,
  tagTypes: ["RoomType"], 
  endpoints: (builder) => ({
    getRoomType: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: ROOMTYPE_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params,
          };
        }
        return {
          url: ROOMTYPE_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params,
        };
      },
      providesTags: ["RoomType"], 
    }),
    getRoomTypeById: builder.query({
      query: (id) => ({
        url: `${ROOMTYPE_API}/${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      providesTags: ["RoomType"], 
    }),
    addRoomType: builder.mutation({
      query: (payload) => ({
        url: ROOMTYPE_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["RoomType"],
    }),
    updateRoomType: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${ROOMTYPE_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["RoomType"],
    }),
    deleteRoomType: builder.mutation({
      query: (id) => ({
        url: `${ROOMTYPE_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RoomType"],
    }),
  }),
});

export const {
  useGetRoomTypeQuery,
  useGetRoomTypeByIdQuery,
  useAddRoomTypeMutation,
  useUpdateRoomTypeMutation,
  useDeleteRoomTypeMutation,
} = roomTypeMasterApi;

export default roomTypeMasterApi;
