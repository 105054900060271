import React, { useState } from 'react'
import PharmDashboard from './PharmaDashboardDetails'
import DashboardCards from './DashboardCards'
import InStockItemDet from './InStockItemDet'
import { RadioButton } from '../../../Inputs'
import Expired from './Expired'

const Pharmacy = () => {
    const [outOfStockReport, setOutOfStockReport] = useState(false)
    const [selectedReport, setSelectedReport] = useState('expired');
    const onHandleChange = (reportType) => {
        if (reportType == 'outOfStockReport') {
            setOutOfStockReport(true)
            setSelectedReport(reportType);
        } else {
            setOutOfStockReport(false)
            setSelectedReport(reportType);
        }
    };
    return (
        <div className='bg-gray-200 py-5'>

            <DashboardCards />
            <div className='grid grid-cols-2 my-5 gap-2 p-2 bg-white'>
                <div className='text-lg text-center mt-4'>
                    <div className='bg-[#9c3030]  text-white
                 flex  justify-between'>   {outOfStockReport ?
                            <div className='text-lg text-center bg-sky-700 text-white'>
                                Out Of Stock Items
                            </div>
                            :
                            <h2>  Expired </h2>}
                        <div className='flex gap-2'>
                            <RadioButton
                                value={selectedReport === 'expired'}
                                onChange={() => onHandleChange('expired')}
                                label=" Expired"
                            />
                            <RadioButton
                                value={selectedReport === 'outOfStockReport'}
                                onChange={() => onHandleChange('outOfStockReport')}
                                label="Out Of Stock"
                            /></div></div>
                    {selectedReport === 'outOfStockReport' ? <PharmDashboard isExpiredOrOutOfStock outOfStockReport={outOfStockReport}
                    /> : <Expired />}


                </div>

                <InStockItemDet />
            </div>
        </div>
    )
}

export default Pharmacy
