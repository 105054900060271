import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../../assets/h-logo.png';
import map from '../../../assets/google-maps.png';
import mobile from '../../../assets/mobile.png';
import gmail from '../../../assets/gmail.png';
import internet from '../../../assets/internet-explorer.png';

const styles = StyleSheet.create({
  page: {
    padding: '20px', 
    fontSize: '10px', 
    fontFamily: 'Helvetica',
    color: '#374151',
    position: 'relative',
  },
  watermark: {
    position: 'absolute',
    top: '50%',  
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    width: '300px', 
    height: '300px',
    opacity: 0.2, 
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '15px',
    paddingBottom: '5px',
    borderBottom: '1px solid #E5E7EB',
    zIndex: 10, 
  },
  logo: {
    width: '70px',
    height: '70px',
  },
  addressContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '5px',
  },
  addressLine: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '3px', 
  },
  addressIcon: {
    width: '10px',
    height: '10px',
    marginRight: '4px',
  },
  address: {
    fontSize: '8px', 
    color: '#6B7280',
  },
  title: {
    textAlign: 'center',
    fontSize: '14px', 
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#2563EB',
    textTransform: 'uppercase',
    letterSpacing: '0.5px', 
    zIndex: 10,
  },
  sectionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px',
    zIndex: 10,
  },
  section: {
    width: '48%',
    padding: '8px',
    backgroundColor: '#F9FAFB',
    borderRadius: '4px',
    border: '1px solid #E5E7EB',
    zIndex: 10,
  },
  sectionBottom: {
    width: '48%',
    alignSelf: 'flex-end',
    padding: '8px',
    backgroundColor: '#F9FAFB',
    borderRadius: '4px',
    border: '1px solid #E5E7EB',
    zIndex: 10,
  },
  addressBottom: {
    width: '48%',
    alignSelf: 'flex-start',
    padding: '8px',
    backgroundColor: '#F9FAFB',
    borderRadius: '4px',
    border: '1px solid #E5E7EB',
    zIndex: 10,
  },
  sectionHeader: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '8px',
    color: 'white',
    backgroundColor: '#006F36',
    padding: '3px 8px', 
    borderRadius: '3px',
    textTransform: 'uppercase',
    letterSpacing: '0.3px',
  },
  fieldGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '6px',
  },
  fieldLabel: {
    width: '45%',
    fontSize: '10px', 
    fontWeight: 'bold',
    color: '#374151',
  },
  fieldValue: {
    width: '50%',
    fontSize: '10px', 
    textAlign: 'right',
    color: '#1F2937',
  },
  signatureSection: {
    marginTop: '25px', 
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px', 
    backgroundColor: '#F3F4F6',
    borderRadius: '4px',
    border: '1px solid #E5E7EB',
  },
  signatureField: {
    width: '30%',
    textAlign: 'center',
    paddingTop: '15px', 
    borderTop: '1px solid #D1D5DB',
    fontSize: '10px',
    color: '#4B5563',
  },
});

const PatientAdmitForm = (data) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image style={styles.watermark} src={logo} />

      <View style={styles.header}>
        <Image style={styles.logo} src={logo} />
        <View style={styles.addressContainer}>
          <View style={styles.addressLine}>
            <Image style={styles.addressIcon} src={map} />
            <Text style={styles.address}>33, Railway Feeder Road, Uttukuli Main Road, Tiruppur</Text>
          </View>
          <View style={styles.addressLine}>
            <Image style={styles.addressIcon} src={mobile} />
            <Text style={styles.address}>9150699976</Text>
          </View>
          <View style={styles.addressLine}>
            <Image style={styles.addressIcon} src={gmail} />
            <Text style={styles.address}>thedoctor46.riwan@gmail.com</Text>
          </View>
          <View style={styles.addressLine}>
            <Image style={styles.addressIcon} src={internet} />
            <Text style={styles.address}>www.kchospital.net</Text>
          </View>
        </View>
      </View>

      <Text style={styles.title}>Patient Admit Form</Text>

      <View style={styles.sectionContainer}>
        <View style={styles.section}>
          <Text style={styles.sectionHeader}>Patient Details</Text>
          <View style={styles.fieldGroup}>
            <Text style={styles.fieldLabel}>Patient Name:</Text>
            <Text style={styles.fieldValue}>John Doe</Text>
          </View>
          <View style={styles.fieldGroup}>
            <Text style={styles.fieldLabel}>Patient ID:</Text>
            <Text style={styles.fieldValue}>12345</Text>
          </View>
          <View style={styles.fieldGroup}>
            <Text style={styles.fieldLabel}>Date of Admission:</Text>
            <Text style={styles.fieldValue}>12/09/2024</Text>
          </View>
          <View style={styles.fieldGroup}>
            <Text style={styles.fieldLabel}>Gender:</Text>
            <Text style={styles.fieldValue}>Male</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionHeader}>Admit Details</Text>
          <View style={styles.fieldGroup}>
            <Text style={styles.fieldLabel}>Room No:</Text>
            <Text style={styles.fieldValue}>101</Text>
          </View>
          <View style={styles.fieldGroup}>
            <Text style={styles.fieldLabel}>Ward:</Text>
            <Text style={styles.fieldValue}>General</Text>
          </View>
          <View style={styles.fieldGroup}>
            <Text style={styles.fieldLabel}>Doctor:</Text>
            <Text style={styles.fieldValue}>Dr. Smith</Text>
          </View>
        </View>
      </View>

      <View style={styles.sectionContainer}>
        <View style={styles.addressBottom}>
          <Text style={styles.sectionHeader}>Address</Text>
          <Text style={styles.address}>33, Railway Feeder Road, Uttukuli Main Road, Tiruppur</Text>
          <Text style={styles.address}>9150699976</Text>
          <Text style={styles.address}>thedoctor46.riwan@gmail.com</Text>
          <Text style={styles.address}>www.kchospital.net</Text>
        </View>

        <View style={styles.sectionBottom}>
          <Text style={styles.sectionHeader}>Medical Details</Text>
          <View style={styles.fieldGroup}>
            <Text style={styles.fieldLabel}>Diagnosis:</Text>
            <Text style={styles.fieldValue}>Flu</Text>
          </View>
          <View style={styles.fieldGroup}>
            <Text style={styles.fieldLabel}>Prescribed Medication:</Text>
            <Text style={styles.fieldValue}>Tylenol, Ibuprofen</Text>
          </View>
        </View>
      </View>

      <View style={styles.signatureSection}>
        <View style={styles.signatureField}>
          <Text>Doctor's Signature</Text>
        </View>
        <View style={styles.signatureField}>
          <Text>Patient's Signature</Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default PatientAdmitForm;
