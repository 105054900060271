import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import waterLogo from '../../../assets/ort.jpg';

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  infoItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 3,
  },
  doctorInfo: {
    fontSize: 10,
    marginLeft: 5,
  },
  page: {
    padding: 30,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    alignItems: 'center',
  },
  tableCol: {
    flex: 1,
    padding: 5,
    borderRightWidth: 1,
    borderRightColor: '#000',
  },
  tableCell: {
    fontSize: 10,
    textAlign: 'center',
  },
  watermark: {
    position: 'absolute',
    opacity: 0.1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: "#1F6584",
    paddingLeft: 5,
    paddingRight: 5

  },
  column: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  doctorDetailsColumn: {
    alignItems: 'flex-start',
  },
  logoColumn: {
    alignItems: 'center',
  },
  addressColumn: {
    alignItems: 'flex-end',
  },
  headerText: {
    fontSize: 10,
    color: 'white',
  },
  headerText1: {
    fontSize: 10,
    color: 'white',
  },
  textContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5,
    padding: 5,
    backgroundColor: '#D0D0D0',
    borderRadius: 5,
  },
  text: {
    margin: 5,
    fontSize: 12,
    color: '#1F6584',
  },
  headerLogo: {
    width: 70,
    height: 70,
    resizeMode: 'contain',
  },
  subtitle: {
    fontSize: 12,
    margin: 3,
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#61BFB5',
    width: 130,
    padding: 2,
    paddingLeft: 5,
    borderRadius: 30
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '12%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    padding: 5,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  tableHeader: {
    backgroundColor: '#e0e0e0',
    fontWeight: 'bold',
  },
  highlightCell: {
    backgroundColor: '#f9f9f9',
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    marginTop: 10,
  },
  tableRow: {
    flexDirection: 'row',
    backgroundColor: '#f0f0f0',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    padding: 8,
  },
  tableCell: {
    fontSize: 10,
  },
  footer: {
    fontSize: 10,
    textAlign: 'center',
    marginTop: 10,
  },
});

const PrintFormatInvoice = ({ data, empData, branchData }) => {
  console.log(data, "data  ")
  if (!data) {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Text>Error: Data not available.</Text>
        </Page>
      </Document>
    );
  }

  const patient = data?.Patient || {};
  const prescriptions = data?.prescriptions || [];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.watermark} src={waterLogo} />

        <View style={styles.headerContainer}>
          <View style={[styles.column, styles.doctorDetailsColumn]}>
            <Text style={styles.headerText}>Dr. {empData?.name || 'N/A'}</Text>
            <Text style={styles.headerText}>Dep : {empData?.specialization || 'N/A'}</Text>
          </View>
          <View style={[styles.column, styles.logoColumn]}>
            <Image style={styles.headerLogo} source={waterLogo} />
          </View>
          <View style={[styles.column, styles.addressColumn]}>
            <Text style={styles.headerText1}>KMCH HOSPITAL</Text>
            <Text style={styles.headerText1}>{branchData?.data?.contactAddressBranch || 'N/A'}</Text>
            <Text style={styles.headerText1}>{branchData?.data?.contactCityBranch || 'N/A'}-{branchData?.data?.contactPincodeBranch || 'N/A'}</Text>
            <Text style={styles.headerText1}>{branchData?.data?.contactMobile || 'N/A'}</Text>
          </View>
        </View>

        <View style={{ marginBottom: 10 }}>
          <Text style={styles.subtitle}>Patient Information</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Name:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Patient.name || 'N/A'}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Age:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Patient.age || 'N/A'}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Mobile No:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Patient.mobile || 'N/A'}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Reg No:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Patient.regNo || 'N/A'}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Gender:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Patient.gender || 'N/A'}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Date:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{new Date(data.Patient.regDate).toLocaleDateString() || 'N/A'}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.textContainer}>
          <Text style={styles.text}>Reason for Visit: {data.reasonForVisit || 'N/A'}</Text>
          <Text style={styles.text}>Temperature: {data.temperature || 'N/A'}</Text>
          <Text style={styles.text}>Pulse: {data.pulse || 'N/A'}</Text>
          <Text style={styles.text}>Sugar: {data.sugar || 'N/A'}</Text>
        </View>
        <Text style={styles.subtitle}>Prescription Details</Text>
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Medicine</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Dosage</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Unit</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Morning (A B)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Morning (B B)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Lunch (A B)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Lunch (B B)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Dinner (A B)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Dinner (B B)</Text>
            </View>
          </View>
          {data.Prescriptions.map((prescription, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{prescription.Product?.name || 'N/A'}</Text>
              </View>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{prescription.dosage || 'N/A'}</Text>
              </View>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{prescription.unit || 'N/A'}</Text>
              </View>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{prescription.MA ? 'Yes' : 'No'}</Text>
              </View>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{prescription.MB ? 'Yes' : 'No'}</Text>
              </View>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{prescription.AA ? 'Yes' : 'No'}</Text>
              </View>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{prescription.AB ? 'Yes' : 'No'}</Text>
              </View>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{prescription.NA ? 'Yes' : 'No'}</Text>
              </View>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{prescription.NB ? 'Yes' : 'No'}</Text>
              </View>
            </View>
          ))}
        </View>


        <Text style={styles.subtitle}>Bottom Surgery Details</Text>
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Surgery Type</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Surgeon</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Comments</Text>
            </View>
          </View>
          {data.Prescriptions.map((surgery, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{surgery.type || 'N/A'}</Text>
              </View>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{surgery.date || 'N/A'}</Text>
              </View>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{surgery.surgeon || 'N/A'}</Text>
              </View>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{surgery.comments || 'N/A'}</Text>
              </View>
            </View>
          ))}
        </View>


        <Text style={styles.subtitle}>Scan Details</Text>
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Scan Type</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Comments</Text>
            </View>
          </View>
          {data.PrescribedLabTest.map((PrescribedLabTest, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{PrescribedLabTest.LabTest.name || 'N/A'}</Text>
              </View>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{PrescribedLabTest.date || 'N/A'}</Text>
              </View>
              <View style={[styles.tableCol, index % 2 === 0 && styles.highlightCell]}>
                <Text style={styles.tableCell}>{PrescribedLabTest.comments || 'N/A'}</Text>
              </View>
            </View>
          ))}
        </View>


        <Text style={styles.footer}>Contact us at: {branchData?.data?.contactEmail || 'N/A'}</Text>
        <Text style={styles.footer}>Thank you for using our service.</Text>
      </Page>
    </Document>
  );
};

export default PrintFormatInvoice;
