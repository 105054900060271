import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from '../../../redux/features/opentabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faDollarSign, faClock, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { WEB_LINK } from '../../../icons';
import { MailIcon, ChatAltIcon } from '@heroicons/react/outline';
import { FaBed  } from 'react-icons/fa';
import { GiBed } from "react-icons/gi";

const NumericCard = ({ patientList, filterDate, setFilterDate }) => {
    const visits = patientList?.data;
    const consultedCount = visits ? visits.filter((visit) => visit.consulted === true).length : 0;
    const notConsulted = visits ? visits.filter(visit => visit.consulted !== true).length : 0;
    const paymentDone = visits ? visits.filter(visit => visit?.PatientVisitPayment).length : 0;
    const paymentPending = visits ? visits.filter(visit => !visit?.PatientVisitPayment).length : 0;

    const data = [
        {
            name: "Total Visits",
            borderColor: "#4CAF50",
            value: `${(visits?.length || 0)}`,
            icon: faUser,
        },
        {
            name: "Consulted",
            borderColor: "#4CAF50",
            value: consultedCount,
            icon: faCheckCircle,
        },
        {
            name: "Waiting",
            borderColor: "#FFC107",
            value: notConsulted,
            icon: faClock,
        },
        {
            name: "Payment Done",
            borderColor: "#4CAF50",
            value: paymentDone,
            icon: faDollarSign,
        },
        {
            name: "Payment Pending",
            borderColor: "#F44336",
            value: paymentPending,
            icon: faExclamationCircle,
        },
    ];
    const occupiedBeds = 12
    const balance = 10

    const [isModalOpen, setIsModalOpen] = useState(false);
  console.log(isModalOpen,"isModel")
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const dispatch = useDispatch();

    return (
        <div className='flex flex-col w-full h-[35%]'>
       <div className='flex space-x-6 mb-2 items-center justify-between'>
    <div className='flex-shrink-0 '>
        <button
            type='button'
            onClick={() => dispatch(push({ name: "PATIENT VISIT TRANSACTION" }))}
            className='bg-emerald-600 tracking-wider text-white text-sm  py-2 px-4 rounded-lg shadow-lg hover:bg-emerald-500 transition duration-300 ease-in-out flex items-center'
        >
            <span className='mr-2'>Generate Token</span>
            <span>{WEB_LINK}</span>
        </button>
    </div>

    <div className=' flex bg-gray-50 px-6 py-3  rounded-lg shadow-lg items-center'>
    <a
        href="mailto:logeshsaran55@gmail.com"
        className="flex items-center text-gray-700 hover:text-[#009688] mr-6"
    >
        <MailIcon className="h-6 w-6 mr-2" />
        <span className="text-sm font-medium">Gmail</span>
    </a>
    <div>
        <div
            className="flex items-center text-gray-700 hover:text-[#009688] cursor-pointer"
            onClick={toggleModal}
        >
            <ChatAltIcon className="h-6 w-6 mr-2" />
            <span className="text-sm font-medium">Message</span>
        </div>

        {isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg shadow-lg w-full max-w-sm">
                    <div className="flex justify-between items-center p-3 border-b border-gray-200">
                        <h3 className="text-md font-semibold">Messsage Only Doctor Sir</h3>
                        <button
                            className="text-white hover:bg-red-500 bg-red-800 p-1  rounded-full"
                            onClick={toggleModal}
                        >
                            &times;
                        </button>
                    </div>

                    <div className="p-3">
                        <textarea
                            className="w-full h-24 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
                            placeholder="Type your message here..."
                        />
                    </div>

                    <div className="flex justify-end p-3 border-t border-gray-200">
                        <button
                            className="bg-emerald-600 text-white px-3 py-1 rounded-lg shadow-md hover:bg-emerald-500 transition duration-300 ease-in-out"
                            onClick={toggleModal}
                        >
                            Send
                        </button>
                    </div>
                </div>
            </div>
        )}
    </div>
</div>
<div className="flex space-x-4 bg-gray-50 px-6 py-3  rounded-lg ">
      <div className="flex items-center space-x-2">
  <FaBed className="text-blue-500" size={24} />
  <span className="text-sm">Occupied Bed = 
    <span className="ml-2 inline-flex items-center justify-center w-8 h-6 text-white bg-blue-500 rounded-full">
      20
    </span>
  </span>
</div>


      <div className="flex items-center space-x-2">
        <GiBed  className="text-green-500" size={24} /> 
        <span className="text-sm">Balance Bed =  <span className="ml-2 inline-flex items-center justify-center w-8 h-6 text-white bg-green-500 rounded-full">
      10
    </span></span>
      </div>
    </div>

    <div className='flex-shrink-0'>
        <input
            type="date"
            className='bg-white rounded-lg border border-gray-300 p-2 text-sm shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500'
            value={filterDate}
            onChange={(e) => {
                if (!e.target.value) return;
                setFilterDate(e.target.value);
            }}
        />
    </div>
</div>
                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 h-full'>
                    {data.map((val, i) => (
                        <div
                            key={i}
                            className={`flex items-center bg-white p-4 rounded-lg shadow-md border-l-4`}
                            style={{ borderColor: val.borderColor }}
                        >
                            <div className='flex-shrink-0'>
                                <FontAwesomeIcon icon={val.icon} className='text-3xl' style={{ color: val.borderColor }} />
                            </div>
                            <div className='flex-grow flex flex-col items-center'>
                                <div className='text-2xl font-bold' style={{ color: val.borderColor }}>
                                    {val.value}
                                </div>
                                <div className='text-sm text-gray-700'>
                                    {val.name}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
        </div>
    );
};

export default NumericCard;
