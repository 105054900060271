import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment/moment';
import { useGetPatientVisitByIdQuery } from '../../../redux/pharmaServices/PatientVisitTransaction';
import { DisabledInput } from '../../../Inputs';


const LastVisitDetails = ({ patientVisitId, viewFullDetail }) => {
  const [comments, setComments] = useState("");
  const [reasonForVisit, setReasonForVisit] = useState("");
  const [bp, setBp] = useState("");
  const [sugar, setSugar] = useState("");
  const [pulse, setPulse] = useState("");
  const [temperature, setTemperature] = useState("");
  const [weight, setWeight] = useState("");
  const [date, setDate] = useState("");

  const syncFormWithDb = useCallback((data) => {
    if (!data) return
    setDate(moment.utc(data.date).format("DD-MM-YYYY"));
    setBp(data.bp);
    setSugar(data.sugar);
    setPulse(data.pulse);
    setTemperature(data.temperature);
    setWeight(data.weight ? data.weight : "");
    setReasonForVisit(data.reasonForVisit);
    // Patient Data
    setComments(data.comments ? data.comments : "");
  }, [])
  const { data: singleData, isLoading: isSingleLoading, isFetching: isSingleFetching } = useGetPatientVisitByIdQuery(patientVisitId, { skip: !patientVisitId })
  useEffect(() => {
    if (patientVisitId) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, patientVisitId, syncFormWithDb, singleData])

  return (
    <div className={`flex flex-col  bg-amber-100 w-full justify-between col-span-1 p-3`}>
      <div className='text-center bg-gray-200 text-blue-900 m-1'>Last Visit Details</div>
      <div className='grid grid-cols-2'>
        <div className='flex flex-col gap-1'>
          <DisabledInput name="Date" type="text" value={date} />
          <DisabledInput name="Blood Pressure" type="text" value={bp} />
          <DisabledInput name="Sugar Level" type="text" value={sugar} />
        </div>
        <div className='flex flex-col gap-1'>
          <DisabledInput name="Pulse" type="text" value={pulse} />

          <DisabledInput name="Temperature" type="text" value={temperature} />
          <DisabledInput name="Reason For Visit" type="text" value={reasonForVisit} />
        </div>
      </div>
      <div className='justify-between text-sm items-center'>
        <label htmlFor="comments" className='text-sm'>Comments</label>
        <textarea name="comments" className='w-full input-field focus:outline-none w-72  border border-gray-500 rounded' cols="30" rows="2" value={comments} disabled></textarea>
      </div>
      <div >
        <span onClick={viewFullDetail} className='underline text-sm text-emerald-800 font-bold cursor-pointer'>View Full Details</span>
      </div>
    </div>
  )
}

export default LastVisitDetails
