import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import logo from '../../../assets/images.png';
import { priceWithTax } from '../../../Utils/helper';

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  header: {
    fontSize: 14,
    marginBottom: 5,
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#6300A0',
    padding: 5,
  },
  header1: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  column: {
    flexGrow: 1,
    fontSize: 10,
    backgroundColor: '#FEF3C7',
    paddingRight: 10,
  },
  logo: { 
    width: 75,
    height: 75,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '11.11%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    backgroundColor: '#6300A0',
    color: 'white',
    textAlign: 'center',
    padding: 5,
    fontSize: 10,
  },
  tableCol: {
    width: '11.11%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    padding: 5,
    fontSize: 10,
    backgroundColor: '#FEF3C7'
  },
  bold: {
    fontWeight: 'bold',
    padding: 2,
  },
  totalTable: {
    display: 'table',
    width: 300,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#E0E0E0',
    marginBottom: 20,
    alignSelf: 'flex-end',
  },
  totalRow: {
    flexDirection: 'row',
  },
  totalColHeader: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#E0E0E0',
    backgroundColor: '#F2F2F2',
    padding: 5,
  },
  totalCol: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#E0E0E0',
    padding: 5,
    backgroundColor: '#FEF3C7',
  },
  tableCellHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#6300A0',
  },
  tableCell: {
    fontSize: 12,
    color: '#333',
  },
});

const SalesBill = (data) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const items = [
    { item: 'Item 1', brand: 'Brand A', product: 'Product X', category: 'Category Y', quantity: 1, taxableAmount: '$100.00', tax: '$10.00', price: '$110.00', total: '$110.00' },
    { item: 'Item 2', brand: 'Brand B', product: 'Product Y', category: 'Category Z', quantity: 2, taxableAmount: '$50.00', tax: '$5.00', price: '$55.00', total: '$110.00' },
  ];

  const totals = {
    subtotal: '$200.00',
    tax: '$20.00',
    total: '$220.00',
  };

  function getTotal(field1, field2) {
    const total = data.data.SalesBillItems.reduce((accumulator, current) => {
      return accumulator + parseFloat(current[field1] && current[field2] ? current[field1] * current[field2] : 0)
    }, 0);
    return parseFloat(total);
  }

  function getTaxTotal() {
    const total = data.data.SalesBillItems.reduce((accumulator, current) => {
      return accumulator + parseFloat(current.tax || 0);
    }, 0);
    return parseFloat(total);
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text>Sales Invoice</Text>
        </View>
        <View style={styles.header1}>
          <View style={styles.column}>
            <Image style={styles.logo} src={logo} />
          </View>
          <View style={styles.column}>
            <Text style={[styles.bold, { color: '#6300A0', fontSize: 12 }]}>Customer Details</Text>
            <Text>Name: John Doe</Text>
            <Text>Address: 123 Main Street</Text>
            <Text>City: Tirupur</Text>
            <Text>Country: India</Text>
            <Text>Date: 2024-07-09</Text>
          </View>
          <View style={styles.column}>
            <Text style={[styles.bold, { color: '#6300A0', fontSize: 12 }]}>Doctor Details</Text>
            <Text>Name: John Doe</Text>
            <Text>Address: 123 Main Street</Text>
            <Text>City: Tirupur</Text>
            <Text>Country: India</Text>
            <Text>Date: 2024-07-09</Text>
          </View>
          <View style={styles.column}>
            <Text style={[styles.bold, { color: '#6300A0', fontSize: 12 }]}>Pharmacy Details</Text>
            <Text>Name: Pharmacy Name</Text>
            <Text>Address: 123 Pharmacy Street</Text>
            <Text>Phone: +1 234 567 890</Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColHeader}>S.No</Text>
            <Text style={[styles.tableColHeader, { width: 100 }]}>Brand</Text>
            <Text style={[styles.tableColHeader, { width: 100 }]}>Product</Text>
            <Text style={[styles.tableColHeader, { width: 70 }]}>Exp Date</Text>
            <Text style={[styles.tableColHeader,{ width: 30}]}>Qty</Text>
            <Text style={styles.tableColHeader}>Price</Text>
            <Text style={styles.tableColHeader}>Taxable Amount</Text>
            <Text style={[styles.tableColHeader,{ width: 30}]}>Tax</Text>
            <Text style={[styles.tableColHeader, { width: 100 }]}>Total</Text>
          </View>
          {data.data.SalesBillItems.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCol}>{index + 1}</Text>
              <Text style={[styles.tableCol,{ width : 100}]}>{item.brandName}</Text>
              <Text style={[styles.tableCol,{ width : 100}]}>{item.productName}</Text>
              <Text style={[styles.tableCol,{ width : 70}]}>{formatDate(item.expiryDate)}</Text>
              <Text style={[styles.tableCol, {width: 30}]}>{item.qty}</Text>
              <Text style={styles.tableCol}>{(item.salePrice).toFixed(2)}</Text>
              <Text style={styles.tableCol}>{(item.qty * item.salePrice).toFixed(2)}</Text>
              <Text style={[styles.tableCol, {width: 30}]}>{item.tax}%</Text>
              <Text style={[styles.tableCol,{ width : 100}]}> {priceWithTax((parseFloat((item?.qty) || 0) * parseFloat(item.salePrice) || 0), item?.tax || 0)}</Text>
            </View>
          ))}
        </View>
        <View style={styles.totalTable}>
          <View style={styles.totalRow}>
            <View style={styles.totalColHeader}>
              <Text style={styles.tableCellHeader}>Description</Text>
            </View>
            <View style={styles.totalColHeader}>
              <Text style={styles.tableCellHeader}>Amount</Text>
            </View>
          </View>
          <View style={styles.totalRow}>
            <View style={styles.totalCol}>
              <Text style={styles.tableCell}>Taxable Amount:</Text>
            </View>
            <View style={styles.totalCol}>
              <Text style={styles.tableCell}>{getTotal("qty", "salePrice").toFixed(2)}</Text>
            </View>
          </View>
          <View style={styles.totalRow}>
            <View style={styles.totalCol}>
              <Text style={styles.tableCell}>Tax Amount:</Text>
            </View>
            <View style={styles.totalCol}>
              <Text style={styles.tableCell}>{getTaxTotal().toFixed(2)}</Text>
            </View>
          </View>
          <View style={styles.totalRow}>
            <View style={styles.totalCol}>
              <Text style={styles.tableCell}>Total:</Text>
            </View>
            <View style={styles.totalCol}>
              <Text style={styles.tableCell}>{(getTotal("qty", "salePrice") + getTaxTotal()).toFixed(2)}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default SalesBill;
