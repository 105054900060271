import React from "react";
import { useSelector } from "react-redux";
import {
  NewButton,
  SaveButton,
  EditButton,
  DeleteButton,
  CloseButton,
  PrintButtonOnly,
  SearchButton
} from "../../../Buttons";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import { useGetPagePermissionsByIdQuery, useGetPagesQuery } from "../../../redux/services/PageMasterService";
import { filterByPatientType } from "../../../Utils/DropdownData";


const FormHeader = ({
  model,
  saveData,
  setReadOnly,
  deleteData,
  onClose = null,
  onNew,
  childRecord = 0,
  onPrint = null,
  openReport = null,
  childRecordValidationActions = ["edit", "delete"],
  setFilterByPatient,
  filterByPatient,
  isFilterPatient,
}) => {

  const openTabs = useSelector((state) => state.openTabs);

  const activeTab = openTabs.tabs.find(tab => tab.active);


  const { data: pageData } = useGetPagesQuery({})

  const currentPageId = (pageData?.data || []).find(i => i.name === activeTab.name)?.id || '';

  const userRoleId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userRoleId"
  );
  const {
    data: currentPagePermissions,
    isLoading,
    isFetching,
  } = useGetPagePermissionsByIdQuery({ currentPageId, userRoleId }, { skip: !(currentPageId && userRoleId) });


  const IsSuperAdmin = () => {
    return JSON.parse(
      secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "superAdmin"
      )
    );
  };

  const IsDefaultAdmin = () => {
    return JSON.parse(
      secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "defaultAdmin"
      )
    );
  };


  const isCurrentFinYearActive = () => {
    return Boolean(
      secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentFinYearActive"
      )
    );
  };

  const hasPermission = (callback, type) => {
    if (childRecordValidationActions.includes(type) && childRecord !== 0) {
      toast.error("Child Record Exists", { position: "top-center" });
      return;
    }
    if (IsSuperAdmin()) {
      callback();
    } else {
      if (isCurrentFinYearActive()) {
        if (IsDefaultAdmin()) {
          callback();
        } else if (currentPagePermissions?.data[type]) {
          callback();
        } else {
          toast.info(`No Permission to ${type}...!`, {
            position: "top-center",
          });
        }
      } else {
        toast.info(" Past Fin Year Only can view!", { position: "top-center" });
      }
    }
  };
  return (

    <div className="md:flex md:items-center md:justify-between page-heading">
      {model ? (
        <div className="heading text-center md:mx-10">
          {/* {model} */}
        </div>
      ) : (
        <div></div>
      )}
      <div className="flex sub-heading">

        {/* {
          isFilterPatient &&
          <div className="flex gap-x-5 justify-center items-center mr-9 w-72">
            <label className={`md:text-start flex text-sm text-white w-36 mt-1`}>Patient Type</label>
            <select
              name="name" className={`${"input-field border bg-white text-gray-600 p-1 border-gray-500 md:col-span-2 col-span-1 rounded text-sm"} `}
              value={filterByPatient} onChange={(e) => { setFilterByPatient(e.target.value); }} >
              <option value="" >Select</option>
              {filterByPatientType?.map((option, index) => <option key={index} value={option.value} >
                {option.show}
              </option>)}
            </select>
          </div>
        } */}

        <NewButton onClick={() => { hasPermission(onNew, "create") }} />
        <EditButton
          onClick={() => {
            hasPermission(setReadOnly, "edit");
          }}
        />
        <SaveButton
          onClick={saveData}
        />
        <DeleteButton
          onClick={() => {
            hasPermission(deleteData, "delete");
          }}
        />
        {openReport && <SearchButton onClick={openReport} />}
        {onPrint &&
          <PrintButtonOnly onClick={onPrint}
          />}
        {onClose &&
          <CloseButton onClick={onClose} />
        }
      </div>
    </div>

  );
};

export default FormHeader;
