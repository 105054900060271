import React from 'react'
import { DropdownInput, MultiSelectDropdown } from '../Inputs';
import { dropDownListObject, multiSelectOption } from '../Utils/contructObject';
import { useGetRoomQuery } from '../redux/services/RoomMasterService';

const RoomMasterDropdown = ({ multiSelect, withoutLabel, readOnly, name, selected, setSelected, patientType }) => {
    const { data } = useGetRoomQuery({ params: { patientType } });
    return (
        <>
            {withoutLabel ?
                <select value={selected} onChange={(e) => setSelected(e.target.value)} className='w-full table-data-input'
                    disabled={readOnly}
                >
                    <option value="">Select</option>
                    {(data ? data.data : []).map(item =>
                        <option key={item.id} value={item.id}>{item.name}</option>
                    )}
                </select>
                :
                <>
                    {multiSelect ?
                        <MultiSelectDropdown readOnly={readOnly} name={name} selected={selected} setSelected={setSelected}
                            options={multiSelectOption(data ? data.data : [], "name", "id")} />
                        :
                        <DropdownInput readOnly={readOnly} name={name} value={selected} setValue={setSelected}
                            options={dropDownListObject(data ? data.data : [], "name", "id")} clear />
                    }
                </>
            }
        </>
    )
}

export default RoomMasterDropdown
