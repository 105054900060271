import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push, remove } from "../../../redux/features/opentabs";
import {
  CountryMaster, PageMaster, StateMaster, CityMaster, RoomMaster, RoomTypeMaster,
  DepartmentMaster, EmployeeCategoryMaster, FinYearMaster, UserAndRolesMaster,
  AccountSettings, ControlPanel, EmployeeMaster, CompanyMaster, PageGroupMaster
} from "../../components";
import { PatientVisitTransaction, DoctorConsultation, LabTestTypeMaster, LabTestMaster } from "../../../pharma/components";
import { CLOSE_ICON, DOUBLE_NEXT_ICON } from "../../../icons";
import {
  BarcodeMaster, BinMaster, ExpiredStockReturnEntry, HsnMaster, LocationMaster, PartyMaster, Payments, ProductBrandMaster, ProductCategoryMaster, ProductMaster, PurchaseBillEntry, PurchaseRegister,
  PurchaseReturn, RackMaster, SalesBillEntry, SalesRegister, SalesReturn, UomMaster, PatientAdmisson,
  Discharge, PatientTransfer,
  FeesTypeMaster,
  OpeningStock,
  StockTransfer,
  StockDelivery,
  StockReceipt
} from "../../../Pharmacy/Components";
import StockReport from "../../../Pharmacy/Components/StockReport";
import Dashboard from "../../../Pharmacy/Components/RecepDashboard";
import Pharmacy from "../../../pharma/components/PharmacyDashboard";
import { PAYMENT_PAGE_NAME } from "../../../Constants";
import useOutsideClick from "../../../CustomHooks/handleOutsideClick";
import ExpiryReport from "../../../Pharmacy/Components/ExpiryRegister";


const ActiveTabList = () => {
  const openTabs = useSelector((state) => state.openTabs);
  const dispatch = useDispatch();
  const [showHidden, setShowHidden] = useState(false);

  const ref = useOutsideClick(() => { setShowHidden(false) })

  const tabs = {
    "PAGE MASTER": <PageMaster />,
    "PAGE GROUP MASTER": <PageGroupMaster />,
    "COUNTRY MASTER": <CountryMaster />,
    "STATE MASTER": <StateMaster />,
    "CITY MASTER": <CityMaster />,
    "DEPARTMENT MASTER": <DepartmentMaster />,
    "EMPLOYEE CATEGORY MASTER": <EmployeeCategoryMaster />,
    "FIN YEAR MASTER": <FinYearMaster />,
    "USERS & ROLES": <UserAndRolesMaster />,
    "ACCOUNT SETTINGS": <AccountSettings />,
    "CONTROL PANEL": <ControlPanel />,
    "EMPLOYEE MASTER": <EmployeeMaster />,
    "COMPANY MASTER": <CompanyMaster />,
    "PATIENT VISIT TRANSACTION": <PatientVisitTransaction />,
    "DOCTOR CONSULTATION DASHBOARD": <DoctorConsultation />,
    "LAB TEST TYPE MASTER": <LabTestTypeMaster />,
    "LAB TEST MASTER": <LabTestMaster />,
    "PRODUCT BRAND MASTER": <ProductBrandMaster />,
    "PRODUCT CATEGORY MASTER": <ProductCategoryMaster />,
    "PRODUCT MASTER": <ProductMaster />,
    "PURCHASE BILL ENTRY": <PurchaseBillEntry />,
    "PARTY MASTER": <PartyMaster />,
    "SALES BILL ENTRY": <SalesBillEntry />,
    "PURCHASE RETURN": <PurchaseReturn />,
    "SALES RETURN": <SalesReturn />,
    "PURCHASE REGISTER": <PurchaseRegister />,
    "SALES REGISTER": <SalesRegister />,
    "CONVERSION MASTER": <UomMaster />,
    'STOCK REGISTER': <StockReport />,
    'LOCATION MASTER': <LocationMaster />,
    'RACK MASTER': <RackMaster />,
    'BIN MASTER': <BinMaster />,
    'BARCODE MASTER': <BarcodeMaster />,
    'HSN MASTER': <HsnMaster />,
    'RECEPTION DASHBOARD': <Dashboard />,
    'PATIENT ADMISSION FORM': <PatientAdmisson />,
    'PATIENT TRANSFER FORM': <PatientTransfer />,
    'PHARMACY': <Pharmacy />,
    [PAYMENT_PAGE_NAME]: <Payments />,
    'EXPIRED STOCK RETURN': <ExpiredStockReturnEntry />,
    "ROOM MASTER": <RoomMaster />,
    "ROOM TYPE MASTER": <RoomTypeMaster />,
    "DISCHARGE": <Discharge />,
    "FEESTYPE MASTER": <FeesTypeMaster />,
    "OPENING STOCK": <OpeningStock />,
    "STOCK DELIVERY": <StockDelivery />,
    "STOCK RECEIPT": <StockReceipt />,
    "EXPIRY REGISTER": <ExpiryReport />
  };
  const innerWidth = window.innerWidth;
  const itemsToShow = innerWidth / 130;

  const currentShowingTabs = openTabs.tabs.slice(0, parseInt(itemsToShow));
  const hiddenTabs = openTabs.tabs.slice(parseInt(itemsToShow));

  return (
    // <div className="relative ">
    <div className=" md:top-10 left-0 w-full ">
      <div className="flex justify-between">
        <div className="flex gap-2 ">
          {currentShowingTabs.map((tab, index) => (
            <div
              key={index}
              className={`rounded-t-md text-[15px] flex justify-center gap-1 text-xs p-2  ${tab.active ? "bg-green-700 text-white" : "bg-gray-300"
                }`}
            >
              <button
                onClick={() => {
                  dispatch(push({ name: tab.name }));
                }}
              >
                {tab.name}
              </button>
              <button className="px-1 rounded-xs transition"
                onClick={() => {
                  dispatch(remove({ name: tab.name }));
                }}
              >
                {CLOSE_ICON}
              </button>
            </div>
          ))}
        </div>
        <div>
          {(hiddenTabs.length !== 0) &&
            <button onClick={() => setShowHidden(true)}>
              {DOUBLE_NEXT_ICON}
            </button>
          }
        </div>
        {showHidden &&
          <ul ref={ref} className="absolute right-0 top-5 bg-gray-200 z-50 text-xs p-1">
            {hiddenTabs.map(tab =>
              <li key={tab.name} className={`flex justify-between  ${tab.active ? "bg-green-600" : "bg-gray-300"
                } `}>
                <button
                  onClick={() => {
                    dispatch(push({ name: tab.name }));
                  }}
                >
                  {tab.name}
                </button>
                <button className="hover:bg-red-400 px-1 rounded-xs transition"
                  onClick={() => {
                    dispatch(remove({ name: tab.name }));
                  }}
                >
                  {CLOSE_ICON}
                </button>
              </li>
            )}
          </ul>
        }
      </div>
      {openTabs.tabs.map((tab, index) => (
        <div key={index} className={`${tab.active ? "block" : "hidden"} `}>
          {tabs[tab.name]}
        </div>
      ))}
    </div>
  );
};

export default ActiveTabList;
