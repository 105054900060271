import React from "react";

export default function SearchDropDown({ items, hideNavBar, sethideNavBar, dataOnClick }) {
  const navBatItemsStyle = hideNavBar ? "hidden" : "";
  return (
    <div className={`overflow-y-auto my-2 max-h-20 rounded-md z-10 ${navBatItemsStyle}`}>
      {items.map((item, index) =>
        <div key={index} type='link' className='font-bold  nav-dropdown-bg z-99 p-2 text-center' onClick={() => { dataOnClick(item.id) }}>
          {item.name}
        </div>)}
    </div>
  )
}






