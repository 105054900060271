import React from 'react'
import { View, Text, Image } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import pixelFinder from '../../../Utils/pixelFinder';
import { getDateFromDateTimeToDisplay, getMonthYearFromDateTimeToDisplay } from '../../../Utils/helper';
import moment from 'moment';
import BarcodeGenerator from '../BarCodeGenerator';


const Grid = ({ data, partyDetails, barCodeGen }) => {

    let count = 1;
    return (
        <>
            <Text style={tw("text-lg w-full text-center mt-2")}> CASH BILL </Text>

            <View style={tw("w-full  flex flex-row items-center  ")}>

                <View style={tw("flex flex-col  w-full justify-around h-full  ")}>


                    <View style={tw("flex flex-row justify-start gap-x-2 w-full  border-t-2  border-b-2 border-gray-500 p-2")} >
                        <View style={tw("flex flex-row w-1/2 gap-x-2 ")}>
                            <Text style={[tw("text-xs font-bold ml-11 w-1/2 text-start"), { fontWeight: 900, fontFamily: "Times-Bold" }]}> Invoice No:</Text>
                            <Text style={tw("text-xs text-start -ml-8")}>  {data?.docId} </Text>
                        </View>
                        <View style={tw("flex flex-row gap-x-2 w-1/2 ml-1")}>
                            <Text style={[tw("text-xs font-bold w-1/2 text-start"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Invoice Date:</Text>
                            <Text style={tw("text-xs text-start -ml-8")}>{data?.createdAt ? moment(data.createdAt).format("DD-MM-YYYY") + "    " : ""}</Text>
                        </View>


                    </View>

                    <View style={tw("flex flex-row justify-start w-full gap-x-2   border-b-2 border-gray-500 p-2")} >
                        <View style={tw("flex flex-row w-1/2 gap-x-2 w-1/2")}>
                            <Text style={[tw("text-xs font-bold ml-12 w-1/2 text-start"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Reg.No:</Text>
                            <Text style={tw("text-xs text-start -ml-8")}>{data?.patientType == "INPATIENT" ? data?.PatientAdmit?.Patient?.regNo : data?.PatientVisit?.Patient?.regNo}</Text>
                        </View>
                        <View style={tw("flex flex-row gap-x-2 w-1/2 ml-1")}>
                            <Text style={[tw("text-xs font-bold w-1/2 text-start"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Doctor:</Text>
                            <Text style={tw("text-xs text-start -ml-8")}>{data?.patientType == "INPATIENT" ? data?.PatientAdmit?.PatientVisit?.Employee?.name : data?.PatientVisit?.Employee?.name}</Text>
                        </View>


                    </View>






                    <View style={tw("flex flex-row justify-start  gap-x-2 w-full   border-b-2 border-gray-500 p-2")} >
                        <View style={tw("flex flex-col justify-start w-3/4  ml-2 mt-1")} >

                            <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                                <Text style={[tw("text-xs font-bold mt-1 w-2/4"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Patient Name        :</Text>
                                <Text style={tw("text-xs mt-1")}>{data?.patientType == "INPATIENT" ? data?.PatientAdmit?.Patient?.name : data?.PatientVisit?.Patient?.name} </Text>
                            </View>
                            <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                                <Text style={[tw("text-xs font-bold mt-1 w-2/4"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Patient Contact    :</Text>
                                <Text style={tw("text-xs mt-1")}>{data?.patientType == "INPATIENT" ? data?.PatientAdmit?.Patient?.mobile : data?.PatientVisit?.Patient?.mobile}</Text>
                            </View>
                            <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                                <Text style={[tw("text-xs font-bold mt-1 w-2/4"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Patient Age / Sex  :</Text>
                                <Text style={tw("text-xs mt-1")}>{data?.patientType == "INPATIENT" ? data?.PatientAdmit?.Patient?.age : data?.PatientVisit?.Patient?.age} / {data?.patientType == "INPATIENT" ? data?.PatientAdmit?.Patient?.gender?.charAt(0) : data?.PatientVisit?.Patient?.gender?.charAt(0)}</Text>
                            </View>
                            <View style={tw("flex flex-row gap-x-2 w-1/2 mb-2")}>
                                <Text style={[tw("text-xs font-bold mt-1 w-2/4"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Patient Address    :</Text>
                                <Text style={tw("text-xs mt-1")}>{data?.patientType == "INPATIENT" ? data?.PatientAdmit?.Patient?.address : data?.PatientVisit?.Patient?.address}</Text>
                            </View>
                        </View>

                        <View style={tw("flex flex-col justify-start w-1/4  ml-2 mt-1")} >
                            <View style={tw("flex flex-row justify-center items-center")}>
                                <Image src={barCodeGen} style={tw(`w-40 h-16`)} />
                            </View>
                        </View>

                    </View>
                </View>


            </View>
        </>
    )
}

export default Grid
