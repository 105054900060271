// patientAdmitApi.js
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";
import { PATIENT_ADMIT_API } from "../../Api";

const patientAdmitApi = createApi({
  reducerPath: "patientAdmit",
  baseQuery,
  tagTypes: ["PatientAdmit", "Room"], // Make sure both tags are included in tagTypes
  endpoints: (builder) => ({
    getPatientAdmit: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: PATIENT_ADMIT_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params,
          };
        }
        return {
          url: PATIENT_ADMIT_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params,
        };
      },
      providesTags: ["PatientAdmit"],
    }),
    getPatientAdmitById: builder.query({
      query: (id) => ({
        url: `${PATIENT_ADMIT_API}/${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      providesTags: ["PatientAdmit"],
    }),
    addPatientAdmit: builder.mutation({
      query: (payload) => ({
        url: PATIENT_ADMIT_API,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["PatientAdmit", "Room"], // Invalidate both tags to trigger refetch
    }),
    updatePatientAdmit: builder.mutation({
      query: (payload) => {
        const { id, body } = payload;
        return {
          url: `${PATIENT_ADMIT_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["PatientAdmit", "Room"], // Invalidate both tags to trigger refetch
    }),
    deletePatientAdmit: builder.mutation({
      query: (id) => ({
        url: `${PATIENT_ADMIT_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PatientAdmit", "Room"], // Invalidate both tags to trigger refetch
    }),
  }),
});

export const {
  useGetPatientAdmitQuery,
  useGetPatientAdmitByIdQuery,
  useAddPatientAdmitMutation,
  useUpdatePatientAdmitMutation,
  useDeletePatientAdmitMutation,
} = patientAdmitApi;

export default patientAdmitApi;
