import React from 'react'
import { View, Text } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import pixelFinder from '../../../Utils/pixelFinder';
import { getMonthYearFromDateTimeToDisplay, priceWithTax } from '../../../Utils/helper';

const Grid = ({ billItems, styles, isTaxBill, findDiscount }) => {
    const bottomRowsCount = pixelFinder(billItems.length);
    let count = 1;
    return (
        <>
            <View style={{ ...styles.table, ...tw("border-t border-black") }} fixed>
                <View style={{ ...styles.tableRow, ...tw("font-[Times-Bold] border-b border-black bg-orange-200 w-full") }} >
                    <View style={{ ...styles.tableCol, ...tw("border-l border-black flex flex-row justify-center items-center w-1/12") }}><Text style={styles.tableCell}>S.NO</Text></View>
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-center items-center w-5/12") }}><Text style={styles.tableCell}>Description</Text></View>
                    {isTaxBill &&
                        <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-center items-center w-1/12") }}><Text style={styles.tableCell}>HSN</Text></View>
                    }
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-center items-center w-1/12") }}><Text style={styles.tableCell}>Batch</Text></View>
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-center items-center w-1/12") }}><Text style={styles.tableCell}>Exp.dt</Text></View>
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-center items-center w-1/12") }}><Text style={styles.tableCell}>MRP</Text></View>
                    {isTaxBill &&
                        <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-center items-center w-1/12") }}><Text style={styles.tableCell}>Tax.%</Text></View>
                    }
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-center items-center w-1/12") }}><Text style={styles.tableCell}>Price</Text></View>
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-center items-center w-1/12") }}><Text style={styles.tableCell}>Qty</Text></View>
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-center items-center w-1/12") }}><Text style={styles.tableCell}>Disc.%</Text></View>
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-center items-center w-1/12") }}><Text style={styles.tableCell}>Disc.Amt</Text></View>

                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-center items-center w-1/12") }}><Text style={styles.tableCell}>AMT</Text></View>
                </View>
            </View>
            {billItems.map((item) =>
                <View key={item.id} style={{
                    ...styles.tableRow, ...tw(` ${(((count) % 26 === 0)) && 'border-b border-black'}  
                `)
                }} wrap={false}>
                    <View style={{ ...styles.tableCol, ...tw("border-l border-black flex flex-row justify-center items-center w-1/12") }}><Text style={styles.tableCell}>{count++}</Text></View>
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row items-center w-5/12") }}><Text style={styles.tableCell}>{item.productName}</Text></View>
                    {/* {isTaxBill &&
                        <View style={{ ...styles.tableCol, ...tw("flex flex-row items-center w-1/12") }}><Text style={styles.tableCell}>{item.hsnCode}</Text></View>
                    } */}
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-center items-center w-1/12") }}><Text style={styles.tableCell}>{item?.batchNo}</Text></View>
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-center items-center w-1/12") }}><Text style={styles.tableCell}>{getMonthYearFromDateTimeToDisplay(item?.expiryDate)}</Text></View>
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-end items-center w-1/12") }}><Text style={styles.tableCell}>{item?.salePrice ? parseFloat(item?.salePrice).toFixed(2) : ""}</Text></View>
                    {/* {isTaxBill &&
                        <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-end items-center w-1/12") }}><Text style={styles.tableCell}>{item?.tax}%</Text></View>
                    } */}
                    {/* <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-end items-center w-1/12") }}><Text style={styles.tableCell}>{parseFloat(item?.price).toFixed(2)}</Text></View> */}
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-end items-center w-1/12") }}><Text style={styles.tableCell}>{parseFloat(item?.salePrice).toFixed(2)}</Text></View>
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-end items-center w-1/12") }}><Text style={styles.tableCell}>{item?.qty}</Text></View>
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-end items-center w-1/12") }}><Text style={styles.tableCell}>{item?.discount}%</Text></View>
                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-end items-center w-1/12") }}><Text style={styles.tableCell}>{item?.discountInAmount}</Text></View>

                    <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-end items-center w-1/12") }}><Text style={styles.tableCell}> {parseFloat((parseFloat(item?.pcsQty || 0) * parseFloat(item?.salePrice || 0)) - findDiscount(item?.pcsQty, item?.salePrice, item?.discount)).toFixed(2)}</Text></View>


                    {/* <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-end items-center w-1/12") }}><Text style={styles.tableCell}>   {priceWithTax((item?.qty || 0) * item?.salePrice, item.tax || 0).toFixed(2)}</Text></View> */}
                    {/* <View style={{ ...styles.tableCol, ...tw("flex flex-row justify-end items-center w-1/12") }}><Text style={styles.tableCell}> {parseFloat(item?.amount).toFixed(2)}</Text></View> */}


                </View>
            )}

        </>
    )
}

export default Grid
