
import { useState, useEffect } from "react";
import upiqrcode from "upiqr";

export default function UPIQrCode({ amount = 0 }) {
    const [qrCode, setQrCode] = useState("");
    useEffect(() => {
        upiqrcode({
            payeeVPA: "mukeshkumar87086-1@oksbi",
            payeeName: "Mukeshkumar",
            amount,
            minimumAmount: amount,
        })
            .then((upi) => {
                setQrCode(upi.qr);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <img
            src={qrCode}
            alt="QR Code"
            width={300}
            height={300}
        />
    )
}
