import React from 'react'
import { DropdownInput, MultiSelectDropdown } from '../Inputs';
import { dropDownListObject, multiSelectOption } from '../Utils/contructObject';
import { getCommonParams } from '../Utils/helper';
import { useGetBranchQuery } from '../redux/services/BranchMasterService';

const LocationDropdown = ({ multiSelect = true, withoutLabel = true, readOnly, name, selected, setSelected }) => {
    const { token, ...params } = getCommonParams();
    const { data: branchList } = useGetBranchQuery({ params: { ...params } });

    return (
        <>
            {withoutLabel ?
                <select value={selected} onChange={(e) => setSelected(e.target.value)} className='w-full table-data-input'>
                    <option value="">Select</option>
                    {(branchList ? branchList.data : []).map(item =>
                        <option key={item.id} value={item.id}>{item.name}</option>
                    )}
                </select>
                :
                <>
                    {multiSelect ?
                        <MultiSelectDropdown readOnly={readOnly} name={name} selected={selected} setSelected={setSelected}
                            options={multiSelectOption(branchList ? branchList.data : [], "branchName", "id")} />
                        :
                        <DropdownInput readOnly={readOnly} name={name} value={selected} setValue={setSelected}
                            options={dropDownListObject(branchList ? branchList.data : [], "branchName", "id")} clear />
                    }
                </>
            }
        </>
    )
}

export default LocationDropdown
