import React, { useEffect } from "react";
import { calculateAge } from "../../../Utils/helper";
import { WEB_LINK } from "../../../icons";
import secureLocalStorage from "react-secure-storage";
import moment from "moment/moment";
import io from "socket.io-client";
import DoctorDashboardCard from "./DashboardCards";
// import DoctorSidebar from "./DoctorSideBar";
// import ResponsiveCharts from "./ResponsiveCharts";
// import ResponsiveCharts1 from "./ResponsiveCharts1";
// import ResponsiveCharts3 from "./ResponsiveCharts3";
// import MonthlyReportBarChart from "./ResponsiveCharts4";
// import DashboardCalendar from "./DashboardFullCalendar";
// import PharmacyPurchaseSalesReport from "./lineChart";
import { useGetPurchaseBillQuery } from "../../../redux/PharmacyServices/PurchaseBillService";
import {
  getCommonParams,
  getDateFromDateTime,
  isGridDatasValid,
} from "../../../Utils/helper";
import { TablePagination } from '@mui/material';

import {
  FaUserNurse,
  FaHospitalSymbol,
  FaFlask,
  FaUserTie,
} from "react-icons/fa";
import { useGetSalesBillQuery } from "../../../redux/PharmacyServices/SalesBillService";
import Nurse from "../../../assets/nurse.png";
import Pharmacist from "../../../assets/pharmacist.png";
import LabStaff from "../../../assets/observation.png";
import Helper from "../../../assets/helper.png";
import { DateInput } from "../../../Inputs";

const BASE_URL = process.env.REACT_APP_SERVER_URL;
const socket = io.connect(BASE_URL);


const DoctorDashBoard = ({
  patientVisits,
  dateFilter,
  setDateFilter,
  refetchPatientVisit,
  setId,
  currentPageNumber,
  setConsulted,
  consulted,
  setCurrentPageNumber,
  dataPerPage,
  setDataPerPage,
  totalCount
}) => {
  const patientHeaders = [
    "Token",
    "PatientId",
    "Name",
    "Gender",
    "Age",
    "Consultation",
  ];
  const { branchId, finYearId } = getCommonParams();
  const employeeId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "employeeId"
  );
  const {
    data: purData,
    isLoading,
    isFetching,
  } = useGetPurchaseBillQuery({ params: { Dash: true } });
  const handleChange = (event, value) => {
    setCurrentPageNumber(value);
  };
  const handleChangeRowsPerPage = (event) => {
    setDataPerPage(parseInt(event.target.value, 10));
    setCurrentPageNumber(0);
  };
  const {
    data: saleData,
    isSaleLoading,
    isSaleFetching,
  } = useGetSalesBillQuery({ params: { Dash: true } });
  const frozenRows = 11;

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected");
    });

    socket.on("disconnect", () => {
      console.log("disconnected");
    });

    socket.on(`newPatient/${parseInt(employeeId)}`, () => {
      refetchPatientVisit();
    });
    console.group(patientVisits, "patient");

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off(`newPatient/${parseInt(employeeId)}`);
    };
  }, []);
  const DashboardCard = ({ title, src, count }) => (
    <div className="bg-white shadow-lg rounded-lg p-3 flex flex-col justify-center items-center hover:shadow-2xl transition-shadow duration-300 mb-4">
      <img
        src={src}
        alt={title}
        className="w-16 h-16 object-cover rounded-full mb-4"
      />
      <div className="text-3xl font-bold text-sky-800">{count}</div>
      <div className="text-lg font-medium mt-2">{title}</div>
    </div>
  );

  return (
    <div className="flex h-screen ">
      <div className="flex-1 flex flex-col">
        <main className="flex flex-col  pt-6 bg-gray-200 ">
          <DoctorDashboardCard />
          {/* <div className="mt-5 px-3 grid grid-cols-4 gap-4 bg-white">
  <button className="bg-gray-700  text-white w-full py-2 rounded hover:bg-gray-700">
   Purchase   Sales Report
  </button>
  <button className="bg-gray-700 text-white w-full py-2 rounded hover:bg-gray-700">
    Visitor Detail
  </button>
  <button className="bg-gray-700 text-white w-full py-2 rounded hover:bg-gray-700">
    Monthly Visitor report
  </button>
  <button className="bg-gray-700 text-white w-full py-2 rounded hover:bg-gray-700">
   Program Calender
  </button>
</div>
          <div className="my-5 px-3 grid grid-cols-4 gap-4 bg-white">
  <div className="col-span-1">
    <div className="bg-white">
    <PharmacyPurchaseSalesReport />
    </div>
  </div>
  <div className="col-span-1">
    <div className="bg-white">
      <ResponsiveCharts1 />
    </div>
  </div>
  <div className="col-span-1">
    <div className="bg-white">
      <MonthlyReportBarChart />
    </div>
    
  </div>
  <div className="col-span-1">
    <div className="bg-white">
      <DashboardCalendar />
    </div>
  </div>
          </div>
           */}

          <div className="mt-3 px-4">
            <div className="flex items-center bg-gray-700 justify-between p-1 mb-4 shadow">
              <h2 className="text-lg text-white">Patients List</h2>
              <div className="flex items-center gap-6">
                <div className="flex gap-6">
                  <label className="flex items-center text-white cursor-pointer">
                    <input
                      type="radio"
                      checked={typeof consulted === "undefined"}
                      onChange={() => setConsulted(undefined)}
                      className="mr-2 cursor-pointer accent-blue-500"
                    />
                    All
                  </label>
                  <label className="flex items-center text-white cursor-pointer">
                    <input
                      type="radio"
                      checked={consulted === true}
                      onChange={() => setConsulted(true)}
                      className="mr-2 cursor-pointer accent-blue-500"
                    />
                    Consulted
                  </label>
                  <label className="flex items-center text-white cursor-pointer">
                    <input
                      type="radio"
                      checked={consulted === false}
                      onChange={() => setConsulted(false)}
                      className="mr-2 cursor-pointer accent-blue-500"
                    />
                    Pending
                  </label>
                </div>
                {console.log(dateFilter, 'dateFilter')
                }
                <DateInput hideLabel value={moment.utc(dateFilter).format("YYYY-MM-DD")} setValue={(value) => { setDateFilter(value) }} />
              </div>
            </div>

            <div className="overflow-x-auto shadow-lg">
              <div className="w-full h-full">
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                  <div className="lg:col-span-3">
                    <table className="w-full text-sm table-auto text-left border-collapse border border-gray-200">
                      <thead>
                        <tr className="bg-gray-200 uppercase text-gray-700">
                          {patientHeaders.map((header, index) => (
                            <th
                              key={index}
                              className="border border-gray-300 p-2 text-sm font-semibold"
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {patientVisits.map((patient, index) => (
                          <tr
                            key={index}
                            className="border-b hover:bg-gray-200 transition-colors duration-200 text-xs cursor-pointer even:bg-gray-100"
                            onClick={() => setId(patient.id)}
                          >
                            <td className="p-2 border border-gray-300">
                              {patient?.token}
                            </td>
                            <td className="p-2 border border-gray-300">
                              {patient?.Patient?.regNo}
                            </td>
                            <td className="p-2 border border-gray-300">
                              {patient?.Patient?.name}
                            </td>
                            <td className="p-2 border border-gray-300">
                              {patient?.Patient?.gender}
                            </td>
                            <td className="p-2 border border-gray-300">
                              {calculateAge(new Date(patient?.Patient?.dob))}
                            </td>
                            <td className="p-2 border border-gray-300 text-blue-600 underline">
                              {WEB_LINK}
                            </td>
                          </tr>
                        ))}
                        {Array.from({ length: frozenRows - patientVisits.length }).map((_, i) => (
                          <tr
                            key={i}
                            className={`bg-white border border-gray-300 md:border-none block md:table-row h-[32px]`}
                          >
                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                            <td className="md:border md:border-gray-300 text-right block md:table-cell p-1"></td>
                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>
                            <td className="md:border md:border-gray-300 text-left block md:table-cell p-1"></td>

                          </tr>
                        ))}

                      </tbody>
                    </table>
                    <div className='flex items-center justify-end'>
                      <TablePagination
                        component="div"
                        count={totalCount}
                        page={currentPageNumber}
                        onPageChange={handleChange}
                        rowsPerPage={dataPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>

                  </div>
                  <div className="lg:col-span-1">
                    {/* <div className="flex justify-center">
                    <h3>TODAY PRASENTS</h3>

                    </div> */}
                    <div className="grid grid-cols-2 gap-6">
                      <div>
                        <DashboardCard src={Nurse} title="Nurse" count={3} />
                      </div>
                      <div>
                        <DashboardCard
                          src={Pharmacist}
                          title="Pharmacist"
                          count={2}
                        />
                      </div>
                      <div>
                        <DashboardCard
                          src={LabStaff}
                          title="Lab Technician"
                          count={1}
                        />
                      </div>
                      <div>
                        <DashboardCard src={Helper} title="Helper" count={3} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default DoctorDashBoard;
