import React, { useCallback, useEffect } from 'react';
import { DELETE } from '../../../icons';
import { useGetProductQuery } from '../../../redux/PharmacyServices/ProductMasterService';
import { useGetProductCategoryQuery } from '../../../redux/PharmacyServices/ProductCategoryServices';
import { useGetProductBrandQuery } from '../../../redux/PharmacyServices/ProductBrandService';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';

import { findFromList, priceWithTax, sumArray } from '../../../Utils/helper';
import SalesPrice from './SalesPrice';
import { DropdownWithSearch } from '../../../Inputs';


const PoBillItems = ({ id, readOnly, setPoBillItems, poBillItems, date, storeId }) => {
    const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") }
    const { data: productBrandList } =
        useGetProductBrandQuery({ params });

    const { data: productCategoryList } =
        useGetProductCategoryQuery({ params });

    const { data: productList } = useGetProductQuery({ params });

    function handleInputChange(value, index, field, stockQty) {
        setPoBillItems(poBillItems => {
            const newBlend = structuredClone(poBillItems);
            newBlend[index][field] = value;
            if (field === "productId") {
                newBlend[index]["tax"] = findFromList(value, (productList?.data || []), "Hsn")?.gst
            }
            if (field === "qty") {
                if (parseFloat(stockQty) < parseFloat(value)) {
                    toast.info("Sales Qty Can not be more than Stock Qty", { position: 'top-center' })
                    return poBillItems
                }
            }
            return newBlend
        }
        );
    };
    useEffect(() => {
        if (poBillItems.length >= 5) return
        setPoBillItems(prev => {
            let newArray = Array.from({ length: 5 - prev.length }, () => {
                return { productCategoryId: "", productBrandId: "", productId: "", stockQty: "0", qty: "0", price: "0.00", amount: "0.000", pcsQty: "0" }
            })
            return [...prev, ...newArray]
        }
        )
    }, [setPoBillItems, poBillItems])


    function getTotal(field1, field2) {
        const total = poBillItems.reduce((accumulator, current) => {

            return accumulator + parseFloat(current[field1] && current[field2] ? current[field1] * current[field2] : 0)
        }, 0)
        return parseFloat(total)
    }

    const getProductUomPriceDetails = useCallback((productId) => {
        const items = findFromList(productId, productList?.data ? productList?.data : [], "ProductUomPriceDetails")
        return items ? items : []
    }, [productList])


    function deleteRow(index) {
        setPoBillItems(prev => prev.filter((_, i) => i !== index))
    }

    function addNewRow() {
        setPoBillItems(prev => [
            ...prev,
            { productCategoryId: "", productBrandId: "", productId: "", stockQty: "0", qty: "0", price: "0.00", amount: "0.000" }
        ]);
    }




    if (!productBrandList || !productCategoryList || !productList) return <Loader />
    return (
        <>


            <div className={` relative w-full overflow-y-auto py-1`}>
                <table className=" border border-gray-500 text-sm table-auto  w-full">
                    <thead className='bg-blue-300 top-0 border-b border-gray-500'>
                        <tr className=''>
                            <th className="table-data  w-2 text-center p-0.5">S.no</th>
                            <th className="table-data ">Brand</th>
                            {/* <th className="table-data ">Product Category<span className="text-red-500 p-0.5">*</span></th> */}
                            <th className="table-data ">Product</th>
                            <th className="table-data ">Category</th>
                            <th className="table-data ">Hsn</th>
                            <th className="table-data ">
                                <div className="p-0.5 col-span-2">
                                    Sales Price
                                </div>
                                <div className="p-0.5">
                                    Price / Bin / Rack / Bat.no / Exp.Date / Supplier / Stk.Qty
                                </div>
                            </th>
                            <th className="table-data  w-20">Sales Price</th>
                            <th className="table-data  w-20">Stock.Qty</th>
                            <th className="table-data  w-20">Qty</th>
                            <th className="table-data  w-16 p-0.5">Taxable Amount</th>
                            <th className="table-data  w-16 p-0.5">Tax</th>
                            <th className="table-data  w-16 p-0.5">Total. Amount</th>
                            {!readOnly &&
                                <th className="table-data  w-16 p-0.5" >  <button className='text-2xl' onClick={addNewRow}>+</button></th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto h-full w-full'>
                        {(poBillItems ? poBillItems : []).map((item, index) =>
                            <tr key={index} className="w-full table-row">
                                <td className="table-data w-2 text-left px-1 py-1">
                                    {index + 1}
                                </td>
                                <td className='table-data'>
                                    <DropdownWithSearch value={item.productBrandId}
                                        readOnly={readOnly}
                                        setValue={(value) => handleInputChange(value, index, "productBrandId")}
                                        options={productBrandList?.data ? (
                                            (id ? productBrandList?.data : productBrandList?.data.filter(i => i?.active))
                                        ) : []} />
                                </td>
                                {/* <td className='table-data'>
                                    <DropdownWithSearch value={item.productCategoryId}
                                        readOnly={readOnly}
                                        setValue={(value) => handleInputChange(value, index, "productCategoryId")} options={(productCategoryList?.data ? (id ? productCategoryList?.data : productCategoryList?.data.filter(i => i?.active)) : [])} />
                                </td> */}
                                <td className='table-data'>
                                    <DropdownWithSearch key={item.productId} value={item.productId}
                                        readOnly={readOnly}
                                        setValue={(value) => handleInputChange(value, index, "productId")}
                                        options={(productList?.data || []).filter(value => item?.productBrandId ? (parseInt(value.productBrandId) === parseInt(item.productBrandId)) : true).filter(item => item?.active)} />
                                </td>
                                <td className='table-data'>
                                    {findFromList(item?.productId, (productList?.data || []), "drugSalesType")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(item?.productId, (productList?.data || []), "Hsn")?.hsnCode}
                                </td>
                                <SalesPrice
                                    storeId={storeId}
                                    getProductUomPriceDetails={getProductUomPriceDetails}
                                    id={id}
                                    date={date}
                                    item={item}
                                    readOnly={readOnly}
                                    productId={item.productId}
                                    index={index}
                                    setPoBillItems={setPoBillItems}
                                    poBillItems={poBillItems}
                                    uomId={item.uomId}
                                    qty={item.qty}
                                />
                                {!readOnly &&
                                    <td className="border border-gray-500 text-xs text-center">
                                        <button
                                            type='button'
                                            onClick={() => {
                                                deleteRow(index)
                                            }}
                                            className='text-xs text-red-600 '>{DELETE}
                                        </button>
                                    </td>
                                }
                            </tr>
                        )}
                        <tr className='bg-blue-300 w-full border border-gray-400 h-7 font-bold'>
                            <td className="table-data text-center w-10 font-bold" colSpan={8}>Total</td>
                            <td className="table-data text-center w-10 font-bold">{sumArray(poBillItems, "pcsQty").toFixed(2)}</td>
                            <td className="table-data  w-10 text-right pr-1">{getTotal("pcsQty", "salePrice").toFixed(2)}</td>
                            <td className="table-data text-center w-10 font-bold"></td>
                            <td className="table-data text-center w-10 font-bold">{poBillItems.reduce((a, item, index) => a + parseFloat(
                                priceWithTax((parseFloat((item?.pcsQty) || 0) * parseFloat(poBillItems[index]?.salePrice) || 0), item?.tax || 0)
                            ), 0).toFixed(2)}</td>
                            {!readOnly &&
                                <td className="border border-gray-500 text-xs text-center">
                                </td>
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default PoBillItems