import React, { useEffect } from 'react'
import { useGetStockQuery } from '../../../redux/PharmacyServices/StockService'
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import { useState } from 'react';
import { TablePagination } from '@mui/material';
import { RadioButton } from '../../../Inputs';

const PharmaDashboardDetails = ({ isExpiredOrOutOfStock, outOfStockReport }) => {
    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);


    const { data: expiredData } = useGetStockQuery({ params: { isGetStockDashboardData: true, pagination: true, dataPerPage, pageNumber: currentPageNumber + 1, isExpiredOrOutOfStock, outOfStockReport } })

    useEffect(() => {
        if (!expiredData?.totalCount) return
        setTotalCount(expiredData?.totalCount);
    }, [expiredData]);

    const handleChange = (event, value) => {
        setCurrentPageNumber(value);
    };
    const handleChangeRowsPerPage = (event) => {
        setDataPerPage(parseInt(event.target.value, 10));
        setCurrentPageNumber(0);
    };
    const frozenRows = 9;

    const stockItems = expiredData?.data?.stockItems || [];
    return (
        <section className="bg-gray-100  ">



            <section className="bg-gray-100  ">

                <div className="text-center mx-auto max-w-screen-2xl">
                    <div className="relative bg-white shadow-md ">
                        <div className="overflow-x-auto overflow-auto  mt-3">
                            {stockItems.length > 0 ? <table className="w-full text-xs text-left border border-White border-collapse">
                                <thead className="text-xs uppercase text-white bg-gray-700 dark:bg-gray-700 dark:text-gray-400">
                                    <tr className={`sticky top-0  font-thin tracking-wider `}>
                                        <th scope="col" className="p-2 text-center  border border-White font-semibold">S.No.</th>
                                        <th scope="col" className="p-2 text-center  border border-White font-semibold">Name</th>

                                        <th scope="col" className="p-2 text-center  border border-White font-semibold">Stock Qty</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {stockItems?.map((stockItem, index) => (
                                        <tr
                                            key={index}
                                            className={`border-b border-White dark:border-gray-600 hover:bg-gray-100  dark:hover:bg-gray-700 
                                                 bg-gray-1 00
                                }`}
                                        >
                                            <td className="p-2 text-center border border-white">{index + 1}</td>
                                            <td className="p-2 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white border border-White">
                                                {stockItem?.productName}
                                            </td>

                                            <td className="p-2 text-right font-medium text-gray-900 whitespace-nowrap dark:text-white border border-White">
                                                {stockItem?.stockQty}
                                            </td>

                                        </tr>
                                    ))}

                                    {(
                                        Array.from({ length: frozenRows - stockItems.length }).map((_, i) => (
                                            <tr
                                                key={i}
                                                className={`bg-white border border-gray-300 md:border-none block md:table-row h-[32px]`}
                                            >
                                                <td className="md:border md:border-white text-left block md:table-cell p-1 bg-gray-100"></td>
                                                <td className="md:border md:border-white bg-gray-100 text-right block md:table-cell p-1"></td>
                                                <td className="md:border md:border-white text-left block md:table-cell p-1 bg-gray-100"></td>



                                            </tr>
                                        ))
                                    )}

                                </tbody>
                            </table> : <div className='font-bold flex items-center justify-center w-full text-xl h-[50%]'> Select Filter Options to Get Data!!! </div>
                            }

                        </div>
                        <div className='flex items-center justify-end'>
                            <TablePagination
                                component="div"
                                count={totalCount}
                                page={currentPageNumber}
                                onPageChange={handleChange}
                                rowsPerPage={dataPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </div>
            </section>

        </section>
    )
}

export default PharmaDashboardDetails
