import React, { useEffect } from 'react'
import { useGetStockQuery } from '../../../redux/PharmacyServices/StockService'
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import { useState } from 'react';
import { TablePagination } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';

const PharmaDashboardDetails = ({ storeId, locationId }) => {
    const [dataPerPage, setDataPerPage] = useState("15");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [searchProduct, setSearchProduct] = useState('');

    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    );
    const searchFields = { searchProduct, }

    const { data: expiredData } = useGetStockQuery({ params: { isGetStockExpiryData: true, pagination: true, dataPerPage, pageNumber: currentPageNumber + 1, isExpiredOrOutOfStock: true, storeId, branchId, ...searchFields } })

    useEffect(() => {
        if (expiredData?.totalCount) {
            setTotalCount(expiredData?.totalCount)
        }
    }, [expiredData])

    const handleChange = (event, value) => {
        setCurrentPageNumber(value);
    };
    const handleChangeRowsPerPage = (event) => {
        setDataPerPage(parseInt(event.target.value, 10));
        setCurrentPageNumber(0);
    };

    let stockItems = ''
    stockItems = expiredData?.data?.stockItems || [];
    stockItems = stockItems?.filter(item => item.stockQty > 0)
    return (
        <section className="bg-gray-100 py-4 ">

            <div className="text-center mx-auto max-w-screen-2xl">
                <div className="relative bg-white shadow-md ">
                    <div className="overflow-x-auto overflow-auto  mt-3">
                        <table className="w-full text-xs text-left border border-White border-collapse">
                            <thead className="text-xs uppercase text-white bg-gray-700 dark:bg-gray-700 dark:text-gray-400">
                                <tr className={`sticky top-0  font-thin tracking-wider `}>
                                    <th scope="col" className="p-2 text-center  border border-White font-semibold">S.No.</th>
                                    <th scope="col" className="p-2 text-center  border border-White font-semibold">Name<input
                                        type="text"
                                        className="text-black h-6 focus:outline-none border md:ml-3 border-white rounded-lg"
                                        placeholder="Search"
                                        value={searchProduct}
                                        onChange={(e) => {
                                            setSearchProduct(e.target.value);
                                        }}
                                    /></th>
                                    <th scope="col" className="p-2 text-center  border border-White font-semibold">Supplier</th>
                                    <th scope="col" className="p-2 text-center  border border-White w-24 font-semibold">Batch No</th>
                                    <th scope="col" className="p-2 text-center  border border-White font-semibold">Expiry Date</th>
                                    <th scope="col" className="p-2 text-center  border border-White w-48 font-semibold">Expired Days</th>
                                    <th scope="col" className="p-2 text-center  border border-White font-semibold">Stock Qty</th>

                                </tr>
                            </thead>
                            <tbody>
                                {stockItems?.map((stockItem, index) => (
                                    <tr
                                        key={index}
                                        className={`border-b border-White dark:border-gray-600 hover:bg-gray-100  dark:hover:bg-gray-700  bg-slate-200
                                            }`}
                                    >
                                        <td className='py-1'> {(index + 1) + (dataPerPage * (currentPageNumber))}</td>
                                        <td className="p-2 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white border border-White">
                                            {stockItem?.name}
                                        </td>
                                        <td className="p-2 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white border border-White">
                                            {stockItem?.supplierName}
                                        </td>
                                        <td className="p-2 text-center border border-White">
                                            {stockItem?.batchNo && (
                                                <span className="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded">{stockItem?.batchNo}</span>
                                            )}
                                        </td>
                                        <td className="p-2 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white border border-White">
                                            <div className="flex items-center">
                                                {stockItem?.expiryDate && (
                                                    <>
                                                        <div className={`inline-block w-4 h-4 mr-2 ${stockItem.expireInDays > 0 ? 'bg-emerald-600' : 'bg-red-700'} rounded-full`}></div>
                                                        {getDateFromDateTimeToDisplay(stockItem.expiryDate)}
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                        <td className="p-2 text-right font-medium text-gray-900 whitespace-nowrap dark:text-white border border-White">
                                            {stockItem?.expireInDays}
                                        </td>
                                        <td className="p-2 text-right font-medium text-gray-900 whitespace-nowrap dark:text-white border border-White">
                                            {stockItem?.stockQty}
                                        </td>

                                    </tr>
                                ))}



                            </tbody>
                        </table>

                    </div>
                    <div className='flex items-center justify-end'>
                        <TablePagination
                            component="div"
                            count={totalCount}
                            page={currentPageNumber}
                            onPageChange={handleChange}
                            rowsPerPage={dataPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PharmaDashboardDetails
