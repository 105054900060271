import React, { useState } from "react";
import moment from "moment/moment";
import { EMPTY_ICON } from '../../../icons';
import { Loader } from "../../components"
import { DateInput } from "../../../Inputs";


const ACTIVE = (
  <button className="rounded bg-green-500 border p-1 disabled">ACTIVE</button>
);
const INACTIVE = (
  <button className="rounded bg-red-500 border p-1 disabled">INACTIVE</button>
);
const EXPIRED = (
  <button className="rounded bg-gray-500 border p-1 disabled">EXPIRED</button>
);
const ACTIVE_PLAN = (
  <button className="rounded bg-blue-600 border p-1 disabled">ACTIVE</button>
);

const MOMENT = moment;
export default function FormReport({
  tableHeaders,
  tableDataNames,
  setId,
  searchDate,
  setSearchDate,
  searchValue,
  setSearchValue,
  data,
  loading,
  searchPatientName,
  setSearchPatientName,
}) {

  return (
    <>
      {searchDate ?
        <div className="flex flex-col md:justify-items-center">
          <div className="md:text-center md:gap-8">
            <div className="grid grid-cols-2 gap-0 w-full m-2">
              <div className="relative w-full ">
                <input
                  type="text"
                  className="text-sm bg-gray-100 focus:outline-none border w-full pl-10 p-2 pr-4"
                  id="id"
                  placeholder="Search Patient Name"
                  value={searchPatientName}
                  onChange={(e) => {
                    setSearchPatientName(e.target.value);
                  }}
                />
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.9 14.32a8 8 0 111.41-1.41l4.35 4.34a1 1 0 01-1.42 1.42l-4.34-4.35zm-7.4-7.4a6 6 0 100 12 6 6 0 000-12z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>


              <div className="relative w-full">
                <DateInput hideLabel value={searchDate} setValue={setSearchDate} />
              </div>
            </div>

          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              {data.length === 0 ? (
                <div className="flex-1 flex justify-center text-blue-900 items-center text-xl mt-5">
                  <p>{EMPTY_ICON} No Data Found...! </p>
                </div>
              ) : (
                <div
                  className="md:grid md:justify-items-stretch overflow-auto"
                >
                  <table className="table-auto text-center">
                    <thead className="border-2 table-header">
                      <tr className="p-1">
                        {tableHeaders.map((head, index) => (
                          <th
                            key={index}
                            className="border-2 sticky top-0 p-1 stick-bg"
                          >
                            {head}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="border-2">
                      {data.map((dataObj, index) => (
                        <tr key={index} className="border-2 table-row" onClick={() => setId(dataObj.id)}>
                          {tableDataNames.map((data, index) => (
                            <td key={index} className="table-data p-1">
                              {eval(data)}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div> :
        <div className="flex flex-col md:justify-items-center">
          <div className="md:text-center md:gap-8">
            <input
              type="text"
              className="text-sm bg-gray-100 focus:outline-none border w-full"
              id="id"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />

          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              {data?.length === 0 ? (
                <div className="flex-1 flex justify-center text-blue-900 items-center text-xl mt-5">
                  <p>{EMPTY_ICON} No Data Found...! </p>
                </div>
              ) : (
                <div
                  className="md:grid md:justify-items-stretch overflow-auto"
                >
                  <table className="table-auto text-center">
                    <thead className="border-2 table-header">
                      <tr>
                        {tableHeaders.map((head, index) => (
                          <th
                            key={index}
                            className="border-2 sticky top-0 stick-bg"
                          >
                            {head}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="border-2">
                      {data?.map((dataObj, index) => (
                        <tr key={index} className="border-2 table-row" onClick={() => setId(dataObj.id)}>
                          {tableDataNames.map((data, index) => (
                            <td key={index} className="table-data">
                              {eval(data)}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>

      }
    </>

  );
}
