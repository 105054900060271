import React from 'react'
import { DropdownInput, MultiSelectDropdown } from '../Inputs';
import { dropDownListObject, multiSelectOption } from '../Utils/contructObject';
import { getCommonParams } from '../Utils/helper';
import { useGetHsnCodeQuery } from '../redux/PharmacyServices/HsnServices';

const HsnDropdown = ({ multiSelect = true, withoutLabel = true, readOnly, name, selected, setSelected, rackId }) => {
    const { token, ...params } = getCommonParams();
    const { data: hsnList } = useGetHsnCodeQuery({ params: { ...params, rackId } });
    return (
        <>
            {withoutLabel ?
                <select value={selected} onChange={(e) => setSelected(e.target.value)} className='w-full table-data-input'
                    disabled={readOnly}
                >
                    <option value="">Select</option>
                    {(hsnList ? hsnList.data : []).map(item =>
                        <option key={item.id} value={item.id}>{item.hsnCode}</option>
                    )}
                </select>
                :
                <>
                    {multiSelect ?
                        <MultiSelectDropdown readOnly={readOnly} name={name} selected={selected} setSelected={setSelected}
                            options={multiSelectOption(hsnList ? hsnList.data : [], "hsnCode", "id")} />
                        :
                        <DropdownInput readOnly={readOnly} name={name} value={selected} setValue={setSelected}
                            options={dropDownListObject(hsnList ? hsnList.data : [], "hsnCode", "id")} clear />
                    }
                </>
            }
        </>
    )
}

export default HsnDropdown
