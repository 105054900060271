import React, { useEffect, useState } from 'react';
import { useGetStockQuery } from '../../../redux/PharmacyServices/StockService';
import { toast } from 'react-toastify';
import { getCommonParams, priceWithTax } from '../../../Utils/helper';
import StockItemDropdown from '../../../ReusableComponents/StockItemDropdown';

const SalesPrice = ({ productId, id, date, poBillItems, setPoBillItems, index, readOnly, storeId, item }) => {
    const { branchId } = getCommonParams()
    const { data: singleProduct } = useGetStockQuery({
        params: {
            isExpiredStock: true,
            productId,
            storeId,
            branchId,
            createdAt: id ? date : undefined,
            expiredStockReturnItemsId: item?.id,
        }
    }, { skip: !(productId) });

    const [salePrice, setSalePrice] = useState([]);
    useEffect(() => {
        if (singleProduct && singleProduct.data) {
            const salesPrices = singleProduct.data
            setSalePrice(salesPrices);
        }
    }, [singleProduct]);

    let stockQty = salePrice.find(i =>
        i.stockItem === poBillItems[index]?.stockItem
    )?.stockQty
    stockQty = stockQty ? stockQty : 0
    let isShow = poBillItems[index]?.productId;

    function handleInputChange(value, index, field) {
        setPoBillItems(poBillItems => {
            const newBlend = structuredClone(poBillItems);
            if (field === "stockItem") {
                newBlend[index]['salePrice'] = salePrice.find(i => i.stockItem === value)?.salePrice
                newBlend[index]['binId'] = salePrice.find(i => i.stockItem === value)?.binId
                newBlend[index]['batchNo'] = salePrice.find(i => i.stockItem === value)?.batchNo
                newBlend[index]['expiryDate'] = salePrice.find(i => i.stockItem === value)?.expiryDate
                newBlend[index]['purchasePrice'] = salePrice.find(i => i.stockItem === value)?.purchasePrice;
                newBlend[index]['supplierId'] = salePrice.find(i => i.stockItem === value)?.supplierId;
                newBlend[index]['pcsQty'] = 1;
            }
            newBlend[index][field] = value;
            if ((field === 'pcsQty') && parseFloat(stockQty) < parseFloat(newBlend[index]['pcsQty'])) {
                toast.info("Sales Qty Can not be more than Stock Qty", { position: 'top-center' })
                return poBillItems
            }
            return newBlend
        })
    };

    useEffect(() => {
        if (!(salePrice?.length === 1)) return
        setPoBillItems(prev => {
            const newBlend = structuredClone(prev);
            newBlend[index]['stockItem'] = salePrice[0].stockItem
            newBlend[index]['salePrice'] = salePrice[0].salePrice
            newBlend[index]['binId'] = salePrice[0]?.binId
            newBlend[index]['batchNo'] = salePrice[0]?.batchNo
            newBlend[index]['expiryDate'] = salePrice[0]?.expiryDate
            newBlend[index]['purchasePrice'] = salePrice[0]?.purchasePrice;
            newBlend[index]['supplierId'] = salePrice[0]?.supplierId;
            newBlend[index]['pcsQty'] = 1;
            return newBlend
        })
    }, [salePrice, setPoBillItems, index])
    console.log(poBillItems[0], 'pobillitems')
    return (
        <>
            <td className='table-data'>
                {isShow &&
                    <StockItemDropdown readOnly={readOnly} index={index} options={salePrice} value={item?.stockItem} setValue={(value) => { handleInputChange(value, index, "stockItem") }} />
                }
            </td>
            <td className='table-data text-right'>
                {parseFloat(item?.salePrice || 0).toFixed(2)}
            </td>
            <td className='table-data text-right'>
                {(isShow) &&
                    <>
                        {stockQty}
                    </>
                }
            </td >
            <td className='table-data'>
                <input
                    min={0}
                    type="number"
                    className="rounded py-1 px-1 w-full table-data-input bg-inherit text-right"
                    value={item?.pcsQty ? item?.pcsQty : 0}
                    disabled={readOnly}
                    onChange={(e) =>
                        handleInputChange(e.target.value, index, "pcsQty")
                    }
                    onBlur={(e) => {
                        handleInputChange(parseFloat(e.target.value).toFixed(2), index, "pcsQty");
                    }
                    }
                />
            </td>
            <td className='table-data text-right'>
                {((parseFloat((item?.pcsQty) || 0) * parseFloat(poBillItems[index]?.salePrice) || 0)).toFixed(2)}
            </td>
            <td className='table-data text-right'>
                {parseFloat(item?.tax || 0).toFixed(2)}
            </td>
            <td className='table-data text-right'>
                {priceWithTax((parseFloat((item?.pcsQty) || 0) * parseFloat(poBillItems[index]?.salePrice) || 0), item?.tax || 0)}
            </td>
        </>
    );
}

export default SalesPrice;
