// PatientRoomTransferApi.js
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";
import { PATIENT_ROOM_TRANSFER_API } from "../../Api";

const PatientRoomTransferApi = createApi({
  reducerPath: "PatientRoomTransfer",
  baseQuery,
  tagTypes: ["PatientRoomTransfer", "Room"], // Make sure both tags are included in tagTypes
  endpoints: (builder) => ({
    getPatientRoomTransfer: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: PATIENT_ROOM_TRANSFER_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params,
          };
        }
        return {
          url: PATIENT_ROOM_TRANSFER_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params,
        };
      },
      providesTags: ["PatientRoomTransfer"],
    }),
    getPatientRoomTransferById: builder.query({
      query: (id) => ({
        url: `${PATIENT_ROOM_TRANSFER_API}/${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      providesTags: ["PatientRoomTransfer"],
    }),
    addPatientRoomTransfer: builder.mutation({
      query: (payload) => ({
        url: PATIENT_ROOM_TRANSFER_API,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["PatientRoomTransfer", "Room"], // Invalidate both tags to trigger refetch
    }),
    updatePatientRoomTransfer: builder.mutation({
      query: (payload) => {
        const { id, body } = payload;
        return {
          url: `${PATIENT_ROOM_TRANSFER_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["PatientRoomTransfer", "Room"], // Invalidate both tags to trigger refetch
    }),
    deletePatientRoomTransfer: builder.mutation({
      query: (id) => ({
        url: `${PATIENT_ROOM_TRANSFER_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PatientRoomTransfer", "Room"], // Invalidate both tags to trigger refetch
    }),
  }),
});

export const {
  useGetPatientRoomTransferQuery,
  useGetPatientRoomTransferByIdQuery,
  useAddPatientRoomTransferMutation,
  useUpdatePatientRoomTransferMutation,
  useDeletePatientRoomTransferMutation,
} = PatientRoomTransferApi;

export default PatientRoomTransferApi;
