// roomMasterApi.js
import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";
import { ROOM_API } from "../../Api";

const roomMasterApi = createApi({
  reducerPath: "roomMaster",
  baseQuery,
  tagTypes: ["Room"], 
  endpoints: (builder) => ({
    getRoom: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: ROOM_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params,
          };
        }
        return {
          url: ROOM_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params,
        };
      },
      providesTags: ["Room"], 
    }),
    getRoomById: builder.query({
      query: (id) => ({
        url: `${ROOM_API}/${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      providesTags: ["Room"], 
    }),
    addRoom: builder.mutation({
      query: (payload) => ({
        url: ROOM_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Room"],
    }),
    updateRoom: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${ROOM_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Room"],
    }),
    deleteRoom: builder.mutation({
      query: (id) => ({
        url: `${ROOM_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Room"],
    }),
  }),
});

export const {
  useGetRoomQuery,
  useGetRoomByIdQuery,
  useAddRoomMutation,
  useUpdateRoomMutation,
  useDeleteRoomMutation,
} = roomMasterApi;

export default roomMasterApi;
