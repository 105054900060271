import React, { useEffect, useState, useRef, useCallback } from 'react';
import secureLocalStorage from 'react-secure-storage';

import FormHeader from '../../../Basic/components/FormHeader';
import { toast } from "react-toastify"
import { DisabledInput, } from "../../../Inputs"
import ReportTemplate from '../../../Basic/components/ReportTemplate';
import {
  useGetStockReceiptQuery,
  useGetStockReceiptByIdQuery,
  useAddStockReceiptMutation,
  useUpdateStockReceiptMutation,
  useDeleteStockReceiptMutation,
} from '../../../redux/PharmacyServices/StockReceiptService'

import { getCommonParams, getDateFromDateTime, isGridDatasValid } from '../../../Utils/helper';
import { useGetPartyQuery } from '../../../redux/PharmacyServices/PartyMasterService';
import { Loader } from '../../../Basic/components';
import PoBillItems from './PoBillItems';
import Modal from "../../../UiComponents/Modal";
import SearchReport from './PurchaseBillFormReport';
import moment from 'moment';
import StoreDropdown from '../../../ReusableComponents/StoreDropdown';
import LocationDropdown from '../../../ReusableComponents/LocationDropdown';
import useSetDefaultLocationStore from '../../../CustomHooks/useSetDefaultLocationStore';
import BranchDropdown from '../../../ReusableComponents/BranchDropdown';
import StockDeliveryDropdown from '../../../ReusableComponents/StockDeliveryDropdown';
import FillGridPopup from './FillGridPopup';
import { useDispatch } from 'react-redux';

const MODEL = "STOCK RECEIPT";

export default function Form() {

  const today = new Date()
  const [form, setForm] = useState(true);
  const [date, setDate] = useState(getDateFromDateTime(today));
  const [docId, setDocId] = useState("");
  const [address, setAddress] = useState("");
  const [place, setPlace] = useState("");
  const [supplierDcDate, setSupplierDcDate] = useState(new Date());
  const [formReport, setFormReport] = useState(false)
  const [storeId, setStoreId] = useState("");
  const [locationId, setLocationId] = useState("")
  const [deliveryFromBranchId, setDeliveryFromBranchId] = useState('');
  const [deliveryId, setDeliveryId] = useState('')

  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [supplierDcNo, setSupplierDcNo] = useState("")

  const [netBillValue, setNetBillValue] = useState("")

  const [searchValue, setSearchValue] = useState("");
  const [poBillItems, setPoBillItems] = useState([]);
  const [refetchStoreId, setRefetchStoreId] = useState(false);

  const [fillGridPopup, setFillGridPopup] = useState(false);

  const childRecord = useRef(0);

  const { branchId, finYearId } = getCommonParams()

  function handleInputChange(value, index, field) {
    const newBlend = structuredClone(poBillItems);
    newBlend[index][field] = value;
    setPoBillItems(newBlend);
  };

  const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") }

  const { data: allData, isLoading, isFetching } = useGetStockReceiptQuery({ params: { branchId, finYearId }, searchParams: searchValue });
  const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetStockReceiptByIdQuery(id, { skip: !id });

  const [addData] = useAddStockReceiptMutation();
  const [updateData] = useUpdateStockReceiptMutation();
  const [removeData] = useDeleteStockReceiptMutation();

  const { data: supplierList } =
    useGetPartyQuery({ params });

  const getNextDocId = useCallback(() => {

    if (id || isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const syncFormWithDb = useCallback(
    (data) => {
      if (id) setReadOnly(true);
      else setReadOnly(false)
      if (data?.createdAt) setDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
      if (data?.docId) {
        setDocId(data.docId);
      }
      setSupplierDcNo(data?.supplierDcNo ? data?.supplierDcNo : "");
      setSupplierId(data?.supplierId ? data?.supplierId : "");
      setAddress(data?.address ? data.address : "")
      setPlace(data?.place ? data.place : "")
      setPoBillItems(data?.StockReceiptItems || []);
      setStoreId(data?.storeId ? data.storeId : '')
      setLocationId(data?.Store?.locationId ? data?.Store?.locationId : '')
      setDeliveryFromBranchId(data?.deliveryFromBranchId || '')
      setDeliveryId(data?.deliveryId || '')
      setRefetchStoreId(true)
      childRecord.current = data?.childRecord ? data?.childRecord : 0;
    }, [id])


  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData])

  useSetDefaultLocationStore({ setLocationId, setStoreId, refetchStoreId, setRefetchStoreId, id })

  const data = {
    branchId,
    storeId,
    supplierId,
    supplierDcNo,
    supplierDcDate,
    address,
    place,
    netBillValue,
    stockReceiptItems: poBillItems.filter(item => item.productId),
    id,
    finYearId,
    deliveryId,
    deliveryFromBranchId
  }

  const validateData = (data) => {
    if ((data.stockReceiptItems.length > 0) && isGridDatasValid(data.stockReceiptItems, false, ['batchNo', 'qty', 'pcsQty', 'expiryDate', 'productId', "uomId", "price", "binId"])) {
      return true;
    }
    return false;
  }

  function getTotal(field1, field2) {
    const total = poBillItems.reduce((accumulator, current) => {

      return accumulator + parseFloat(current[field1] && current[field2] ? current[field1] * current[field2] : 0)
    }, 0)
    return parseFloat(total)
  }


  const validateNetBillValue = () => {

    if (getTotal("qty", "price").toFixed(2) === parseFloat(netBillValue).toFixed(2)) {
      return true;
    }
    return false;
  }

  const dispatch = useDispatch();


  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData = await callback(data).unwrap();
      if (returnData.statusCode === 0) {
        setId("")
        syncFormWithDb(undefined)
        toast.success(text + "Successfully");
      } else {
        toast.error(returnData?.message)
      }
      dispatch({
        type: `stockDelivery/invalidateTags`,
        payload: ['StockDelivery'],
      });
    } catch (error) {
      console.log("handle")
    }
  }

  const saveData = () => {
    console.log(data, 'data')
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    // if (!window.confirm("Are you sure save the details ...?")) {
    //   return
    // }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated")
    } else {
      handleSubmitCustom(addData, data, "Added")
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return
      }
      try {
        await removeData(id).unwrap();
        setId("");
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong")
      }
      ;
    }
  }

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      event.preventDefault();
      saveData();
    }
  }

  const onNew = () => {
    setId("");
    getNextDocId();
    setReadOnly(false);
    setForm(true);
    setSearchValue("")
    syncFormWithDb(undefined);
  }

  const isGridValid = poBillItems.length > 0;
  return (
    <div onKeyDown={handleKeyDown} className='md:items-start md:justify-items-center grid bg-theme'>
      <Modal
        isOpen={formReport}
        onClose={() => setFormReport(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}
      >
        <SearchReport onClick={(id) => { setId(id); setFormReport(false) }} />
      </Modal>
      <Modal
        isOpen={fillGridPopup}
        onClose={() => setFillGridPopup(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}
      >
        <FillGridPopup gridDetails={poBillItems} deliveryId={deliveryId} id={id} setGridDetails={setPoBillItems} setFillGrid={setFillGridPopup} />
      </Modal>
      <div className='flex flex-col frame w-full'>
        <FormHeader
          onNew={onNew}
          model={MODEL}
          openReport={() => setFormReport(true)}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <div className='flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2'>
          <div className='col-span-4 grid md:grid-cols-1 border'>
            <div className='mr-1 md:ml-2'>
              <fieldset className=' my-1'>
                <div className='grid grid-cols-8 my-2 gap-4'>
                  <DisabledInput name="DOC.No" value={docId} required={true} readOnly={readOnly} />
                  <DisabledInput name="DOC. Date" value={date} type={"Date"} required={true} readOnly={readOnly} />
                  <LocationDropdown selected={locationId} setSelected={(value) => { setLocationId(value); setStoreId("") }} name={"Location"} multiSelect={false} withoutLabel={false} readOnly={readOnly} />
                  <StoreDropdown selected={storeId} setSelected={setStoreId} name={"Store"} multiSelect={false} withoutLabel={false} readOnly={!locationId || readOnly} />
                  <BranchDropdown name={"Delivery From"} readOnly={id || isGridValid || readOnly} selected={deliveryFromBranchId} setSelected={setDeliveryFromBranchId} />
                  <StockDeliveryDropdown name={"Stock Delivery"} deliveryFromId={deliveryFromBranchId} readOnly={id || isGridValid || !deliveryFromBranchId || readOnly} selected={deliveryId} setSelected={setDeliveryId} pendingDeliveryOnly />
                </div>
              </fieldset>
              <PoBillItems handleInputChange={handleInputChange} id={id} readOnly={readOnly} poBillItems={poBillItems}
                setPoBillItems={setPoBillItems} singleData={singleData} storeId={storeId}
                openPopup={() => {
                  if (!deliveryId) return toast.info("Select Delivery...!!!")
                  setFillGridPopup(true)
                }} />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
