import React, { Fragment } from 'react'
import { useEffect, useState } from "react";
import { useGetPartyQuery } from "../../../redux/PharmacyServices/PartyMasterService"
import { Loader } from "../../../Basic/components";

import secureLocalStorage from 'react-secure-storage';
import { findFromList, getDateFromDateTimeToDisplay, pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { useGetPurchaseBillByIdQuery, useGetPurchaseBillQuery } from '../../../redux/PharmacyServices/PurchaseBillService';
import { TablePagination } from '@mui/material';


const PurchaseRegister = ({
  onClick,
}) => {
  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )
  const [searchDocId, setSearchDocId] = useState("");
  const [searchBillDate, setSearchBillDate] = useState("");
  const [searchSupplierDcNo, setSearchSupplierDcNo] = useState("");
  const [earchDocId, setSearchBillNo] = useState('')
  const [id, setId] = useState('')
  const [searchSupplierName, setSearchSupplierName] = useState("");
  const [searchBuyerName, setSearchBuyerName] = useState("");
  const [searchPackageName, setSearchPackageName] = useState("");
  const [dataPerPage, setDataPerPage] = useState("15");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchSupplierDcDate, setSearchSupplierDcDate] = useState("")
  const [currentOpenNumber, setCurrentOpenNumber] = useState('')
  const handleOnclick = (e) => {
    setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
  }
  const searchFields = { searchDocId, searchBillDate, searchSupplierName, searchSupplierDcNo, searchSupplierDcDate, earchDocId }

  useEffect(() => { setCurrentPageNumber(0) }, [dataPerPage, searchDocId, searchBillDate, searchSupplierName, searchSupplierDcNo, searchSupplierDcDate])

  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  )
  const params = {
    branchId, companyId
  };



  const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetPurchaseBillByIdQuery(id, { skip: !id });
  console.log(singleData, 'singleData');

  const { data: allData, isLoading, isFetching } = useGetPurchaseBillQuery({ params: { branchId, ...searchFields, pagination: true, dataPerPage, pageNumber: currentPageNumber + 1 } });

  const { data: supplierList, isLoading: isSupplierLoading, isFetching: isSupplierFetching } =
    useGetPartyQuery({ params: { ...params } });

  useEffect(() => {
    if (allData?.totalCount) {
      setTotalCount(allData?.totalCount)
    }
  }, [allData, isLoading, isFetching])

  useEffect(() => {
    let registrationFormReportComponent = document.getElementById("registrationFormReport");
    registrationFormReportComponent.addEventListener('keydown', function (ev) {
      var focusableElementsString = '[tabindex="0"]';
      let ol = document.querySelectorAll(focusableElementsString);
      if (ev.key === "ArrowDown") {
        for (let i = 0; i < ol.length; i++) {
          if (ol[i] === ev.target) {
            let o = i < ol.length - 1 ? ol[i + 1] : ol[0];
            o.focus(); break;
          }
        }
        ev.preventDefault();
      } else if (ev.key === "ArrowUp") {
        for (let i = 0; i < ol.length; i++) {
          if (ol[i] === ev.target) {
            let o = ol[i - 1];
            o.focus(); break;
          }
        }
        ev.preventDefault();
      }
    });
    return () => {
      registrationFormReportComponent.removeEventListener('keydown', () => { });
    };
  }, []);
  const handleChange = (event, value) => {
    setCurrentPageNumber(value);
  };
  const handleChangeRowsPerPage = (event) => {
    setDataPerPage(parseInt(event.target.value));
    setCurrentPageNumber(0);
  };
  const isLoadingIndicator = isLoading || isFetching || isSupplierFetching || isSupplierLoading
  const purData = allData?.data ? allData.data : []
  return (
    <>

      <div id='registrationFormReport' className="flex flex-col w-full  py-4">
        <div className="flex items-center justify-between w-full page-heading p-1 text-black">
          <div className="heading text-center whitespace-nowrap">Purchase Register</div>
          <span className='flex gap-4'>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button text-white bg-blue-400 p-2 text-xs "
              table="table-to-xls"
              filename="tablexls"
              sheet="tablexls"
              buttonText='Download as excel'
            >

            </ReactHTMLTableToExcel>

          </span>
        </div>

        <div className='relative bg-white shadow-md m-4'>
          <div
            className=" overflow-x-auto overflow-auto   "
          >

            <table className="table-fixed text-center w-full  w-full text-xs text-left border border-White border-collapse ">
              <thead className="text-xs text-white bg-gray-700 uppercase  dark:text-gray-400">
                <tr className='sticky top-0  font-thin tracking-wider'>
                  <th
                    className="p-2 w-2"
                  >
                    S. no.
                  </th>
                  <th
                    className="p-2 text-center  border border-White font-semibold  top-0 stick-bg w-10"
                  >
                    <div>Bill. No</div><input
                      type="text"
                      className="text-black h-6 focus:outline-none border md:ml-3 border-white "
                      placeholder="Search"
                      value={searchDocId}
                      onChange={(e) => {
                        setSearchDocId(e.target.value);
                      }}
                    />
                  </th>
                  <th
                    className="p-2 text-center  border border-White font-semibold  top-0 stick-bg w-10"
                  >
                    <div>Bill. Date</div><input
                      type="text"
                      className="text-black h-6 focus:outline-none border md:ml-3 border-white"
                      placeholder="Search"
                      value={searchBillDate}
                      onChange={(e) => {
                        setSearchBillDate(e.target.value);
                      }}
                    />
                  </th>
                  <th

                    className="p-2 text-center  border border-White font-semibold  top-0 stick-bg w-10"
                  >
                    <div>Supplier</div><input
                      type="text"
                      className="text-black  h-6 focus:outline-none border md:ml-3 border-white "
                      placeholder="Search"
                      value={searchSupplierName}
                      onChange={(e) => {
                        setSearchSupplierName(e.target.value);
                      }}
                    />
                  </th>
                  <th
                    className="p-2 text-center  border border-White font-semibold  top-0 stick-bg w-10"
                  >
                    <div>Sup.Dc.No</div><input
                      type="text"
                      className="text-black h-6 focus:outline-none border md:ml-3 border-white "
                      placeholder="Search"
                      value={searchSupplierDcNo}
                      onChange={(e) => {
                        setSearchSupplierDcNo(e.target.value);
                      }}
                    />
                  </th>
                  <th
                    className="p-2 text-center  border border-White font-semibold  top-0 stick-bg w-10"
                  >
                    <div>Sup.Dc.Date</div><input
                      type="text"
                      className="text-black h-6 focus:outline-none border md:ml-3 border-white "
                      placeholder="Search"
                      value={searchSupplierDcDate}
                      onChange={(e) => {
                        setSearchSupplierDcDate(e.target.value);
                      }}
                    />
                  </th>


                </tr>
              </thead>
              {isLoadingIndicator ?
                <tbody>
                  <tr>
                    <td>
                      <Loader />
                    </td>
                  </tr>
                </tbody>
                :
                <tbody className="">
                  {purData.map((dataObj, index) => (
                    <Fragment key={index}>
                      <tr
                        className={` table-row ${(currentOpenNumber === index) ? "border-2 border-green-500" : ""}`}
                        onClick={() => {
                          if (index === currentOpenNumber) {
                            setCurrentOpenNumber("")
                          } else {
                            setCurrentOpenNumber(index)
                            setId(dataObj.id)
                          }
                        }}>
                        <td className='p-1'> {(index + 1) + (dataPerPage * (currentPageNumber))}</td>
                        <td className='p-2  text-[12px] text-left border border-white '>{dataObj.docId}</td>
                        <td className='p-2  text-[12px] text-left border border-white '> {getDateFromDateTimeToDisplay(dataObj?.createdAt)}</td>
                        <td className='p-2  text-[12px] text-left border border-white '>{dataObj?.supplier?.name} </td>
                        <td className='p-2  text-[12px] text-right border border-white '>{(dataObj?.supplierDcNo
                        )}</td>

                        <td className='p-2 text-[12px] text-right border border-white '>{parseFloat(dataObj?.netBillValue ? dataObj.netBillValue : 0).toFixed(2)}</td>

                      </tr>
                      {(currentOpenNumber === index)
                        &&



                        <table class=" border text-sm  w-[95vw]  m-2">
                          <thead className=" table-header">
                            <tr className='h-2'>
                              <th
                                className="  top-0 stick-bg w-10">
                                S.no.
                              </th>
                              <th
                                className="  top-0 stick-bg table-data "
                              >
                                Pro category

                              </th>
                              <th
                                className="  top-0 stick-bg table-data "
                              >
                                Pro Brand

                              </th>
                              <th
                                className="  top-0 stick-bg ">
                                Product
                              </th>

                              <th className="  top-0 stick-bg table-data">
                                <label>Qty</label>
                              </th>

                              <th
                                className="  top-0 stick-bg table-data "
                              >
                                <label>Price</label>

                              </th>
                              <th className="  top-0 stick-bg table-data">
                                <label>Total Amt</label>
                              </th>

                            </tr>
                          </thead>
                          <tbody className=' bg-blue-200 '>
                            {singleData?.data?.PoBillItems.map((poItem, index) => (
                              <tr key={index} className='text-[15px]'>
                                <td className='border-2 border-white'>{index + 1}</td>
                                <td className='border-2 border-white'>{poItem?.productCategoryName}</td>
                                <td className='border-2 border-white'>{poItem?.productBrandName}</td>
                                <td className='border-2 border-white'>{poItem?.
                                  name}</td>

                                <td className='py-1 text-right border-2 border-white'>{poItem?.qty}</td>
                                <td className='py-1 text-right border-2 border-white'>{parseFloat(poItem?.price).toFixed(2)}</td>
                                <td
                                  className='py-1 text-right border-2 border-white'>
                                  {parseFloat(poItem?.price) * parseFloat().toFixed(2)}</td>

                              </tr>
                            ))}
                            {console.log(singleData?.data?.PoBillItems, 'data')}
                          </tbody>
                        </table>
                      }
                    </Fragment>
                  ))}
                </tbody>

              }
            </table>
            <table id="table-to-xls" className="w-full text-xs text-left border border-White border-collapse  visible">
              <thead className="text-xs text-white bg-gray-700 uppercase  dark:text-gray-400">
                <tr className='sticky top-0  font-thin tracking-wider'>
                  <th
                    className="p-2 text-center  border border-White font-semibold  top-0 stick-bg w-10"
                  >
                    S. no.
                  </th>
                  <th
                    className="p-2 text-center  border border-White font-semibold  top-0 stick-bg w-10"
                  >
                    <div>Bill. No</div>

                  </th>
                  <th
                    className="p-2 text-center  border border-White font-semibold  top-0 stick-bg w-10"
                  >
                    <div>Bill. Date</div>

                  </th>
                  <th

                    className="p-2 text-center  border border-White font-semibold  top-0 stick-bg w-10"
                  >
                    <div>Supplier</div>

                  </th>
                  <th
                    className="p-2 text-center  border border-White font-semibold  top-0 stick-bg w-10"
                  >
                    <div>Sup.Dc.No</div>

                  </th>
                  <th
                    className="p-2 text-center  border border-White font-semibold  top-0 stick-bg w-10"
                  >
                    <div>Sup.Dc.Date</div>

                  </th>


                </tr>
              </thead>
              {isLoadingIndicator ?
                <tbody>
                  <tr>
                    <td>
                      <Loader />
                    </td>
                  </tr>
                </tbody>
                :
                <tbody className="">
                  {purData.map((dataObj, index) => (
                    <Fragment key={index}>
                      <tr
                        className={` table-row ${(currentOpenNumber === index) ? "border-2 border-green-500" : ""}`}
                        onClick={() => {
                          if (index === currentOpenNumber) {
                            setCurrentOpenNumber("")
                          } else {
                            setCurrentOpenNumber(index)
                            setId(dataObj.id)
                          }
                        }}>
                        <td className='p-1'> {(index + 1) + (dataPerPage * (currentPageNumber))}</td>
                        <td className='p-2  text-[12px] text-left border border-white '>{dataObj.docId}</td>
                        <td className='p-2  text-[12px] text-left border border-white '> {getDateFromDateTimeToDisplay(dataObj?.createdAt)}</td>
                        <td className='p-2  text-[12px] text-left border border-white '>{dataObj?.supplier?.name} </td>
                        <td className='p-2  text-[12px] text-right border border-white '>{(dataObj?.supplierDcNo
                        )}</td>

                        <td className='p-2 text-[12px] text-right border border-white '>{parseFloat(dataObj?.netBillValue ? dataObj.netBillValue : 0).toFixed(2)}</td>

                      </tr>
                      {(currentOpenNumber === index)
                        &&



                        <table class=" border text-sm  m-2">
                          <thead className=" table-header">
                            <tr className='h-2'>
                              <th
                                className="  top-0 stick-bg w-10">
                                S.no.
                              </th>
                              <th
                                className="  top-0 stick-bg table-data "
                              >
                                Pro category

                              </th>
                              <th
                                className="  top-0 stick-bg table-data "
                              >
                                Pro Brand

                              </th>
                              <th
                                className="  top-0 stick-bg ">
                                Product
                              </th>

                              <th className="  top-0 stick-bg table-data">
                                <label>Qty</label>
                              </th>

                              <th
                                className="  top-0 stick-bg table-data "
                              >
                                <label>Price</label>

                              </th>
                              <th className="  top-0 stick-bg table-data">
                                <label>Total Amt</label>
                              </th>

                            </tr>
                          </thead>
                          <tbody className=' bg-blue-200 '>
                            {singleData?.data?.PoBillItems.map((poItem, index) => (
                              <tr key={index} className='text-[15px]'>
                                <td className='border-2 border-white'>{index + 1}</td>
                                <td className='border-2 border-white'>{poItem?.productCategoryName}</td>
                                <td className='border-2 border-white'>{poItem?.productBrandName}</td>
                                <td className='border-2 border-white'>{poItem?.
                                  name}</td>

                                <td className='py-1 text-right border-2 border-white'>{poItem?.qty}</td>
                                <td className='py-1 text-right border-2 border-white'>{parseFloat(poItem?.price).toFixed(2)}</td>
                                <td
                                  className='py-1 text-right border-2 border-white'>
                                  {parseFloat(poItem?.price) * parseFloat().toFixed(2)}</td>

                              </tr>
                            ))}
                            {console.log(singleData?.data?.PoBillItems, 'data')}
                          </tbody>
                        </table>
                      }
                    </Fragment>
                  ))}
                </tbody>

              }
            </table>
          </div>
          <div className='flex items-center justify-end'>
            <TablePagination
              component="div"
              count={totalCount}
              page={currentPageNumber}
              onPageChange={handleChange}
              rowsPerPage={dataPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>

      </div>
    </>
  )
}

export default PurchaseRegister



