import React, { useEffect } from 'react'
import { useGetStockQuery } from '../../../redux/PharmacyServices/StockService'
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import { useState } from 'react';
import { TablePagination } from '@mui/material';
import { RadioButton } from '../../../Inputs';

const Expired = ({ isExpiredOrOutOfStock, outOfStockReport }) => {
    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);


    const { data: expiredData } = useGetStockQuery({ params: { isGetStockDashboardData: true, pagination: true, dataPerPage, pageNumber: currentPageNumber + 1, isExpiredOrOutOfStock, outOfStockReport } })

    useEffect(() => {
        if (!expiredData?.totalCount) return
        setTotalCount(expiredData?.totalCount);
    }, [expiredData]);

    const handleChange = (event, value) => {
        setCurrentPageNumber(value);
    };
    const handleChangeRowsPerPage = (event) => {
        setDataPerPage(parseInt(event.target.value, 10));
        setCurrentPageNumber(0);
    };
    const frozenRows = 9;

    const stockItems = expiredData?.data?.stockItems || [];
    return (
        <section className="bg-gray-100  ">


            <div className="text-center mx-auto max-w-screen-2xl">
                <div className="relative bg-white shadow-md ">
                    <div className="overflow-x-auto overflow-auto h-[350px] mt-3">
                        <table className="w-full text-xs text-left border border-White border-collapse">
                            <thead className="text-xs uppercase text-white bg-gray-700 dark:bg-gray-700 dark:text-gray-400">
                                <tr className={`sticky top-0  font-thin tracking-wider `}>
                                    <th scope="col" className="p-2 text-center  border border-gray-300 font-semibold">S.No.</th>
                                    <th scope="col" className="p-2 text-center  border border-gray-300 font-semibold">Name</th>
                                    <th scope="col" className="p-2 text-center  border border-gray-300 font-semibold">Supplier</th>
                                    <th scope="col" className="p-2 text-center  border border-gray-300 w-24 font-semibold">Batch No</th>
                                    <th scope="col" className="p-2 text-center  border border-gray-300 font-semibold">Expiry Date</th>
                                    <th scope="col" className="p-2 text-center  border border-gray-300 w-48 font-semibold">Expired Days</th>
                                    <th scope="col" className="p-2 text-center  border border-gray-300 font-semibold">Stock Qty</th>
                                    {isExpiredOrOutOfStock && (
                                        <th scope="col" className="p-2 text-center font-thin border border-gray-300 w-24">Status</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody className='bg-gray-100  '>
                                {stockItems.map((stockItem, index) => (
                                    <tr
                                        key={index}
                                        className={`border-b border-white dark:border-gray-600 bg-gray-100  dark:hover:bg-gray-700 `}
                                    >
                                        <td className="p-2 text-center border border-white">{index + 1}</td>
                                        <td className="p-2 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white border border-white">
                                            {stockItem?.name}
                                        </td>
                                        <td className="p-2 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white border border-white">
                                            {stockItem?.supplierName}
                                        </td>
                                        <td className="p-2 text-center border border-white">
                                            {stockItem?.batchNo && (
                                                <span className="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded">{stockItem?.batchNo}</span>
                                            )}
                                        </td>
                                        <td className="p-2 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white border border-white">
                                            <div className="flex items-center">
                                                {stockItem?.expiryDate && (
                                                    <>
                                                        <div className={`inline- w-4 h-4 mr-2 ${stockItem.expireInDays > 0 ? 'bg-emerald-600' : 'bg-red-700'} rounded-full`}></div>
                                                        {getDateFromDateTimeToDisplay(stockItem.expiryDate)}
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                        <td className="p-2 text-right font-medium text-gray-900 whitespace-nowrap dark:text-white border border-white">
                                            {stockItem?.expireInDays}
                                        </td>
                                        <td className="p-2 text-right font-medium text-gray-900 whitespace-nowrap dark:text-white border border-white">
                                            {stockItem?.stockQty}
                                        </td>
                                        {isExpiredOrOutOfStock && (
                                            <td className="p-2 text-center flex items-center justify-center font-medium text-gray-900 whitespace-nowrap dark:text-white border border-white">
                                                <div className="flex justify-center items-center w-[100px]">
                                                    {parseFloat(stockItem?.stockQty) > 0 ? (
                                                        <span className="inline-flex items-center gap-x-1.5 px-3 rounded-full text-xs font-medium bg-red-500 text-white">Expired</span>
                                                    ) : (
                                                        <span className="inline-flex items-center gap-x-1.5 px-3 rounded-full text-xs font-medium bg-gray-500 text-white">Out Of Stock</span>
                                                    )}
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                                {(
                                    Array.from({ length: frozenRows - stockItems.length }).map((_, i) => (
                                        <tr
                                            key={i}
                                            className={`bg-gray-100 border border-white md:border-none block md:table-row h-[32px]`}
                                        >
                                            <td className="md:border md:border-white text-left block md:table-cell p-1 bg-gray-100"></td>
                                            <td className="md:border md:border-white text-right block md:table-cell p-1 bg-gray-100"></td>
                                            <td className="md:border md:border-white text-left block md:table-cell p-1 bg-gray-100"></td>
                                            <td className="md:border md:border-white text-left block md:table-cell p-1 bg-gray-100"></td>
                                            <td className="md:border md:border-white text-left block md:table-cell p-1 bg-gray-100"></td>
                                            <td className="md:border md:border-white text-left block md:table-cell p-1 bg-gray-100"></td>
                                            <td className="md:border md:border-white text-left block md:table-cell p-1 bg-gray-100"></td>
                                        </tr>
                                    ))
                                )}


                            </tbody>
                        </table>

                    </div>
                    <div className='flex items-center justify-end'>
                        <TablePagination
                            component="div"
                            count={totalCount}
                            page={currentPageNumber}
                            onPageChange={handleChange}
                            rowsPerPage={dataPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Expired
