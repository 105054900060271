import React from 'react'
import { DropdownInput } from '../Inputs'

const ProductSalesType = ({ drugSalesType, setDrugSalesType, readOnly, childRecord }) => {
    const options = [
        { show: 'OTC', value: 'OTC' },
        { show: 'PRESCRIPTION NEED', value: 'PRESCRIPTION_NEED' },
        { show: 'NARCOTIC', value: 'NARCOTIC' }
    ]
    return (
        <DropdownInput name="Drug Type" options={options} value={drugSalesType} setValue={setDrugSalesType} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
    )
}

export default ProductSalesType