import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faUserClock, faPrescriptionBottleAlt } from '@fortawesome/free-solid-svg-icons';
import { useGetPatientVisitQuery } from '../../../redux/pharmaServices/PatientVisitTransaction';

const DoctorDashboardCard = () => {
    const { data: dashboardData } = useGetPatientVisitQuery({ params: { isGetDashboardData: true } });

    const { todayInfo, yesterdayInfo, thisWeekInfo, thisMonthInfo } = dashboardData?.data || {};

    const data = [
        {
            name: "Today Visits",
            value: `${(todayInfo?.totalVisits || 0)}`,
            borderColor: "#0093D4",
            left: 'Consulted',
            leftValue: `${(todayInfo?.consultedVisits || 0)}`,
            leftIcon: faUserCheck,
            center: 'Waiting',
            centerValue: `${(todayInfo?.pendingVisits || 0)}`,
            centerIcon: faUserClock,
            right: 'Pharma',
            rightValue: `${(todayInfo?.pharmaVisits || 0)}`,
            rightIcon: faPrescriptionBottleAlt,
        },
        {
            name: "Yesterday Visits",
            value: `${(yesterdayInfo?.totalVisits || 0)}`,
            borderColor: "#006FD5",
            left: 'Consulted',
            leftValue: `${(yesterdayInfo?.consultedVisits || 0)}`,
            leftIcon: faUserCheck,
            center: 'Waiting',
            centerValue: `${(yesterdayInfo?.pendingVisits || 0)}`,
            centerIcon: faUserClock,
            right: 'Pharma',
            rightValue: `${(yesterdayInfo?.pharmaVisits || 0)}`,
            rightIcon: faPrescriptionBottleAlt,
        },
        {
            name: "This Week Visits",
            value: `${(thisWeekInfo?.totalVisits || 0)}`,
            borderColor: "#386BA2",
            left: 'Consulted',
            leftValue: `${(thisWeekInfo?.consultedVisits || 0)}`,
            leftIcon: faUserCheck,
            center: 'Waiting',
            centerValue: `${(thisWeekInfo?.pendingVisits || 0)}`,
            centerIcon: faUserClock,
            right: 'Pharma',
            rightValue: `${(thisWeekInfo?.pharmaVisits || 0)}`,
            rightIcon: faPrescriptionBottleAlt,
        },
        {
            name: "This Month Visits",
            value: `${(thisMonthInfo?.totalVisits || 0)}`,
            borderColor: "#1F4764",
            left: 'Consulted',
            leftValue: `${(thisMonthInfo?.consultedVisits || 0)}`,
            leftIcon: faUserCheck,
            center: 'Waiting',
            centerValue: `${(thisMonthInfo?.pendingVisits || 0)}`,
            centerIcon: faUserClock,
            right: 'Pharma',
            rightValue: `${(thisMonthInfo?.pharmaVisits || 0)}`,
            rightIcon: faPrescriptionBottleAlt,
        },
    ];

    return (
        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-4 gap-4 h-full'>
            {data.map((val, i) => (
                <div
                    key={i}
                    className={`flex flex-col bg-white p-1 rounded-lg shadow-md border-l-4`}
                    style={{ borderColor: val.borderColor }}
                >
                    <div className='text-xl font-bold mb-2 text-center font-xs font-thin' style={{ color: val.borderColor }}>
                        {val.name}
                    </div>
                    <div className='flex justify-center text-2xl font-bold mb-2 gray-500'>
                        {val.value}
                    </div>
                    <div className='flex justify-between text-gray-800 text-[14px]'>
                        <div className='flex flex-col items-center'>
                            <FontAwesomeIcon icon={val.leftIcon} className='text-2xl' style={{ color: val.borderColor }} />
                            <h2 className='text-sm'>{val.left}</h2>
                            <div>{val.leftValue}</div>
                        </div>
                        <div className='flex flex-col items-center'>
                            <FontAwesomeIcon icon={val.centerIcon} className='text-2xl' style={{ color: val.borderColor }} />
                            <h2 className='text-sm'>{val.center}</h2>
                            <div>{val.centerValue}</div>
                        </div>
                        <div className='flex flex-col items-center'>
                            <FontAwesomeIcon icon={val.rightIcon} className='text-2xl' style={{ color: val.borderColor }} />
                            <h2 className='text-sm'>{val.right}</h2>
                            <div>{val.rightValue}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DoctorDashboardCard;
