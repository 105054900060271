export const EMPLOYEE_API = "employees";
export const CITY_API = "cities";
export const STATE_API = "states";
export const COUNTRY_API = "countries";
export const BRAND_API = "brands";
export const MODEL_API = "models";
export const BATTERY_API = "batteries";
export const DEPARTMENT_API = "departments";
export const LOGIN_API = "users/login";
export const COMPANY_API = "companies";
export const BRANCHES_API = "branches";
export const USERS_API = "users";
export const PAGES_API = "pages";
export const ROLES_API = "roles";
export const SUBSCRIPTION_API = "subscriptions";
export const FIN_YEAR_API = "finYear";
export const EMPLOYEE_CATEGORY_API = "employeeCategories";
export const PATIENT_API = "patients";
export const PATIENT_VISITS_API = "patientVisit";
export const PATIENT_ADMIT_API = "patientAdmit";
export const PATIENT_ROOM_TRANSFER_API = "patientTransfer";
export const PATIENT_DISCHARGE_API = "patientDischarge";
export const LAB_TEST_TYPES_API = "labTestType";
export const MEDICINES_API = "medicines";
export const LAB_TEST_API = "labTest";
export const PARTY_CATEGORY_API = "partyCategories"
export const ROOM_API = "room"
export const ROOMTYPE_API = "roomType"

export const PARTY_API = "party"
export const PRODUCT_BRAND_API = "productBrand"
export const PRODUCT_CATEGORY_API = "productCategory"
export const PRODUCT_API = "product"
export const PURCHASE_BILL_API = "purchaseBill"
export const STOCK_API = "stock"
export const SALES_BILL_API = "salesBill"
export const EXPIRED_STOCK_RETURN_API = "expiredStockReturn"
export const PAYMENT_API = "payment"
export const PURCHASE_RETURN_API = "purchaseReturn"
export const SALES_RETURN_API = "salesReturn"
export const UOM_API = "uom"
export const PAGES_GROUP_API = "pageGroup"
export const LOCATION_API = "location"
export const RACK_API = "rack"
export const BIN_API = "bin"
export const BARCODE_API = "barcode"
export const HSN_CODE_API = "hsn"
export const FEES_TYPE_API = "feesType"
export const OPENING_STOCK = 'openingStock'
export const STOCK_TRANSFER_API = 'stockTransfer'
export const STOCK_DELIVERY_API = 'stockDelivery'
export const STOCK_RECEIPT_API = 'stockReceipt'