import React from 'react'
import { View, Text, Image } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import { ACC_NO, APP_NAME, BANK_NAME, BRANCH_NAME, G_PAY_NO, IFSC_CODE } from '../../../Strings';
import numToWordConverter from "num-words"
import moment from 'moment';
import { findFromList, numberFormatter, substract } from '../../../Utils/helper';
import paymentQr from "../../../assets/paymentQr.jpeg"

const TaxGrid = ({ taxDetails, data, userId, userData }) => {
    console.log(data, 'data');



    const amount = taxDetails.reduce((a, c) => a + parseFloat(c.amount || 0), 0);
    const totalAmount = taxDetails.reduce((a, c) => a + parseFloat(c.amount || 0), 0);
    const finalAmount = (data?.isTaxBill ? totalAmount : amount);
    const finalRoundedAmount = Math.round(data?.isTaxBill ? totalAmount : amount);
    const roundOffAmount = parseFloat(Math.abs(substract(finalAmount, finalRoundedAmount)));
    const size = "70px";
    return (
        <>
            <View style={tw(' border border-gray-500 w-full')} >

                <View style={tw('flex flex-row text-xs bg-gray-300')}>
                    <Text style={[tw('border-r border-b border-gray-500 p-2 w-16'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>
                        S.NO
                    </Text>

                    <Text style={[tw('border-r border-b border-gray-500 text-center p-2 w-2/4'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                        Header
                    </Text>
                    <Text style={[tw('border-r border-b border-gray-500 text-center p-2 w-1/4'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                        Service Name
                    </Text>
                    <Text style={[tw('border-r border-b border-gray-500 text-center p-2 w-1/4'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                        Amount
                    </Text>

                </View>



                {data?.feesStructureGrid?.map((item, index) =>
                    <View style={tw('flex flex-row text-xs')}>
                        <Text style={tw('border-r border-b border-gray-500 p-2 w-16')}>
                            {index + 1}
                        </Text>
                        <Text style={tw('border-r border-b  border-gray-500 p-2 w-2/4')}>
                            {item?.LabTest?.name || ""}
                        </Text>
                        <Text style={tw('border-r border-b border-gray-500 p-2 w-1/4')}>
                            {item?.name
                                || ""}

                        </Text>
                        <Text style={tw('border-r border-b border-gray-500 p-2 w-1/4 text-right')}>
                            {parseFloat(item?.amount || 0).toFixed(2)}

                        </Text>
                    </View>
                )}


                <View style={tw('flex flex-row text-xs bg-gray-300')}>
                    <Text style={[tw('border-r border-b border-gray-500 p-0.5 w-16'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                    </Text>

                    <Text style={[tw('border-r border-b border-gray-500 p-0.5 w-2/4'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>


                    </Text>
                    <Text style={[tw('border-r border-b border-gray-500 px-2 w-1/4 text-sm text-center mt-2'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                        Bill Amount
                    </Text>
                    <Text style={[tw('border-r border-b border-gray-500 px-2 w-1/4 text-right text-sm mt-2'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                        {parseFloat(data?.consultFee || "").toFixed(2)}
                    </Text>

                </View>




                <View style={tw('flex flex-row text-xs')}>

                    <Text style={tw('col-span-2  p-2 w-2/4')}>
                        Pay Mode
                    </Text>
                    <Text style={tw('  p-2 w-1/4')}>
                        {data?.paymentMode || ""}({numberFormatter(data?.consultFee?.toFixed(2))})
                    </Text>
                    <Text style={tw('border-r  p-2 w-1/4 ')}>


                    </Text>

                </View>




                <View style={tw("flex flex-row justify-start  gap-x-2 w-full  border-t-2  border-b-2 border-gray-500 p-2")} >
                    <View style={tw("flex flex-col w-3/4")}>
                        <View style={tw('flex flex-row text-xs')}>

                            <Text style={tw('p-2 w-4/4 capitalize text-sm')}>
                                Rupees  In Words :  Rs  {numToWordConverter(data?.consultFee) || 0} Only.
                            </Text>

                        </View>

                        <View style={tw('flex flex-row text-xs')}>

                            <Text style={tw('p-2 w-4/4')}>
                                Bill Prepared By {findFromList(userId, userData?.data, "username")} on {moment.utc(data?.createdAt).utcOffset("+05:30").format("hh:mm A")}
                            </Text>

                        </View>
                        <View style={tw('flex flex-row text-xs w-full text-center')}>

                            <Text style={tw('p-2 w-4/4')}>
                                For Emergency care: 0421- 242560
                            </Text>

                        </View>
                    </View>

                    <View style={tw("flex flex-col w-1/4 text-xs")}>
                        <Text style={tw('p-2 w-4/4')}>
                            For KC HOSPITAL

                        </Text>
                        <Text style={tw('p-2 w-4/4')}>


                        </Text>
                        <Text style={tw('p-2 w-4/4')}>
                            Authorized Signatory

                        </Text>

                    </View>
                </View>

            </View>































            {/* <View wrap={false} style={tw(" flex items-center  justify-center w-full")} >
            <View style={tw("w-full border-t border-b  border-black flex flex-col items-start")} > */}



            {/* <View style={tw("w-1/2 border-r border-black")}>
                    <Text style={tw("font-[Times-Bold] text-[12px] text-center border-b border-black h-5")}>BANK DETAILS</Text>
                    <View style={tw("w-full flex flex-row border-l border-black p-1")}>
                        <View style={tw("w-2/3 flex flex-row items-center")}>
                            <View style={tw("w-2/6 font-[Times-Bold] flex gap-1 text-left text-[8px]")}>
                                <Text style={tw("")}> BANK NAME:</Text>
                                <Text style={tw("")}> A/C NO:</Text>
                                <Text style={tw("")}> BRANCH:</Text>
                                <Text style={tw("")}> IFSC CODE:</Text>
                                <Text style={tw("")}> G-PAY NO:</Text>
                            </View>
                            <View style={tw("w-4/6 text-left text-[9px] flex gap-1")}>
                                <Text style={tw("")}> {BANK_NAME}</Text>
                                <Text style={tw("")}> {ACC_NO}</Text>
                                <Text style={tw("")}> {BRANCH_NAME}</Text>
                                <Text style={tw("")}> {IFSC_CODE}</Text>
                                <Text style={tw("")}> {G_PAY_NO}</Text>
                            </View>
                        </View>
                        <View style={tw("w-1/3 flex items-center justify-center")}>
                            <Image style={tw(`w-[${size}] h-[${size}]`)} src={paymentQr} />
                        </View>
                    </View>

                </View> */}

            {/* <View style={tw("w-full flex text-[10px] items-center border-b border-black")}>{console.log(taxDetails, "taxDetails")}
                        {taxDetails.map((item, index) =>
                            <View key={index} style={tw("w-full text-[8px]")}>
                                {partyDetails?.isIgst ?
                                    <View style={tw("w-full flex flex-row items-center ")} key={index}>
                                        <View style={tw("w-1/2 flex flex-row")}>
                                            <Text style={tw("w-1/2 border-r border-black text-center")}>
                                                IGST
                                            </Text>
                                            <Text style={tw("w-1/2 border-r border-black text-center")}>
                                                {item?.tax}%
                                            </Text>
                                        </View>
                                        <Text style={tw("w-1/2 text-right text-[10px] pr-2")}>
                                            {parseFloat(item?.taxAmount || 0).toFixed(2)}
                                        </Text>
                                    </View>
                                    :
                                    <>
                                        <View style={tw("w-full flex flex-row items-center")} key={index}>
                                            <View style={tw("w-1/2 flex flex-row")}>
                                                <Text style={tw("w-1/2 border-r border-black text-center")}>
                                                    CGST
                                                </Text>
                                                <Text style={tw("w-1/2 border-r border-black text-center")}>
                                                    {(item?.tax || 0) / 2}%
                                                </Text>
                                            </View>
                                            <Text style={tw("w-1/2  text-right text-[10px] pr-2")}>
                                                {parseFloat((item?.taxAmount || 0) / 2).toFixed(2)}
                                            </Text>
                                        </View>
                                        <View style={tw("w-full flex flex-row items-center")} key={index}>
                                            <View style={tw("w-1/2 flex flex-row")}>
                                                <Text style={tw("w-1/2 border-r border-black text-center")}>
                                                    SGST
                                                </Text>
                                                <Text style={tw("w-1/2 border-r border-black text-center")}>
                                                    {(item?.tax || 0) / 2}%
                                                </Text>
                                            </View>
                                            <Text style={tw("w-1/2  text-right text-[10px] pr-2")}>
                                                {parseFloat((item?.taxAmount || 0) / 2).toFixed(2)}
                                            </Text>
                                        </View>
                                    </>
                                }
                            </View>
                        )}
                    </View> */}
            {/* <View style={tw("text-[9px] text-center border-b border-black flex flex-row items-center p-1")}>
                        <Text style={tw("w-1/2")}>ROUND OFF : </Text>
                        <Text style={tw("w-1/2 text-right text-[10px] pr-2 ")}>{numberFormatter(roundOffAmount.toFixed(2))}</Text>
                     
                    </View> */}



            {/* <View style={tw("w-full flex flex-row  border-b border-gray-500")}>
                    <View style={tw(" text-[9px] text-center border-r  flex flex-row  p-1 w-1/2")}>
                        <Text style={tw("w-1/2  p-1")}>TOTAL :</Text>
                        <Text style={tw("w-1/2 text-right pr-2 p-1")}>{data?.consultFee ? parseFloat(data?.consultFee).toFixed(2) : ""}</Text>
                    </View>
                    <View style={tw(" text-[9px] text-center  flex flex-row  p-1 w-1/2")}>
                        <Text style={tw("w-1/2  p-1")}>Paid Amount :</Text>
                        <Text style={tw("w-1/2 text-right pr-2 p-1")}>{data?.consultFee ? parseFloat(data?.consultFee).toFixed(2) : ""}</Text>
                    </View>
                </View>

                <View style={tw("w-full flex flex-row ")}>
                    <View style={tw(" text-[9px] text-center  flex flex-row  border-r p-1 w-1/2")}>
                        <Text style={tw("w-1/2  p-1")}>Collected.By :</Text>
                        <Text style={tw("w-1/2 text-right pr-2 p-1")}> {"Tamil"}</Text>
                    </View>
                    <View style={tw(" text-[9px] text-center  flex flex-row p-1 w-1/2")}>
                        <Text style={tw("w-1/2  p-1")}>Balance :</Text>
                        <Text style={tw("w-1/2 text-right pr-2 p-1")}>{data?.consultFee ? parseFloat(data?.consultFee).toFixed(2) : ""}</Text>
                    </View>
                </View>

            </View>
            <View style={tw("w-full flex flex-row items-center justify-center text-[10px] border-x border-b border-black h-[20px] pt-1")}>
                <Text style={tw("w-[20%] text-center border-r border-black h-full flex flex-row ")}>
                    Rupees
                </Text>
                <Text style={tw("w-[80%] capitalize  h-full pl-1")}>{numToWordConverter(data?.consultFee)} Only.</Text>
            </View>
            <View style={tw("w-full flex flex-row items-start font-[Times-Bold] p-1 justify underline text-[10px] border border-black h-[60px]")}>

                <View style={tw("w-[40%] h-full border-r border-black  text-center")}>
                    <Text>
                        Receiver Signature
                    </Text>
                </View>
                <View style={tw("w-[60%] capitalize  text-end flex items-end ")}>
                    <Text>
                        For {APP_NAME}
                    </Text>
                </View>
            </View>
        </View> */}





        </>


    )
}

export default TaxGrid
