import React, { useState, useEffect } from 'react';
import Medication from './Medication';
import LabTest from './LabTest';
import { useGetLabTestTypesQuery } from '../../../redux/pharmaServices/LabTestTypes';
import { getCommonParams } from '../../../Utils/helper';

const Prescriptions = ({ medicines, setMedicines, prescribedLabTests, setPrescribedLabTests, readOnly = false }) => {
    const [activeNavBar, setActiveNavBar] = useState(null);
    const [labTestTypes, setLabTestTypes] = useState([]);
    const { companyId } = getCommonParams()

    const { data, isLoading, isFetching } = useGetLabTestTypesQuery({ params: { active: true, companyId } })
    useEffect(() => {
        if (!data?.data) return
        setLabTestTypes(data.data)
    }, [data, isLoading, isFetching])

    const subMenus = [{ name: "MEDICATION", id: null }].concat(labTestTypes)

    const getShowSubMenu = () => {
        if (activeNavBar === null) {
            return <Medication readOnly={readOnly} medicines={medicines} setMedicines={setMedicines} />
        }
        return <LabTest readOnly={readOnly} labTestTypeId={activeNavBar} prescribedLabTests={prescribedLabTests} setPrescribedLabTests={setPrescribedLabTests} />
    }
    return (
        <fieldset disabled={readOnly} className='px-2 w-full' >
            <legend className='flex gap-2 items-center' >
                {subMenus.map(menu =>
                    <div key={menu.id} onClick={() => setActiveNavBar(menu.id)} className={`col-span-2 border border-gray-400 text-center cursor-pointer ${activeNavBar === menu.id ? "sub-navbar-active" : "sub-navbar"}`}>{menu.name}</div>
                )}
            </legend>
            <div disabled={readOnly} className='w-full' >
                {getShowSubMenu()}
            </div>
        </fieldset>
    )
}

export default Prescriptions
