import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    useGetPatientAdmitByIdQuery,
} from "../../../redux/pharmaServices/PatientAdmitTransaction";

import {
    useGetPatientDischargeQuery,
    useGetPatientDischargeByIdQuery,
    useAddPatientDischargeMutation,
    useUpdatePatientDischargeMutation,
    useDeletePatientDischargeMutation,
} from "../../../redux/pharmaServices/PatientDischargeTransaction"

import { FormHeader } from '../../../Basic/components';
import { toast } from "react-toastify"
import { DisabledInput, TextInput } from '../../../Inputs';
import Modal from "../../../UiComponents/Modal"
import { getCommonParams, getDateFromDateTime } from '../../../Utils/helper';
import SingleImageFileUploadComponent from '../../../Basic/components/SingleImageUploadComponent';
import useOutsideClick from '../../../CustomHooks/handleOutsideClick';
import PatientAdmission from '../PatientTransfer/PatientAdmission';
import SearchReport from './SearchReport';
import PatientAdmitSummary from './PatientAdmitSummary';

const MODEL = "Patient Discharge Form";

export default function Form() {
    const [printModalOpen, setPrintModalOpen] = useState(false);
    const [formReport, setFormReport] = useState(false);
    const [inPatientPopUp, setInPatientPopUp] = useState(false);

    const [patientAdmitId, setPatientAdmitId] = useState('');

    const [id, setId] = useState("")
    const [docId, setDocId] = useState("");
    const [docDate, setDocDate] = useState('');
    const [hideNavBar, sethideNavBar] = useState(true);
    const [readOnly, setReadOnly] = useState(false);
    const [name, setName] = useState("");
    const ref = useOutsideClick(() => sethideNavBar(true));
    const [regNo, setregNo] = useState("");
    const [mobile, setMobile] = useState("");
    const [image, setImage] = useState('')

    const { finYearId, branchId } = getCommonParams();

    const childRecord = useRef(0);
    const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetPatientDischargeByIdQuery(id, { skip: !id });
    const [addData] = useAddPatientDischargeMutation();
    const [updateData] = useUpdatePatientDischargeMutation();
    const [removeData] = useDeletePatientDischargeMutation();
    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            else setReadOnly(false);
            if (data?.docId) {
                setDocId(data?.docId || '');
            }
            setDocDate(getDateFromDateTime(data?.createdAt || new Date()));
            setPatientAdmitId(data?.patientAdmitId || '');
        }, [id])

    const syncFormPatientDetails = (data) => {
        // Patient Data
        setName(data?.name || '');
        setregNo(data?.regNo || '');
        setMobile(data?.mobile || '');
        setImage(data?.image || "");
        sethideNavBar(true);
    }

    useEffect(() => {
        if (id) {
            syncFormWithDb(singleData?.data);
        } else {
            syncFormWithDb(undefined);
        }
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData])

    const { data: docData } = useGetPatientDischargeQuery({ params: { finYearId, branchId, isGetNextDocId: true } });

    const { data: patientAdmitDetails } = useGetPatientAdmitByIdQuery(patientAdmitId, { skip: !patientAdmitId })

    useEffect(() => {
        if (!patientAdmitId) return
        syncFormPatientDetails(patientAdmitDetails?.data?.Patient);
    }, [patientAdmitId, patientAdmitDetails])

    const getNextDocId = useCallback(() => {
        if (id) return
        if (docData?.nextDocId) {
            setDocId(docData?.nextDocId)
        }
    }, [docData, id])

    useEffect(getNextDocId, [getNextDocId])

    const validateData = (data) => {
        if (data.patientAdmitId) {
            return true;
        }
        return false;
    }
    const data = {
        branchId, patientAdmitId, id, finYearId
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData;
            if (text === "Updated") {
                returnData = await callback({ id, body: data }).unwrap();
            } else {
                returnData = await callback(data).unwrap()
            }
            if (returnData.statusCode === 0) {
                setId("")
                syncFormWithDb(undefined)
                toast.success(text + "Successfully");
                onNew()
            } else {
                toast.error(returnData?.message)
            }
        } catch (error) {
            console.log("handle");
        }
    };


    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", { position: "top-center" })
            return
        }

        if (!window.confirm("Are you sure save the details ...?")) {
            return
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated")
        } else {
            handleSubmitCustom(addData, data, "Added")
        }
    }

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return
            }
            try {
                await removeData(id).unwrap();
                setId("");
                toast.success("Deleted Successfully");
                onNew()
            } catch (error) {
                toast.error("something went wrong")
            }
            ;
        }
    }

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === 's') {
            event.preventDefault();
            saveData();
        }
    }
    const handlePrint = () => {
        setPrintModalOpen(true);
    }

    const onNew = () => {
        syncFormWithDb(undefined);
        setId("");
        setReadOnly(false);
        syncFormPatientDetails(undefined)
    }

    return (
        <div onKeyDown={handleKeyDown} className='md:items-start md:justify-items-center grid overflow-auto bg-theme '>
            <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"w-[90%] h-[90%]"} >
                <SearchReport onClick={(id) => { setId(id); setFormReport(false) }} />
            </Modal>
            <Modal
                isOpen={inPatientPopUp}
                onClose={() => setInPatientPopUp(false)}
                widthClass={"px-2 h-[90%] w-[90%]"}
            >
                <PatientAdmission onClick={(id) => { setInPatientPopUp(false); setPatientAdmitId(id) }} />
            </Modal>
            <div className='flex flex-col frame w-full h-full'>
                <FormHeader onNew={onNew} model={MODEL} saveData={saveData} setReadOnly={setReadOnly}
                    deleteData={deleteData} childRecord={childRecord.current}
                    openReport={() => { setFormReport(true) }}
                />
                <div className='grid border overflow-auto'>
                    <div className='border-2 border-gray-200 grid grid-cols-2'>
                        <fieldset className='frame grid grid-cols-3'>
                            <legend className='sub-heading'>Discharge Details</legend>
                            <SingleImageFileUploadComponent editProfileImage={false} size="150px" disabled={readOnly} image={image} />
                            <div ref={ref} onClick={() => sethideNavBar(!hideNavBar)} className='col-span-2 flex flex-col justify-start ml-10 gap-2 mt-5 flex-1'>
                                <DisabledInput name="Doc. Id" value={docId} required={true} readOnly={readOnly} />
                                <DisabledInput name="Doc. Date" value={docDate} type={"Date"} required={true} readOnly={readOnly} />
                                <div className="flex items-center">
                                    <button
                                        className="bg-yellow-800 hover:bg-yellow-700 uppercase p-1 rounded-md w-40 text-white text-sm"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                setInPatientPopUp(true);
                                            }
                                        }}
                                        onClick={() => {
                                            if (!readOnly) {
                                                setInPatientPopUp(true);
                                            }
                                        }}
                                    >
                                        Choose InPatient
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className='frame my-1 '>
                            <legend className='sub-heading'>Patient Details</legend>
                            <div className='grid grid-cols-2 my-2'>
                                <TextInput name="Patient Admit Doc Id" type="text" value={patientAdmitDetails?.data?.docId || ''}
                                    disabled required={true} readOnly={readOnly} />
                                <TextInput name="Mobile No" type="number" value={mobile} disabled setValue={setMobile} required={true} readOnly={readOnly} />
                                {id ? <DisabledInput name="Patient id" type={"text"} value={regNo} /> : ""}
                                <div>
                                    <TextInput disabled name="Name" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    {patientAdmitDetails?.data &&
                        <div className='border-2 border-gray-200 flex flex-col'>
                            <fieldset className='frame my-1 m-2'>
                                <legend className='sub-heading'>Admission Summary</legend>
                                <div className='grid my-2'>
                                    <PatientAdmitSummary patientAdmitData={patientAdmitDetails?.data || {}} />
                                </div>
                            </fieldset>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
