import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useGetPatientByIdQuery } from '../../../redux/pharmaServices/PatientMaster';
import { PDFViewer } from '@react-pdf/renderer';
import tw from "../../../Utils/tailwind-react-pdf";
import {
  useGetPatientRoomTransferQuery,
  useGetPatientRoomTransferByIdQuery,
  useAddPatientRoomTransferMutation,
  useUpdatePatientRoomTransferMutation,
  useDeletePatientRoomTransferMutation,
} from "../../../redux/pharmaServices/PatientRoomTransferTransaction";
import { useGetRoomQuery } from '../../../redux/services/RoomMasterService';
import { FormHeader } from '../../../Basic/components';
import { toast } from "react-toastify"
import { DisabledInput, TextInput, TextArea } from '../../../Inputs';
import Modal from "../../../UiComponents/Modal"
import io from 'socket.io-client';
import { findFromList, getCommonParams, getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import SingleImageFileUploadComponent from '../../../Basic/components/SingleImageUploadComponent';
import LiveWebCam from '../../../Basic/components/LiveWebCam';
import useOutsideClick from '../../../CustomHooks/handleOutsideClick';
import { useDispatch } from 'react-redux';
import PatientAdmitForm from './printFormat';
import RoomTypeMasterDropdown from '../../../ReusableComponents/RoomTypeMasterDropdown';
import SearchReport from './SearchReport';
import PatientAdmission from './PatientAdmission';
import RoomSelection from '../PatientAdmissonForm/RoomSelectionTable';
import { useGetPatientAdmitByIdQuery } from '../../../redux/pharmaServices/PatientAdmitTransaction';


const MODEL = "Patient Room Transfer";
const BASE_URL = process.env.REACT_APP_SERVER_URL;

const socket = io.connect(BASE_URL);

export default function Form() {
  const [printModalOpen, setPrintModalOpen] = useState(false);
  const [patientVisitPopUp, setPatientAdmitPopUp] = useState(false);
  const [patientAdmitId, setPatientAdmitId] = useState('');

  const [id, setId] = useState("")
  const [docId, setDocId] = useState("");
  const [roomTypeId, setRoomTypeId] = useState("")
  const [docDate, setDocDate] = useState("");
  const [hideNavBar, sethideNavBar] = useState(true);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [name, setName] = useState("");
  const ref = useOutsideClick(() => sethideNavBar(true));
  const [regNo, setregNo] = useState("");
  const [mobile, setMobile] = useState("");
  const [roomId, setRoomId] = useState({});

  const [image, setImage] = useState("");
  const [reasonForAdmit, setReasonForAdmit] = useState("");
  const [doctor, setDoctor] = useState("");
  const [formReport, setFormReport] = useState(false);

  const { finYearId, branchId, companyId } = getCommonParams();
  const dispatch = useDispatch()


  const params = { companyId, finYearId, branchId, }

  const childRecord = useRef(0);

  const { data: roomList } = useGetRoomQuery({ params: { ...params, active: true, roomType: roomTypeId, roomAllocation: true, } });
  const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetPatientRoomTransferByIdQuery(id, { skip: !id });

  const { data: patientData, isLoading: isPatientLoading, isFetching: isPatientFetching } = useGetPatientByIdQuery(patientId, { skip: id && !patientId });

  const [addData] = useAddPatientRoomTransferMutation();
  const [updateData] = useUpdatePatientRoomTransferMutation();
  const [removeData] = useDeletePatientRoomTransferMutation();
  const syncFormWithDb = useCallback(
    (data) => {
      if (id) setReadOnly(true);
      else setReadOnly(false);
      if (data?.docId) {
        setDocId(data?.docId);
      }
      setRoomId(data?.roomId ? data?.roomId : "")
      setRoomTypeId(data?.roomTypeId ? data?.roomTypeId : "")
      setPatientAdmitId(data?.patientAdmitId || '');
      setDocDate(getDateFromDateTimeToDisplay(data?.createdAt || new Date()));
      setReasonForAdmit(data?.reasonForAdmit ? data.reasonForAdmit : "");
      setDoctor(data?.employeeId ? data.employeeId : "");
      setPatientId(data?.patientId ? data.patientId : "");
      setName(data?.Patient?.name ? data?.Patient?.name : "");
      setregNo(data?.Patient?.regNo ? data?.Patient?.regNo : "");
      setMobile(data?.Patient?.mobile ? data?.Patient.mobile : "");
      setImage(data?.Patient?.image || "");
    }, [id])


  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData])

  const { data: docData } = useGetPatientRoomTransferQuery({ params: { finYearId, branchId, isGetNextDocId: true } });

  const getNextDocId = useCallback(() => {
    if (id) return
    if (docData?.nextDocId) {
      setDocId(docData?.nextDocId)
    }
  }, [docData, id])

  useEffect(getNextDocId, [getNextDocId])


  const syncFormPatientDetails = (data) => {

    // Patient Data
    setName(data?.name || '');
    setregNo(data?.regNo || '');
    setMobile(data?.mobile || '');
    setImage(data?.image || "");
    sethideNavBar(true);
  }
  useEffect(() => {
    if (patientId && patientData?.data) {
      syncFormPatientDetails(patientData?.data);
    } else {
      syncFormPatientDetails(undefined);
    }
  }, [patientId, patientData, isPatientLoading, isPatientFetching, id])


  const { data: patientAdmitDetails } = useGetPatientAdmitByIdQuery(patientAdmitId, { skip: !patientAdmitId })

  useEffect(() => {
    if (!patientAdmitDetails?.data) return
    syncFormPatientDetails(patientAdmitDetails?.data?.Patient);
  }, [patientAdmitDetails])


  const data = {
    branchId, docId,
    name, mobile,
    id, patientAdmitId,
    roomTypeId,
    roomId, finYearId
  }

  const validateData = (data) => {
    if (data.roomTypeId && data.roomId && data.name && data.mobile) {
      return true;
    }
    return false;
  }


  const handleSubmitCustom = async (callback, data, text) => {
    try {

      const formData = new FormData()
      const out = { ...data };
      let returnData;
      for (let key in out) {
        formData.append(key, out[key]);
      }
      if (image instanceof File) {
        formData.append("image", image);
      } else if (!image) {
        formData.append("isImageDeleted", true);
      }
      if (text === "Updated") {
        returnData = await callback({ id, body: formData }).unwrap();
      } else {
        returnData = await callback(formData).unwrap()
      }
      if (returnData.statusCode === 0) {
        setId("")
        syncFormWithDb(undefined)
        toast.success(text + "Successfully");
        onNew()
      } else {
        toast.error(returnData?.message)
      }
      dispatch({
        type: `roomMaster/invalidateTags`,
        payload: ['Room'],
      });
    } catch (error) {
      console.log("handle");
    }
  };


  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }

    if (!window.confirm("Are you sure save the details ...?")) {
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated")
    } else {
      handleSubmitCustom(addData, data, "Added")
    }
  }
  function onDataClick(id) {
    setId(id);
    onNew();
    setFormReport(false);
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return
      }
      try {
        await removeData(id).unwrap();
        setId("");
        toast.success("Deleted Successfully");
        onNew()
      } catch (error) {
        toast.error("something went wrong")
      }
      ;
    }

  }

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      event.preventDefault();
      saveData();
    }
  }
  const handlePrint = () => {
    setPrintModalOpen(true);
  }

  const onNew = () => {
    syncFormWithDb(undefined);
    setId("");
  }

  useEffect(() => {
    socket.on('connect', () => {
      console.log("connected")
    });

    socket.on('disconnect', () => {
      console.log("disconnect")
    });
    return () => {
      socket.off('connect');
      socket.off('disconnect');
    }
  }, [])
  return (
    <div onKeyDown={handleKeyDown} className='md:items-start md:justify-items-center grid overflow-auto bg-theme '>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"w-[90%] h-[90%]"} >
        <SearchReport onClick={(id) => { setId(id); setFormReport(false) }} />
      </Modal>
      <Modal isOpen={printModalOpen} onClose={() => setPrintModalOpen(false)} widthClass={"w-[90%] h-[90%]"} >
        <PDFViewer style={tw("w-full h-full")}>
          <PatientAdmitForm data={singleData?.data ? singleData.data : undefined} />
        </PDFViewer>
      </Modal>
      <Modal isOpen={cameraOpen} onClose={() => setCameraOpen(false)}>
        <LiveWebCam picture={image} setPicture={setImage} onClose={() => setCameraOpen(false)} />
      </Modal>
      <Modal
        isOpen={patientVisitPopUp}
        onClose={() => setPatientAdmitPopUp(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}
      >
        <PatientAdmission onClick={(id) => { setPatientAdmitId(id); setPatientAdmitPopUp(false) }} />
      </Modal>
      <div className='flex flex-col frame w-full h-full'>
        <FormHeader onNew={onNew} model={MODEL} saveData={saveData} setReadOnly={setReadOnly} onClick={onDataClick}
          deleteData={deleteData} childRecord={childRecord.current} onPrint={id ? handlePrint : null}
          openReport={() => { setFormReport(true) }}
        />
        <div className='flex-1 grid'>
          <div className='col-span-3 grid  border'>
            <fieldset className='frame m-2 flex-1 flex'>
              <legend className='sub-heading'>IP Details</legend>
              <SingleImageFileUploadComponent size="150px" setWebCam={setCameraOpen} disabled={readOnly} image={image} setImage={setImage} editProfileImage={false} />
              <div ref={ref} onClick={() => sethideNavBar(!hideNavBar)} >
                {(!readOnly) &&
                  <button className='bg-sky-800 text-white text-xs p-1' onKeyDown={(e) => { if (e.key === 'Enter') { setPatientAdmitPopUp(true) } }} onClick={() => { setPatientAdmitPopUp(true) }}>Choose IP </button>
                }
                <div className='grid grid-cols-5 py-8 gap-5 w-full p-4 border rounded'>
                  <DisabledInput name="Patient id" type={"text"} value={regNo} readOnly={true} />
                  <DisabledInput name="Doc Id" type={"text"} value={docId} className='w-full' />
                  <DisabledInput name="Doc Date" type={"text"} value={docDate} />
                  <TextInput name="Patient Admit Doc Id" type="text" value={patientAdmitDetails?.data?.docId || ''}
                    disabled required={true} readOnly={readOnly} />
                  <TextInput name="Name" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} disabled />
                  <TextInput name="Mobile No" type="number" value={mobile} setValue={setMobile} required={true} readOnly={readOnly} disabled />
                </div>

                <div className='px-3 w-[40%]'>      <TextArea name="Reason For Admit" type="text" value={reasonForAdmit} setValue={setReasonForAdmit} readOnly={readOnly} disabled /></div>
              </div>
            </fieldset>
            <div className='border-2 border-gray-200 flex flex-col'>
              <fieldset className='frame my-1 m-2'>
                <legend className='sub-heading'>Admission</legend>
                <div className='grid grid-cols-4 my-2 '>
                  {/* <TextArea rows={1} name="Reason For Admit" type="text" value={reasonForAdmit} setValue={setReasonForAdmit} disabled /> */}
                  <RoomTypeMasterDropdown readOnly={readOnly} name={"Room Type"}
                    selected={roomTypeId} setSelected={(value) => { setRoomTypeId(value); setRoomId("") }} />
                  <DisabledInput readOnly={readOnly} name={"Room"} value={findFromList(roomId, roomList?.data || [], "name")} />
                </div>
              </fieldset>
              {roomTypeId &&
                <fieldset className='frame my-1 m-2'>
                  <legend className='sub-heading '>Room Details</legend>
                  <RoomSelection
                    id={id}
                    selectedRoom={roomId}
                    setSelectedRoom={setRoomId}
                    rooms={(roomList?.data || []).filter(i => i.roomTypeId == roomTypeId)}
                    readOnly={readOnly} />
                </fieldset>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
