import React from 'react';
import { DropdownWithSearch } from '../Inputs';

const MedicineTimingDropdown = ({ value, setValue, readOnly }) => {
    const medicineList = [
        { name: "Before Food", value: "Before Food" },
        { name: "After Food", value: "After Food" },
        { name: "With Food", value: "With Food" },
    ];
    return (
        <div>
            <DropdownWithSearch readOnly={readOnly} options={medicineList} value={value} setValue={(value) => setValue(value)} labelField='name' valueField='value' />
        </div>
    )
}

export default MedicineTimingDropdown
