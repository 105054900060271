import React, { useEffect, useState, useRef, useCallback } from 'react';
import FormHeader from '../../../Basic/components/FormHeader';
import { toast } from "react-toastify"
import { DisabledInput } from "../../../Inputs"
import ReportTemplate from '../../../Basic/components/ReportTemplate';
import {
  useGetExpiredStockReturnQuery,
  useGetExpiredStockReturnByIdQuery,
  useAddExpiredStockReturnMutation,
  useUpdateExpiredStockReturnMutation,
  useDeleteExpiredStockReturnMutation,
} from '../../../redux/PharmacyServices/ExpiredStockReturnService'

import { getCommonParams, getDateFromDateTime } from '../../../Utils/helper';
import PoBillItems from './PoBillItems';
import Modal from "../../../UiComponents/Modal";
import PurchaseBillFormReport from './PurchaseBillFormReport';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import LocationDropdown from '../../../ReusableComponents/LocationDropdown';
import StoreDropdown from '../../../ReusableComponents/StoreDropdown';

import useSetDefaultLocationStore from '../../../CustomHooks/useSetDefaultLocationStore';
import { PDFViewer } from '@react-pdf/renderer';
import tw from "../../../Utils/tailwind-react-pdf";
import PrintFormatSalesBill from './PrintFormatSalesBill';
import PartyDropdown from '../../../ReusableComponents/PartyDropdown';

const MODEL = "Expired Stock Return";

export default function ExpiredStockReturnEntry() {
  const today = new Date()
  const [form, setForm] = useState(true);
  const [date, setDate] = useState(getDateFromDateTime(today));
  const [docId, setDocId] = useState("");
  const [formReport, setFormReport] = useState(false)

  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [storeId, setStoreId] = useState("")
  const [supplierId, setSupplierId] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [expiredStockReturnItems, setExpiredStockReturnItems] = useState([])
  const childRecord = useRef(0);
  const [refetchStoreId, setRefetchStoreId] = useState(false);

  const [printModalOpen, setPrintModalOpen] = useState(false);

  const { branchId, finYearId } = getCommonParams()


  const dispatch = useDispatch()


  const { data: allData, isLoading, isFetching } = useGetExpiredStockReturnQuery({ params: { branchId, finYearId }, searchParams: searchValue });
  const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetExpiredStockReturnByIdQuery(id, { skip: !id });

  const [addData] = useAddExpiredStockReturnMutation();
  const [updateData] = useUpdateExpiredStockReturnMutation();
  const [removeData] = useDeleteExpiredStockReturnMutation();

  const getNextDocId = useCallback(() => {

    if (id || isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const syncFormWithDb = useCallback(
    (data) => {
      if (id) setReadOnly(true);
      else setReadOnly(false);
      if (data?.docId) {
        setDocId(data.docId);
      }
      if (data?.createdAt) setDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
      setExpiredStockReturnItems(data?.expiredStockReturnItems || []);
      setStoreId(data?.storeId ? data.storeId : "")
      setLocationId(data?.Store?.locationId ? data?.Store?.locationId : "")
      setSupplierId(data?.supplierId || '');
      setRefetchStoreId(true)
      childRecord.current = data?.childRecord ? data?.childRecord : 0;
    }, [id])


  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData])

  useSetDefaultLocationStore({ setLocationId, setStoreId, refetchStoreId, setRefetchStoreId, id })


  const data = {
    id,
    branchId,
    storeId,
    expiredStockReturnItems: expiredStockReturnItems.filter(item => item?.productId),
    finYearId,
    supplierId
  }
  const validateData = (data) => {
    return data?.supplierId && data?.storeId && data?.expiredStockReturnItems && data?.expiredStockReturnItems.length > 0
  }


  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData = await callback(data).unwrap();
      if (returnData.statusCode === 0) {
        setId("")
        syncFormWithDb(undefined)
        toast.success(text + "Successfully");
      } else {
        toast.error(returnData?.message)
      }
      dispatch({
        type: `stock/invalidateTags`,
        payload: ['Stock'],
      });
      dispatch({
        type: `patientVisit/invalidateTags`,
        payload: ['PatientVisit'],
      });
    } catch (error) {
      console.log(error)
    }

  }
  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (!window.confirm("Are you sure save the details ...?")) {
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated")
    } else {
      handleSubmitCustom(addData, data, "Added")
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return
      }
      try {
        await removeData(id).unwrap();
        setId("");
        toast.success("Deleted Successfully");
        dispatch({
          type: `patientVisit/invalidateTags`,
          payload: ['PatientVisit'],
        });
      } catch (error) {
        toast.error("something went wrong")
      }
      ;
    }
  }

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      event.preventDefault();
      saveData();
    }
  }

  const onNew = () => {
    setSearchValue("")
    syncFormWithDb(undefined);
    setId("");
    getNextDocId();
  }

  function onDataClick(id) {
    setId(id);
    onNew();
    setForm(true);
  }

  const handlePrint = () => {
    setPrintModalOpen(true);
  }

  const tableHeaders = [
    "Code", "Name", "Status"
  ]
  const tableDataNames = ["dataObj.code", "dataObj.name", 'dataObj.active ? ACTIVE : INACTIVE']

  if (!form)
    return <ReportTemplate
      heading={MODEL}
      tableHeaders={tableHeaders}
      tableDataNames={tableDataNames}
      loading={
        isLoading || isFetching
      }
      setForm={setForm}
      data={allData?.data ? allData?.data : []}
      onClick={onDataClick}
      onNew={onNew}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />

  return (


    <div onKeyDown={handleKeyDown} className='md:items-start md:justify-items-center grid h-full bg-theme'>
      <Modal isOpen={printModalOpen} onClose={() => setPrintModalOpen(false)} widthClass={"w-[90%] h-[90%]"} >
        <PDFViewer style={tw("w-full h-full")}>
          <PrintFormatSalesBill data={singleData?.data ? singleData.data : undefined} />
        </PDFViewer>
      </Modal>
      <Modal

        isOpen={formReport}
        onClose={() => setFormReport(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}

      >
        <PurchaseBillFormReport onClick={(id) => { setId(id); setFormReport(false) }} />
      </Modal>
      <div className='flex flex-col frame w-full h-full'>
        <FormHeader
          onNew={onNew}
          model={MODEL}
          openReport={() => setFormReport(true)}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          onPrint={id ? handlePrint : null}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <div className='flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip'>
          <div className='col-span-4 grid md:grid-cols-1 border overflow-auto'>
            <div className='mr-1 md:ml-2'>
              <fieldset className='frame my-1 p-2'>
                <legend className='sub-heading'>Product Info</legend>
                <div className='grid grid-cols-4 my-2 gap-6'>
                  <div className='col-span-1 flex gap-8 w-full p-1'> <div className='w-[60%] '><DisabledInput name="Doc.No" value={docId} required={true} readOnly={readOnly} /></div>
                    <div className='w-[35%] '> <DisabledInput name="Doc.  Date" value={date} type={"Date"} required={true} readOnly={readOnly} /></div>
                  </div>
                  <LocationDropdown selected={locationId} setSelected={(value) => { setLocationId(value); setStoreId("") }} name={"Location"} multiSelect={false} withoutLabel={false} readOnly={readOnly} />
                  <StoreDropdown selected={storeId} setSelected={setStoreId} name={"Store"} multiSelect={false} withoutLabel={false} readOnly={!locationId || readOnly} />
                  <PartyDropdown selected={supplierId} setSelected={setSupplierId} name={"Supplier"} multiSelect={false} withoutLabel={false} readOnly={readOnly} />
                </div>
              </fieldset>
              <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-1 w-full border border-gray-400 md:pb-5 flex flex-1 overflow-auto h-[400px]'>
                <legend className='sub-heading'>Expired Stock Return Details</legend>
                <PoBillItems storeId={storeId} key={expiredStockReturnItems} date={singleData?.data?.createdAt} id={id} readOnly={readOnly} poBillItems={expiredStockReturnItems} setPoBillItems={setExpiredStockReturnItems} />
              </fieldset>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
