import React, { useEffect, useState } from 'react';
import { useGetStockQuery } from '../../../redux/PharmacyServices/StockService';
import { toast } from 'react-toastify';
import { getCommonParams } from '../../../Utils/helper';
import StockItemDropdown from '../../../ReusableComponents/StockItemDropdown';

const SalesPrice = ({ productId, id, date, poBillItems, setPoBillItems, index, readOnly, storeId, item }) => {
    const { branchId } = getCommonParams()
    const { data: singleProduct } = useGetStockQuery({
        params: {
            isStockDelivery: true,
            productId,
            storeId,
            branchId,
            createdAt: id ? date : undefined,
            stockDeliveryDetailsId: item?.id,
        }
    }, { skip: !(productId) });

    const [salePrice, setSalePrice] = useState([]);
    useEffect(() => {
        if (singleProduct && singleProduct?.data) {
            const salesPrices = (singleProduct?.data || [])
            setSalePrice(salesPrices);
        }
    }, [singleProduct]);

    let stockQty = salePrice.find(i =>
        i.stockItem === poBillItems[index]?.stockItem
    )?.stockQty
    stockQty = stockQty ? stockQty : 0
    let isShow = poBillItems[index]?.productId;

    function handleInputChange(value, index, field, saleAmount, qty) {

        setPoBillItems(poBillItems => {
            const newBlend = structuredClone(poBillItems);

            if (field === "stockItem") {
                newBlend[index]['salePrice'] = salePrice.find(i => i.stockItem === value)?.salePrice
                newBlend[index]['binId'] = salePrice.find(i => i.stockItem === value)?.binId
                newBlend[index]['batchNo'] = salePrice.find(i => i.stockItem === value)?.batchNo
                newBlend[index]['expiryDate'] = salePrice.find(i => i.stockItem === value)?.expiryDate
                newBlend[index]['purchasePrice'] = salePrice.find(i => i.stockItem === value)?.purchasePrice;
                newBlend[index]['supplierId'] = salePrice.find(i => i.stockItem === value)?.supplierId;
                // newBlend[index]['pcsQty'] = 1;
            }

            if (field === "discount") {
                let discountamount = findDiscount(qty, saleAmount, value);
                newBlend[index][field] = value;
                newBlend[index]["discountInAmount"] = discountamount;
                return newBlend
            }
            else {
                newBlend[index][field] = value;
            }


            if ((field === 'pcsQty') && parseFloat(stockQty) < parseFloat(newBlend[index]['pcsQty'])) {
                toast.info("Sales Qty Can not be more than Stock Qty", { position: 'top-center' })
                return poBillItems
            }
            return newBlend
        })
    };

    useEffect(() => {
        if (!(salePrice?.length === 1)) return
        setPoBillItems(prev => {
            const newBlend = structuredClone(prev);
            newBlend[index]['stockItem'] = salePrice[0].stockItem
            newBlend[index]['salePrice'] = salePrice[0].salePrice
            newBlend[index]['binId'] = salePrice[0]?.binId
            newBlend[index]['batchNo'] = salePrice[0]?.batchNo
            newBlend[index]['expiryDate'] = salePrice[0]?.expiryDate
            newBlend[index]['purchasePrice'] = salePrice[0]?.purchasePrice;
            newBlend[index]['supplierId'] = salePrice[0]?.supplierId;
            // newBlend[index]['pcsQty'] = 1;
            return newBlend
        })
    }, [salePrice, setPoBillItems, index]);
    const discountValue = ((item?.discount || 0) * (item?.salePrice || 0) / 100);
    const rate = ((item['salePrice'] || 0) - discountValue);

    function findDiscount(qty, price, dicount) {
        if (!qty || !price) return 0
        return parseFloat((parseFloat(qty) * parseFloat(price)) * (parseFloat(dicount || 0) / 100)).toFixed(2)
    }


    function findExtraDiscount(qty, price, discount, discountInAmount) {

        if (!discountInAmount) return 0

        let discountAmount = findDiscount(qty, price, discount);
        let amount = 0;
        if (parseFloat(discountInAmount) === parseFloat(discountAmount)) {
            amount = parseFloat(0)
        }
        else {
            amount = parseFloat(discountInAmount).toFixed(2)
        }
        return amount
    }

    return (
        <>
            <td className='table-data'>
                {isShow &&
                    <StockItemDropdown readOnly={readOnly} index={index} options={salePrice} value={item?.stockItem} setValue={(value) => { handleInputChange(value, index, "stockItem") }} />
                }
            </td>
            <td className='table-data text-right'>
                {parseFloat(item?.salePrice || 0).toFixed(2)}
            </td>
            <td className='table-data text-right'>
                {(isShow) &&
                    <>
                        {stockQty}
                    </>
                }
            </td >
            <td className='table-data text-right'>
                {parseFloat(item?.salePrice || 0).toFixed(2)}
            </td>
            <td className={`table-data `}>
                <input
                    min={0}
                    type="number"
                    className={`rounded py-1 px-1 w-full table-data-input bg-inherit text-right  ${(stockQty < item.pcsQty) ? "border border-red-600" : ""}`}
                    value={item?.pcsQty ? item?.pcsQty : 0}
                    disabled={readOnly}
                    onChange={(e) =>
                        handleInputChange(e.target.value, index, "pcsQty")
                    }
                    onBlur={(e) => {
                        handleInputChange(parseFloat(e.target.value).toFixed(2), index, "pcsQty");
                    }
                    }
                />
            </td>
            <td className='table-data text-right'>
                {parseFloat((parseFloat(item?.pcsQty || 0) * parseFloat(item?.salePrice || 0)) - (parseFloat(findDiscount(item?.pcsQty, item?.salePrice, item?.discount)))).toFixed(2)}
            </td>
        </>
    );
}

export default SalesPrice;
