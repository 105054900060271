
import React, { useEffect } from 'react';
import { Document, Page, View, StyleSheet, Text } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import Header from './Header';
import ToField from './ToField';
import Grid from './Grid';
import TaxGrid from './TaxGrid';
import { useGetUserByIdQuery, useGetUserQuery } from '../../../redux/services/UsersMasterService';
import JsBarcode from 'jsbarcode';
import WaterMarkSymbol from '../../../Utils/WaterMarkSymbol';


const PrintFormatDoctorConsult = ({ age, data, userId, userData, barCode, setBarCode }) => {

    console.log(data, "data")

    const styles = StyleSheet.create({
        page: {
            fontFamily: 'Helvetica',
            fontSize: 12,
            border: '2px solid black',
            padding: 20,
        },
        header: {
            fontSize: 9,
            marginBottom: 10,
            textAlign: 'center',
        },
        section: {
            margin: 10,
            padding: 10,
        },
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
            borderColor: "black"
        },
        tableRow: {
            flexDirection: "row",
            minHeight: '20px'
        },
        tableCol: {
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: 'black',
            borderBottomWidth: 0,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCell: {
            paddingRight: "1px",
            marginLeft: 3,
            fontSize: 8,
            paddingVertical: 1,
        }
    });

    useEffect(() => {

        let regNumb = data?.Patient?.regNo
        const canvas = document.createElement('canvas');
        JsBarcode(canvas, regNumb);
        setBarCode(canvas.toDataURL());

    }, [data])

    return (
        <Document style={tw("w-[400px]")}  >
            <Page size={{ width: 595.28, height: 595.28 }} style={tw("relative w-full border border-black p-1 h-full ")} wrap >
                <WaterMarkSymbol />
                <View style={tw("absolute top-1 bottom-1 left-1 right-1 border border-black")} fixed />
                <View style={tw("bg-blue-200")} fixed>
                    <Header data={data} />

                </View>
                <Grid data={data} barCodeGen={barCode} age={age} />
                <TaxGrid data={data} />
                <Text style={tw("absolute bottom-0 text-center w-full text-xs pb-2")} render={({ pageNumber, totalPages }) => (
                    `Page ${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    );
}

export default PrintFormatDoctorConsult;
