import React, { useEffect, useMemo } from 'react'
import ReceptionDashboard from './ReceptionDashboard'

import { useGetPatientVisitQuery } from '../../../redux/pharmaServices/PatientVisitTransaction';
import TemperatureTable from './VisitorsReport';
import { useState } from 'react';
import moment from 'moment';

const Dashboard = () => {
    const [filterDate, setFilterDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [paymentFilter, setPaymentFilter] = useState("All");
    const [consultFilter, setConsultFilter] = useState("All");
    const [searchToken, setSearchToken] = useState('');
    const [patientGender, setPatientGender] = useState('');
    const [searchPatientRegNo, setSearchPatientRegNo] = useState('');
    const [searchPatientName, setSearchPatientName] = useState('')
    const [searchPatientVisitDocId, setSearchPatientVisitDocId] = useState('')
    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    let isPaymentDone = useMemo(() => {
        let isPaymentDone;
        if (paymentFilter === "Done") {
            isPaymentDone = true
        } else if (paymentFilter === "Pending") {
            isPaymentDone = false
        }
        return isPaymentDone
    }, [paymentFilter]);
    let consulted = useMemo(() => {
        let consulted;
        if (consultFilter === "Consulted") {
            consulted = true
        } else if (consultFilter === "Waiting") {
            consulted = false
        }
        return consulted
    }, [consultFilter]);

    const { data: patientList } = useGetPatientVisitQuery({ params: { date: filterDate, isPaymentDone, consulted, searchToken, patientGender, searchPatientRegNo, searchPatientName, pagination: true, dataPerPage, pageNumber: currentPageNumber + 1, searchDocId: searchPatientVisitDocId } });

    const { data: overAllDataList } = useGetPatientVisitQuery({ params: { date: filterDate } });


    useEffect(() => { setCurrentPageNumber(0) }, [dataPerPage, filterDate, isPaymentDone, consulted, searchToken, patientGender, searchPatientRegNo, searchPatientName, searchPatientVisitDocId])

    useEffect(() => {
        if (patientList?.totalCount) {
            setTotalCount(patientList?.totalCount);
        }
    }, [patientList])
    return (
        <div className='flex flex-col bg-gray-200 px-3'>
            <div className='my-3'><ReceptionDashboard patientList={overAllDataList} filterDate={filterDate} setFilterDate={setFilterDate} /></div>
            <div><TemperatureTable searchPatientVisitDocId={searchPatientVisitDocId} setSearchPatientVisitDocId={setSearchPatientVisitDocId} patientList={patientList} paymentFilter={paymentFilter} setPaymentFilter={setPaymentFilter} consultFilter={consultFilter} setConsultFilter={setConsultFilter} searchToken={searchToken} setSearchToken={setSearchToken} patientGender={patientGender} setPatientGender={setPatientGender} searchPatientRegNo={searchPatientRegNo} setSearchPatientRegNo={setSearchPatientRegNo} searchPatientName={searchPatientName} setSearchPatientName={setSearchPatientName}
                totalCount={totalCount} currentPageNumber={currentPageNumber} setCurrentPageNumber={setCurrentPageNumber} dataPerPage={dataPerPage} setDataPerPage={setDataPerPage} filterDate={filterDate} setFilterDate={setFilterDate} /></div>
        </div>
    )
}
export default Dashboard
