import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
    useGetRackMasterByIdQuery,
    useAddRackMasterMutation,
    useUpdateRackMasterMutation,
    useDeleteRackMasterMutation,
} from "../../../redux/PharmacyServices/RackMasterServices";
import {
    useGetBranchQuery,
} from '../../../redux/services/BranchMasterService';
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { TextInput, CheckBox, DropdownInput } from "../../../Inputs";
import { dropDownListObject } from "../../../Utils/contructObject";
import { Loader } from "../../../Basic/components";
import Report from "./Report";
import FormReport from "./FormReport";
import StoreDropdown from "../../../ReusableComponents/StoreDropdown";

const MODEL = "Rack Master";

export default function Form() {
    const [form, setForm] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [locationId, setLocationId] = useState("");
    const [storeId, setStoreId] = useState("");
    const [active, setActive] = useState(true);

    const [searchValue, setSearchValue] = useState("");
    const childRecord = useRef(0);

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };


    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetRackMasterByIdQuery(id, { skip: !id });

    const { data: branchList, isLoading: isBranchLoading, isFetching: isBranchFetching } = useGetBranchQuery({ params });

    const [addData] = useAddRackMasterMutation();
    const [updateData] = useUpdateRackMasterMutation();
    const [removeData] = useDeleteRackMasterMutation();

    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            setName(data?.name ? data.name : "");
            setStoreId(data?.storeId ? data.storeId : "");
            setLocationId(data?.Store?.locationId ? data?.Store?.locationId : "");
            setActive(id ? (data?.active ? data.active : false) : true);
        },
        [id]
    );

    useEffect(() => {
        if (id) {
            syncFormWithDb(singleData?.data);
        } else {
            syncFormWithDb(undefined);
        }
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        id, name,
        storeId, active
    }

    const validateData = (data) => {
        if (data.name && data.storeId) {
            return true;
        }
        return false;
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            await callback(data)
            toast.success(text + "Successfully");
            setId("")
            syncFormWithDb(undefined)
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        syncFormWithDb(undefined)
        setReadOnly(false);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }

    if (!form)
        return (
            <Report searchValue={searchValue} setSearchValue={setSearchValue} onNew={onNew} onClick={onDataClick} />
        );

    if (isBranchFetching || isBranchLoading) return <Loader />
    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}
                />
                <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
                    <div className="col-span-3 grid md:grid-cols-2 border overflow-auto">
                        <div className='col-span-3 grid md:grid-cols-2 border overflow-auto'>
                            <div className='mr-1 md:ml-2'>
                                <fieldset className='frame my-1'>
                                    <legend className='sub-heading'>Location Info</legend>
                                    <div className='grid grid-cols-1 my-2'>
                                        <DropdownInput
                                            name="Location"
                                            options={dropDownListObject(id ? branchList.data : branchList.data.filter(item => item.active), "branchName", "id")}
                                            value={locationId}
                                            setValue={setLocationId}
                                            required={true}
                                            readOnly={readOnly}
                                            disabled={childRecord.current > 0}
                                        />
                                        <StoreDropdown selected={storeId} setSelected={setStoreId} name={"Store"} multiSelect={false} withoutLabel={false} locationId={locationId} readOnly={(!locationId) || readOnly} />
                                        <TextInput name="Rack" type="text" value={name} setValue={setName} readOnly={readOnly}
                                            disabled={childRecord.current > 0}
                                        />
                                        <CheckBox name="Active" value={active} setValue={setActive} readOnly={readOnly} />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="frame hidden md:block overflow-x-hidden">
                        <FormReport searchValue={searchValue} setSearchValue={setSearchValue} onNew={onNew} onClick={onDataClick} />
                    </div>
                </div>
            </div>
        </div>
    );
}
