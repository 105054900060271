import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment/moment';
import Prescriptions from './Prescriptions';
import { useGetPatientVisitByIdQuery } from '../../../redux/pharmaServices/PatientVisitTransaction';
import { DisabledInput } from '../../../Inputs';

const PatientHistoryDetail = ({ patientVisitId }) => {
    const [comments, setComments] = useState("");
    const [medicines, setMedicines] = useState([]);
    const [prescribedLabTests, setPrescribedLabTests] = useState([]);

    const [reasonForVisit, setReasonForVisit] = useState("");
    const [bp, setBp] = useState("");
    const [sugar, setSugar] = useState("");
    const [pulse, setPulse] = useState("");
    const [temperature, setTemperature] = useState("");
    const [weight, setWeight] = useState("");
    const [date, setDate] = useState("");

    const syncFormWithDb = useCallback((data) => {
        if (!data) return
        setDate(moment.utc(data.date).format("DD-MM-YYYY"));
        setBp(data.bp);
        setSugar(data.sugar);
        setPulse(data.pulse);
        setTemperature(data.temperature);
        setWeight(data.weight ? data.weight : "");
        setReasonForVisit(data.reasonForVisit);
        // Patient Data
        setMedicines(data.Prescriptions);
        setComments(data.comments ? data.comments : "");
    }, [])
    const { data: singleData, isLoading: isSingleLoading, isFetching: isSingleFetching } = useGetPatientVisitByIdQuery(patientVisitId, { skip: !patientVisitId })
    useEffect(() => {
        if (patientVisitId) {
            syncFormWithDb(singleData?.data);
        } else {
            syncFormWithDb(undefined);
        }
    }, [isSingleFetching, isSingleLoading, patientVisitId, syncFormWithDb, singleData])
    return (
        <div className='flex flex-col h-5/6 w-full col-span-2'>
            <div className='text-center bg-gray-200 text-blue-900'>Health Details</div>
            <div className='grid grid-cols-2'>
                <div className='flex flex-col gap-1'>
                    <DisabledInput name="Date" type="text" value={date} />
                    <DisabledInput name="Blood Pressure" type="text" value={bp} />
                    <DisabledInput name="Sugar Level" type="text" value={sugar} />
                    <DisabledInput name="Pulse" type="text" value={pulse} />
                </div>
                <div className='flex flex-col gap-1'>
                    <DisabledInput name="Temperature" type="text" value={temperature} />
                    <DisabledInput name="Weight" type="number" value={weight} />
                    <DisabledInput name="Reason For Visit" type="text" value={reasonForVisit} />
                </div>
            </div>
            <div className='text-center bg-gray-200 text-blue-900'>Prescription Details</div>
            <div className='row-span-2 h-full overflow-auto'>
                <div className='flex flex-col'>
                    <div className='flex justify-between items-center p-2 m-1'>
                        <label htmlFor="comments">Comments</label>
                        <textarea disabled name="comments" className='w-full input-field focus:outline-none  border rounded' cols="30" rows="2" value={comments} onChange={(e) => { setComments(e.target.value) }} ></textarea>
                    </div>
                </div>
                <div className="mt-1 container mx-auto flex items-center gap-2">
                    <Prescriptions readOnly={true} medicines={medicines} setMedicines={setMedicines} prescribedLabTests={prescribedLabTests} setPrescribedLabTests={setPrescribedLabTests} />
                </div>
            </div>
        </div>
    )
}

export default PatientHistoryDetail
