import { configureStore } from "@reduxjs/toolkit";
import { openTabs } from "./features";
import {
  countryMasterApi, pageMasterApi, stateMasterApi,
  cityMasterApi, departmentMasterApi, employeeCategoryMasterApi,
  finYearMasterApi, rolesMasterApi, employeeMasterApi, userMasterApi, branchMasterApi, companyMasterApi, pageGroupApi, roomMasterApi, roomTypeMasterApi,
} from "./services"

import { LabTestTypesApi, patientMasterApi, patientVisitApi, patientAdmitApi, patientDischargeApi, patientRoomTransferApi } from "./pharmaServices";
import LabTestMasterApi from "./pharmaServices/LabTestMaster";
import { BarcodeMasterApi, BinMasterApi, ExpiredStockReturnApi, feesTypeMasterApi, HsnApi, LocationMasterApi, openingStockApi, partyCategoryMasterApi, partyMasterApi, PaymentApi, productBrandMasterApi, productCategoryMasterApi, productMasterApi, purchaseBillApi, purchaseReturnApi, rackMasterApi, salesBillApi, salesReturnApi, stockApi, StockDeliveryApi, StockReceiptApi, StockTransfer, uomMasterApi } from "./PharmacyServices";


const commonReducers = {
  openTabs,
  countryMaster: countryMasterApi.reducer,
  pageMaster: pageMasterApi.reducer,
  [pageGroupApi.reducerPath]: pageGroupApi.reducer,
  stateMaster: stateMasterApi.reducer,
  cityMaster: cityMasterApi.reducer,
  departmentMaster: departmentMasterApi.reducer,
  employeeCategoryMaster: employeeCategoryMasterApi.reducer,
  finYearMaster: finYearMasterApi.reducer,
  roleMaster: rolesMasterApi.reducer,
  userMaster: userMasterApi.reducer,
  employeeMaster: employeeMasterApi.reducer,
  branchMaster: branchMasterApi.reducer,
  companyMaster: companyMasterApi.reducer,
  roomMaster: roomMasterApi.reducer,
  roomTypeMaster: roomTypeMasterApi.reducer

}
const commonMiddleware = [countryMasterApi.middleware,
pageMasterApi.middleware,
pageGroupApi.middleware,
stateMasterApi.middleware,
cityMasterApi.middleware,
departmentMasterApi.middleware,
employeeCategoryMasterApi.middleware,
finYearMasterApi.middleware,
rolesMasterApi.middleware,
userMasterApi.middleware,
employeeMasterApi.middleware,
branchMasterApi.middleware,
companyMasterApi.middleware,
roomMasterApi.middleware,
roomTypeMasterApi.middleware

];


const pharmaReducers = {
  patientMaster: patientMasterApi.reducer,
  patientVisit: patientVisitApi.reducer,
  patientAdmit: patientAdmitApi.reducer,
  [LabTestTypesApi.reducerPath]: LabTestTypesApi.reducer,
  [LabTestMasterApi.reducerPath]: LabTestMasterApi.reducer,
  productBrandMaster: productBrandMasterApi.reducer,
  productCategoryMaster: productCategoryMasterApi.reducer,
  productMaster: productMasterApi.reducer,
  partyMaster: partyMasterApi.reducer,
  partyCategoryMaster: partyCategoryMasterApi.reducer,
  purchaseBill: purchaseBillApi.reducer,
  stock: stockApi.reducer,
  salesBill: salesBillApi.reducer,
  purchaseReturn: purchaseReturnApi.reducer,
  salesReturn: salesReturnApi.reducer,
  uomMaster: uomMasterApi.reducer,
  [LocationMasterApi.reducerPath]: LocationMasterApi.reducer,
  [rackMasterApi.reducerPath]: rackMasterApi.reducer,
  [BinMasterApi.reducerPath]: BinMasterApi.reducer,
  [BarcodeMasterApi.reducerPath]: BarcodeMasterApi.reducer,
  [HsnApi.reducerPath]: HsnApi.reducer,
  [PaymentApi.reducerPath]: PaymentApi.reducer,
  [ExpiredStockReturnApi.reducerPath]: ExpiredStockReturnApi.reducer,
  [patientDischargeApi.reducerPath]: patientDischargeApi.reducer,
  [patientRoomTransferApi.reducerPath]: patientRoomTransferApi.reducer,
  feesTypeMaster: feesTypeMasterApi.reducer,
  [openingStockApi.reducerPath]: openingStockApi.reducer,
  [StockTransfer.reducerPath]: StockTransfer.reducer,
  [StockDeliveryApi.reducerPath]: StockDeliveryApi.reducer,
  [StockReceiptApi.reducerPath]: StockReceiptApi.reducer
}
const pharmaMiddleware = [
  patientMasterApi.middleware,
  patientVisitApi.middleware,
  patientAdmitApi.middleware,
  LabTestTypesApi.middleware,
  LabTestMasterApi.middleware,
  productBrandMasterApi.middleware,
  productCategoryMasterApi.middleware,
  productMasterApi.middleware,
  partyMasterApi.middleware,
  partyCategoryMasterApi.middleware,
  purchaseBillApi.middleware,
  stockApi.middleware,
  salesBillApi.middleware,
  purchaseReturnApi.middleware,
  salesReturnApi.middleware,
  uomMasterApi.middleware,
  LocationMasterApi.middleware,
  BinMasterApi.middleware,
  BarcodeMasterApi.middleware,
  HsnApi.middleware,
  PaymentApi.middleware,
  ExpiredStockReturnApi.middleware,
  patientDischargeApi.middleware,
  patientRoomTransferApi.middleware,
  feesTypeMasterApi.middleware,
  openingStockApi.middleware,
  StockTransfer.middleware,
  StockDeliveryApi.middleware,
  rackMasterApi.middleware,
  StockReceiptApi.middleware,
]

export const store = configureStore({
  reducer: {
    ...commonReducers, ...pharmaReducers
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(commonMiddleware).concat(pharmaMiddleware),
});


