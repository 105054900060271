import React from 'react'
import { View, Text } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import { APP_NAME } from '../../../Strings';
import numToWordConverter from "num-words"

import { numberFormatter, substract } from '../../../Utils/helper';

const TaxGrid = ({ taxDetails, data }) => {
    // const amount = taxDetails.reduce((a, c) => a + parseFloat(c.amount || 0), 0);
    function findDiscount(qty, price, dicount) {
        if (!qty || !price) return 0
        return parseFloat((parseFloat(qty) * parseFloat(price)) * (parseFloat(dicount || 0) / 100)).toFixed(2)
    }
    const amount = taxDetails.reduce((a, item, index) => a + parseFloat(
        (parseFloat(item?.pcsQty || 0) * parseFloat(item?.salePrice || 0)) - findDiscount(item?.pcsQty, item?.salePrice, item?.discount)), 0)
    const totalAmount = taxDetails.reduce((a, c) => a + parseFloat(c.amount || 0), 0);
    const finalAmount = (data?.isTaxBill ? totalAmount : amount);
    // const finalRoundedAmount = Math.round(data?.isTaxBill ? totalAmount : amount);
    const finalRoundedAmount = Math.round(amount);
    const roundOffAmount = parseFloat(Math.abs(substract(finalAmount, finalRoundedAmount)));
    return (
        <View wrap={false} style={tw(" flex items-center  justify-center w-full h-[140px]")} >
            <View style={tw("w-full h-full border-t border-b border-r border-black flex flex-row items-start")} >
                <View style={tw("w-1/2 text-xs")}>
                </View>
                <View style={tw("w-1/2 h-full border-l border-black")}>
                    <View style={tw("font-[Times-Bold] text-[12px] text-center border-b flex flex-row border-black h-20")}>
                        <Text style={tw("w-1/2 border-r border-black")}>TOTAL</Text>
                        <Text style={tw("w-1/2 text-right pr-2")}>{amount.toFixed(2)}</Text>
                    </View>
                    <View style={tw("text-[8px] text-center border-b border-black flex flex-row items-center h-20")}>
                        <Text style={tw("w-1/2 border-r border-black")}>ROUND OFF</Text>
                        <Text style={tw("w-1/2 text-right text-[10px] pr-2")}>{numberFormatter(roundOffAmount.toFixed(2))}</Text>
                    </View>
                    <View style={tw(" font-[Times-Bold] text-[16px] w-full text-center border-black min-h-[45px] flex flex-row items-center")}>
                        <View style={tw("w-1/2 h-full border-r border-black flex flex-row justify-center items-center ")}>
                            <Text >GRAND TOTAL</Text>
                        </View>
                        <View style={tw("w-1/2 border-r border-black flex flex-row justify-end pr-1 items-center")}>
                            <Text style={tw("")}>{numberFormatter(finalRoundedAmount.toFixed(2))} </Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={tw("w-full flex flex-row items-center justify-center text-[10px] border-x border-black h-[30px]")}>
                <Text style={tw("w-[20%] text-center border-r border-black h-full flex flex-row justify-center items-center")}>
                    Rupees
                </Text>
                <Text style={tw("w-[80%] capitalize flex items-center justify-center h-full pl-1")}>{numToWordConverter(finalRoundedAmount)} Only.</Text>
            </View>
            <View style={tw("w-full flex flex-row items-start font-[Times-Bold] p-1 justify underline text-[10px] border border-black h-[60px]")}>
                <View style={tw("w-1/2 h-full border-r border-black  text-center")}>
                    <Text>
                        Receiver Signature
                    </Text>
                </View>
                <View style={tw("w-1/2 capitalize  text-end flex items-end ")}>
                    <Text>
                        For {APP_NAME}
                    </Text>
                </View>
            </View>
            <View style={tw("w-full text-xs text-center h-[20px]")}>
                <Text style={tw("border-b border-black w-full")}>Items Once Sold, Will not be taken back, Don't, Medicine without doctor consult</Text>
            </View>
        </View>

    )
}

export default TaxGrid
