import React from 'react'
import { DropdownInput, MultiSelectDropdown } from '../Inputs';
import { dropDownListObject, multiSelectOption } from '../Utils/contructObject';
import { getCommonParams } from '../Utils/helper';
import { useGetRackMasterQuery } from '../redux/PharmacyServices/RackMasterServices';

const RackDropdown = ({ multiSelect = true, withoutLabel = true, readOnly, name, selected, setSelected, storeId }) => {
    const { token, ...params } = getCommonParams();
    const { data: rackList } = useGetRackMasterQuery({ params: { ...params, storeId } });
    return (
        <>
            {withoutLabel ?
                <select value={selected} onChange={(e) => setSelected(e.target.value)} className='w-full p-2 '
                    disabled={readOnly}
                >
                    <option value="">Select</option>
                    {(rackList ? rackList.data : []).map(item =>
                        <option key={item.id} value={item.id}>{item.name}</option>
                    )}
                </select>
                :
                <>
                    {multiSelect ?
                        <MultiSelectDropdown readOnly={readOnly} name={name} selected={selected} setSelected={setSelected}
                            options={multiSelectOption(rackList ? rackList.data : [], "name", "id")} />
                        :
                        <DropdownInput readOnly={readOnly} name={name} value={selected} setValue={setSelected}
                            options={dropDownListObject(rackList ? rackList.data : [], "name", "id")} clear />
                    }
                </>
            }
        </>
    )
}

export default RackDropdown
