import React from "react";

const PaymentSuccessModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
      <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-sm mx-auto z-50">
        <h2 className="text-2xl font-bold mb-4 text-center text-green-600">
          Payment Successfully!
        </h2>
        <p className="text-center mb-6">Your payment has been processed successfully.</p>
        <button
          onClick={onClose}
          className="block w-full bg-green-600 text-white py-2 rounded-lg hover:bg-green-700"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccessModal;
