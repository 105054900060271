import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";
import { EXPIRED_STOCK_RETURN_API } from "../../Api";

const ExpiredStockReturnApi = createApi({
  reducerPath: "ExpiredStockReturn",
  baseQuery,
  tagTypes: ["ExpiredStockReturn"],
  endpoints: (builder) => ({
    getExpiredStockReturn: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: EXPIRED_STOCK_RETURN_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: EXPIRED_STOCK_RETURN_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["ExpiredStockReturn"],
    }),
    getExpiredStockReturnById: builder.query({
      query: (id) => {
        return {
          url: `${EXPIRED_STOCK_RETURN_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["ExpiredStockReturn"],
    }),
    addExpiredStockReturn: builder.mutation({
      query: (payload) => ({
        url: EXPIRED_STOCK_RETURN_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["ExpiredStockReturn"],
    }),
    updateExpiredStockReturn: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${EXPIRED_STOCK_RETURN_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["ExpiredStockReturn"],
    }),
    deleteExpiredStockReturn: builder.mutation({
      query: (id) => ({
        url: `${EXPIRED_STOCK_RETURN_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ExpiredStockReturn"],
    }),
  }),
});

export const {
  useGetExpiredStockReturnQuery,
  useGetExpiredStockReturnByIdQuery,
  useAddExpiredStockReturnMutation,
  useUpdateExpiredStockReturnMutation,
  useDeleteExpiredStockReturnMutation,
} = ExpiredStockReturnApi;

export default ExpiredStockReturnApi;
