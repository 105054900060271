import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetStockQuery } from '../../../redux/PharmacyServices/StockService';

const DashboardCards = () => {
    const { data: expiredData } = useGetStockQuery({ params: { isGetStockDashboardData: true, isGetInsightsData: true, } });
    const { productInfo, todaySales, salesVisits, profit } = expiredData?.data?.insightsData || {};

    const data = [
        {
            name: "Total Products",
            value: productInfo?.totalProductsCount,
            borderColor: "#0093D4",
            left: 'Out Of Stock',
            leftValue: productInfo?.outOfStockCount,
            leftIcon: 'fa-boxes',
            leftValueColor: "text-gray-700 underline",
            right: 'Expired',
            rightValue: productInfo?.expireProductsCount,
            rightIcon: 'fa-exclamation-triangle',
            rightValueColor: "text-red-600 underline",
        },
        {
            name: "Sales Count Today",
            value: salesVisits?.todayVisits,
            borderColor: "#006FD5",
            left: 'Yesterday',
            leftValue: salesVisits?.yesterdayVisits,
            leftIcon: 'fa-calendar-day',
            center: 'This Month',
            centerValue: salesVisits?.thisMonthVisits,
            centerIcon: 'fa-calendar-alt',
            right: "Last Month",
            rightValue: salesVisits?.lastMonthVisits,
            rightIcon: 'fa-calendar-check',
        },
        {
            name: "Today Sales",
            value: parseFloat(todaySales?.totalSales || 0).toFixed(2),
            borderColor: "#386BA2",
            left: 'OTC',
            leftValue: parseFloat(todaySales?.OTCSales || 0).toFixed(2),
            leftIcon: 'fa-pills',
            center: 'Prescription Need',
            centerValue: parseFloat(todaySales?.PrescriptionSales || 0).toFixed(2),
            centerIcon: 'fa-prescription-bottle-alt',
            right: 'Narcotic',
            rightValue: parseFloat(todaySales?.NarcoticSales || 0).toFixed(2),
            rightIcon: 'fa-capsules',
        },
        {
            name: "Today Profit",
            value: parseFloat(profit?.todayProfit || 0).toFixed(2),
            borderColor: "#1F4764",
            left: 'Yesterday',
            leftValue: parseFloat(profit?.yesterdayProfit || 0).toFixed(2),
            leftIcon: 'fa-dollar-sign',
            center: 'This Month',
            centerValue: parseFloat(profit?.thisMonthProfit || 0).toFixed(2),
            centerIcon: 'fa-chart-line',
            right: "Last Month",
            rightValue: parseFloat(profit?.lastMonthProfit || 0).toFixed(2),
            rightIcon: 'fa-chart-bar',
        },
    ];

    return (
        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-4 gap-4'>
            {data.map((val, i) => (
                <div
                    key={i}
                    className={`flex flex-col bg-white p-1 rounded-lg shadow-md border-l-4`}
                    style={{ borderColor: val.borderColor }}
                >
                    <div className='text-xl mb-4 text-center' style={{ color: val.borderColor }}>
                        {val.name}
                    </div>
                    <div className='flex justify-center text-2xl font-bold mb-4 text-gray-600'>
                        {val.value}
                    </div>
                    <div className='flex justify-between text-gray-800 text-sm'>
                        <div className='flex flex-col items-center'>
                            <FontAwesomeIcon icon={val.leftIcon} className='text-2xl mb-1' style={{ color: val.borderColor }} />
                            <h2>{val.left}</h2>
                            <div className={`font-bold ${val.leftValueColor}`}>{val.leftValue}</div>
                        </div>
                        {val.center && (
                            <div className='flex flex-col items-center'>
                                <FontAwesomeIcon icon={val.centerIcon} className='text-2xl mb-1' style={{ color: val.borderColor }} />
                                <h2>{val.center}</h2>
                                <div className='font-bold'>{val.centerValue}</div>
                            </div>
                        )}
                        <div className='flex flex-col items-center'>
                            <FontAwesomeIcon icon={val.rightIcon} className='text-2xl mb-1' style={{ color: val.borderColor }} />
                            <h2>{val.right}</h2>
                            <div className={`font-bold ${val.rightValueColor}`}>{val.rightValue}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DashboardCards;
