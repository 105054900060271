import React, { useState, useEffect, useCallback } from 'react'
import { LAB_TEST_API } from '../../../Api';
import { getData } from '../../../Utils/Apicalls';
import { TICK_ICON } from '../../../icons';

const LabTest = ({ prescribedLabTests, setPrescribedLabTests, labTestTypeId, readOnly = false }) => {
  const [loading, setLoading] = useState(false);
  const [labTestList, setLabTestList] = useState([]);

  const retrieveLabTests = useCallback(() =>
    getData(LAB_TEST_API, setLabTestList, setLoading,
      { active: true, labTestType: labTestTypeId }), [labTestTypeId]);
  useEffect(retrieveLabTests, [retrieveLabTests])

  const handleInputChange = (id) => {
    if (readOnly) return
    let newLabTests = [...prescribedLabTests];
    if (newLabTests.includes(parseInt(id))) {
      newLabTests = newLabTests.filter(test => (parseInt(test) !== parseInt(id)));
    } else {
      newLabTests.push(parseInt(id))
    }
    setPrescribedLabTests(newLabTests);
  };

  return (
    <div className='w-full flex justify-center mx-auto overflow-y-auto mt-3 max-h-full'>
      <table className="w-1/2 text-sm border">
        <thead className='bg-gray-300 sticky z-10 top-0 w-full'>
          <tr className='text-sm w-full p-2'>
            <th className="table-data w-1/2 p-2">Test Name</th>
            <th className="table-data w-1/2 p-2">Click To Select</th>
          </tr>
        </thead>
        <tbody className='overflow-y-auto w-full text-center'>
          {labTestList.map((test) => (
            <tr key={test.id} className="w-full border h-10">
              <td className='border'>
                {test.name}
              </td>
              <td onClick={() => handleInputChange(test.id)} className="hover:cursor-pointer">
                {prescribedLabTests.includes(test.id) ? <div className='text-lg text-green-500'>{TICK_ICON}</div> : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default LabTest
