import React, { Fragment } from 'react'
import { useEffect, useState } from "react";
import { useGetPartyQuery } from "../../../redux/PharmacyServices/PartyMasterService"
import { Loader } from "../../../Basic/components";
import { showEntries } from '../../../Utils/DropdownData';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, getDateFromDateTimeToDisplay, pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { useGetSalesBillByIdQuery, useGetSalesBillQuery } from '../../../redux/PharmacyServices/SalesBillService';
import { useGetBarcodeMasterByIdQuery } from '../../../redux/PharmacyServices/BarcodeMasterServices';
import moment from 'moment';
import { TablePagination } from '@mui/material';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';



const PurchaseRegister = ({
    onClick,
}) => {
    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )
    const [searchDocId, setSearchDocId] = useState("");
    const [searchBillDate, setSearchBillDate] = useState("");
    const [searchMobileNo, setSearchMobileNo] = useState("");

    const [id, setId] = useState('')
    const [searchCustomerName, setSearchCustomerName] = useState("");

    const [dataPerPage, setDataPerPage] = useState("15");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [searchSupplierDcDate, setSearchSupplierDcDate] = useState("")
    const [currentOpenNumber, setCurrentOpenNumber] = useState('')
    const handleOnclick = (e) => {
        setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
    }
    const searchFields = { searchDocId, searchBillDate, searchCustomerName, searchMobileNo, searchSupplierDcDate, }

    useEffect(() => { setCurrentPageNumber(0) }, [dataPerPage, searchDocId, searchBillDate, searchCustomerName, searchMobileNo, searchSupplierDcDate])

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
    const params = {
        branchId, companyId
    };


    const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetSalesBillByIdQuery(id, { skip: !id });
    console.log(singleData, 'singleData');

    const { data: allData, isLoading, isFetching } = useGetSalesBillQuery({ params: { branchId, ...searchFields, pagination: true, dataPerPage, pageNumber: currentPageNumber + 1 } });

    const { data: supplierList, isLoading: isSupplierLoading, isFetching: isSupplierFetching } =
        useGetPartyQuery({ params: { ...params } });

    useEffect(() => {
        if (allData?.totalCount) {
            setTotalCount(allData?.totalCount)
        }
    }, [allData, isLoading, isFetching])

    useEffect(() => {
        let registrationFormReportComponent = document.getElementById("registrationFormReport");
        registrationFormReportComponent.addEventListener('keydown', function (ev) {
            var focusableElementsString = '[tabindex="0"]';
            let ol = document.querySelectorAll(focusableElementsString);
            if (ev.key === "ArrowDown") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = i < ol.length - 1 ? ol[i + 1] : ol[0];
                        o.focus(); break;
                    }
                }
                ev.preventDefault();
            } else if (ev.key === "ArrowUp") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = ol[i - 1];
                        o.focus(); break;
                    }
                }
                ev.preventDefault();
            }
        });
        return () => {
            registrationFormReportComponent.removeEventListener('keydown', () => { });
        };
    }, []);

    const isLoadingIndicator = isLoading || isFetching || isSupplierFetching || isSupplierLoading
    const purData = allData?.data ? allData.data : []
    const handleChange = (event, value) => {
        setCurrentPageNumber(value);
    };
    const handleChangeRowsPerPage = (event) => {
        setDataPerPage(parseInt(event.target.value, 10));
        setCurrentPageNumber(0);
    };
    return (
        <>

            <div id='registrationFormReport' className="flex flex-col w-full">
                <div className="flex items-center justify-between w-full page-heading p-1 text-black">
                    <div className="heading text-center whitespace-nowrap">Sales Register</div>
                    <span className='flex gap-4'>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button text-white bg-blue-400 p-2 text-xs "
                            table="table-to-xls"
                            filename="tablexls"
                            sheet="tablexls"
                            buttonText='Download as excel'
                        >

                        </ReactHTMLTableToExcel>
                    </span>
                </div>
                <>

                    <div
                        className=" p-4 border m-2"
                    >
                        <table id="" className="table-fixed text-center w-full ">
                            <thead className="text-xs uppercase text-white bg-gray-700 dark:bg-gray-700 dark:text-gray-400">
                                <tr className='sticky top-0  font-thin tracking-wider'>
                                    <th
                                        className="  p-2 text-center  border border-White font-semibold  top-0 stick-bg w-10"
                                    >
                                        S. no.
                                    </th>
                                    <th
                                        className=" p-2 text-center  border border-White font-semibold  top-0 stick-bg "
                                    >
                                        <div>Bill. No</div><input
                                            type="text"
                                            className="text-black focus:outline-none  md:ml-3 rounded p-1"
                                            placeholder="Search"
                                            value={searchDocId}
                                            onChange={(e) => {
                                                setSearchDocId(e.target.value);
                                            }}
                                        />
                                    </th>
                                    <th
                                        className=" p-2 text-center  border border-White font-semibold  top-0 stick-bg "
                                    >
                                        <div>Bill. Date</div><input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border md:ml-3 border-white rounded p-1"
                                            placeholder="Search"
                                            value={searchBillDate}
                                            onChange={(e) => {
                                                setSearchBillDate(e.target.value);
                                            }}
                                        />
                                    </th>
                                    <th

                                        className="p-2 text-center  border border-White font-semibold  top-0 stick-bg  "
                                    >
                                        <div>Patient Name</div><input
                                            type="text"
                                            className="text-black  h-6 focus:outline-none border md:ml-3 border-white rounded p-1"
                                            placeholder="Search"
                                            value={searchCustomerName}
                                            onChange={(e) => {
                                                setSearchCustomerName(e.target.value);
                                            }}
                                        />
                                    </th>
                                    <th
                                        className="p-2 text-center  border border-White font-semibold  top-0 stick-bg "
                                    >
                                        <div>Ph.No</div><input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border md:ml-3 border-white rounded p-1"
                                            placeholder="Search"
                                            value={searchMobileNo}
                                            onChange={(e) => {
                                                setSearchMobileNo(e.target.value);
                                            }}
                                        />
                                    </th>
                                    <th
                                        className="p-2 text-center  border border-White font-semibold  top-0 stick-bg "
                                    >
                                        <div>Sales Type</div>
                                        {/* <input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded p-1"
                                            placeholder="Search"
                                            value={searchSupplierDcDate}
                                            onChange={(e) => {
                                                setSearchSupplierDcDate(e.target.value);
                                            }}
                                        /> */}
                                    </th>


                                </tr>
                            </thead>
                            {isLoadingIndicator ?
                                <tbody>
                                    <tr>
                                        <td>
                                            <Loader />
                                        </td>
                                    </tr>
                                </tbody>
                                :
                                <tbody className="">
                                    {purData?.map((dataObj, index) => (
                                        <Fragment key={index}>
                                            <tr
                                                className={` table-row ${(currentOpenNumber === index) ? "border-2 border-green-500" : ""}`}
                                                onClick={() => {
                                                    if (index === currentOpenNumber) {
                                                        setCurrentOpenNumber("")
                                                    } else {
                                                        setCurrentOpenNumber(index)
                                                        setId(dataObj.id)
                                                    }
                                                }}>
                                                <td className='p-2 text-left border border-white'> {(index + 1) + (dataPerPage * (currentPageNumber))}</td>
                                                <td className=' p-2 text-left border border-white'>{dataObj.docId}</td>
                                                <td className=' p-2 text-left border border-white '> {getDateFromDateTimeToDisplay(dataObj?.createdAt)}</td>
                                                <td className=' p-2 text-left border border-white'>{dataObj?.name} </td>
                                                <td className=' p-2 text-left border border-white '>{(dataObj?.contactMobile
                                                )}</td>

                                                <td className=' p-2 text-left border border-white'>{dataObj.salesType}</td>

                                            </tr>
                                            {(currentOpenNumber === index)
                                                &&



                                                <table class=" border text-xs justify-center  w-[95vw]  m-2">
                                                    <thead className=" table-header">
                                                        <tr className='h-2'>
                                                            <th
                                                                className="  top-0 stick-bg w-10">
                                                                S.no.
                                                            </th>
                                                            <th
                                                                className="  top-0 stick-bg table-data "
                                                            >
                                                                Batch No

                                                            </th>
                                                            <th
                                                                className="  top-0 stick-bg table-data "
                                                            >
                                                                Product Brand

                                                            </th>
                                                            <th
                                                                className="  top-0 stick-bg ">
                                                                Product
                                                            </th>
                                                            <th
                                                                className="  top-0 stick-bg ">
                                                                Expiry Date
                                                            </th>
                                                            <th className="  top-0 stick-bg table-data">
                                                                <label>Qty</label>
                                                            </th>

                                                            <th
                                                                className="  top-0 stick-bg table-data "
                                                            >
                                                                <label>Price</label>

                                                            </th>
                                                            <th className="  top-0 stick-bg table-data">
                                                                <label>Total Amt</label>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className=' bg-blue-200 '>
                                                        {singleData?.data?.SalesBillItems
                                                            .map((poItem, index) => (
                                                                <tr key={index}>
                                                                    <td className='border-2 border-white p-2'>{index + 1}</td>
                                                                    <td className=' p-2  border-2 border-white'>{poItem?.batchNo}</td>
                                                                    <td className=' p-2 border-2 border-white'>{poItem?.productBrandName}</td>
                                                                    <td className=' p-2 border-2 border-white'>{poItem?.
                                                                        productName}</td>
                                                                    <td className=' p-2 border-2 border-white'>{moment(poItem?.
                                                                        expiryDate).format("MM/YYYY")}</td>
                                                                    <td className='py-1 text-right p-2  border-2 border-white'>{poItem?.qty}</td>
                                                                    <td className='py-1 text-right border-2  p-2 border-white'>{parseFloat(poItem?.salePrice
                                                                    ).toFixed(2)}</td>
                                                                    <td
                                                                        className='py-1 text-right border-2 p-2  border-white'>
                                                                        {parseFloat(poItem?.salePrice
                                                                        ) * parseFloat(poItem?.qty).toFixed(2)}</td>

                                                                </tr>
                                                            ))}
                                                        {console.log(singleData?.data?.SalesBillItems
                                                            , 'data')}
                                                    </tbody>
                                                </table>
                                            }
                                        </Fragment>
                                    ))}
                                </tbody>

                            }
                        </table>
                        <table id="table-to-xls" className="table-fixed text-center w-full visible">
                            <thead className="border-2 table-header">
                                <tr className='h-2'>
                                    <th
                                        className="  top-0 stick-bg w-10"
                                    >
                                        S. no.
                                    </th>
                                    <th
                                        className="  top-0 stick-bg "
                                    >
                                        <div>Bill. No</div>
                                    </th>
                                    <th
                                        className="  top-0 stick-bg"
                                    >
                                        <div>Bill. Date</div>
                                    </th>
                                    <th

                                        className="  top-0 stick-bg "
                                    >
                                        <div>Patient Name</div>
                                    </th>
                                    <th
                                        className="  top-0 stick-bg"
                                    >
                                        <div>Ph.No</div>
                                    </th>
                                    <th
                                        className="  top-0 stick-bg"
                                    >
                                        <div>Sales Type</div>
                                        {/* <input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded p-1"
                                            placeholder="Search"
                                            value={searchSupplierDcDate}
                                            onChange={(e) => {
                                                setSearchSupplierDcDate(e.target.value);
                                            }}
                                        /> */}
                                    </th>


                                </tr>
                            </thead>
                            {isLoadingIndicator ?
                                <tbody>
                                    <tr>
                                        <td>
                                            <Loader />
                                        </td>
                                    </tr>
                                </tbody>
                                :
                                <tbody className="">
                                    {purData?.map((dataObj, index) => (
                                        <Fragment key={index}>
                                            <tr
                                                className={` table-row `}
                                                onClick={() => {
                                                    if (index === currentOpenNumber) {
                                                        setCurrentOpenNumber("")
                                                    } else {
                                                        setCurrentOpenNumber(index)
                                                        setId(dataObj.id)
                                                    }
                                                }}>
                                                <td className='py-1  '> {(index + 1) + (dataPerPage * (currentPageNumber))}</td>
                                                <td className='  py-2 text-left  border border-white p-1 '>{dataObj.docId}</td>
                                                <td className='  py-2 text-left  border border-white p-1 '> {getDateFromDateTimeToDisplay(dataObj?.createdAt)}</td>
                                                <td className='   py-2 text-left  border border-white p-1 '>{dataObj?.name} </td>
                                                <td className='  py-2 text-left  border border-white p-1 '>{(dataObj?.contactMobile
                                                )}</td>

                                                <td className=' text-[12px] text-right  border border-white p-1'>{dataObj.salesType}</td>

                                            </tr>
                                            {(currentOpenNumber === index)
                                                &&



                                                <table class=" border text-xs justify-center  w-[95vw]  m-2">
                                                    <thead className=" table-header">
                                                        <tr className='h-2'>
                                                            <th
                                                                className="  top-0 stick-bg w-10">
                                                                S.no.
                                                            </th>
                                                            <th
                                                                className="  top-0 stick-bg table-data "
                                                            >
                                                                Batch No

                                                            </th>
                                                            <th
                                                                className="  top-0 stick-bg table-data "
                                                            >
                                                                Product Brand

                                                            </th>
                                                            <th
                                                                className="  top-0 stick-bg ">
                                                                Product
                                                            </th>
                                                            <th
                                                                className="  top-0 stick-bg ">
                                                                Expiry Date
                                                            </th>
                                                            <th className="  top-0 stick-bg table-data">
                                                                <label>Qty</label>
                                                            </th>

                                                            <th
                                                                className="  top-0 stick-bg table-data "
                                                            >
                                                                <label>Price</label>

                                                            </th>
                                                            <th className="  top-0 stick-bg table-data">
                                                                <label>Total Amt</label>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className=' bg-blue-200 '>
                                                        {singleData?.data?.SalesBillItems
                                                            .map((poItem, index) => (
                                                                <tr key={index}>
                                                                    <td className='border-2 border-white p-2'>{index + 1}</td>
                                                                    <td className=' p-2  border-2 border-white'>{poItem?.batchNo}</td>
                                                                    <td className=' p-2 border-2 border-white'>{poItem?.productBrandName}</td>
                                                                    <td className=' p-2 border-2 border-white'>{poItem?.
                                                                        productName}</td>
                                                                    <td className=' p-2 border-2 border-white'>{moment(poItem?.
                                                                        expiryDate).format("MM/YYYY")}</td>
                                                                    <td className='py-1 text-right p-2  border-2 border-white'>{poItem?.qty}</td>
                                                                    <td className='py-1 text-right border-2  p-2 border-white'>{parseFloat(poItem?.salePrice
                                                                    ).toFixed(2)}</td>
                                                                    <td
                                                                        className='py-1 text-right border-2 p-2  border-white'>
                                                                        {parseFloat(poItem?.salePrice
                                                                        ) * parseFloat(poItem?.qty).toFixed(2)}</td>

                                                                </tr>
                                                            ))}
                                                        {console.log(singleData?.data?.SalesBillItems
                                                            , 'data')}
                                                    </tbody>
                                                </table>
                                            }
                                        </Fragment>
                                    ))}
                                </tbody>

                            }
                        </table>
                        <div className='flex items-center justify-end'>
                            <TablePagination
                                component="div"
                                count={totalCount}
                                page={currentPageNumber}
                                onPageChange={handleChange}
                                rowsPerPage={dataPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </>
            </div>
        </>
    )
}

export default PurchaseRegister



