import React, { useState } from 'react';
import { TICK_ICON } from '../../../icons';

const PrescriptionSelection = ({ medicines, setPoBillItems, poBillItems, onClose }) => {
    const [localPoBillItems, setLocalPoBillItems] = useState(poBillItems)
    const addItem = (item) => {
        setLocalPoBillItems(prev => [...prev.filter(i => i.productId), { productId: item.productId, active: true, productBrandId: item?.Product?.productBrandId }])
    }

    const removeItem = (item) => {
        setLocalPoBillItems(prev => prev.filter(i => parseInt(i.productId) !== parseInt(item.productId)))
    }

    const isItemChecked = (item) => {
        return localPoBillItems.findIndex(i => parseInt(i.productId) === parseInt(item.productId)) !== -1
    }

    function handleCheckBoxChange(value, item) {
        if (value) {
            addItem(item)
        } else {
            removeItem(item)
        }
    }

    function handleSelectAllChange(value) {
        if (value) {
            medicines.forEach(item => addItem(item))
        } else {
            medicines.forEach(item => removeItem(item))
        }
    }

    function getSelectAll() {
        return medicines.every(item => isItemChecked(item))
    }

    function handleDone() {
        setPoBillItems(localPoBillItems);
        onClose()
    }

    return (
        <div>
            <div className='text-2xl bg-indigo-500 text-white text-center'>
                Doctor Prescription
            </div>
            <div className='w-full overflow-y-auto mt-3' style={{ height: "90%" }}>
                <table className="w-full text-sm border border-gray-300">
                    <thead className='bg-gray-200'>
                        <tr className='text-sm border border-gray-300'>
                            <th className="table-data w-10"
                                onClick={(e) => { handleSelectAllChange(e.target.checked) }}
                            >
                                <input type="checkbox" checked={getSelectAll()} onChange={(e) => { handleSelectAllChange(e.target.checked) }} />
                            </th>
                            <th className="table-data w-60 border border-gray-300">Drugs</th>
                            <th className="table-data border border-gray-300">Unit (Tablet / Syrup)</th>
                            <th className="table-data border border-gray-300">Time</th>
                            <th className="table-data w-28 border border-gray-300"  >
                                <div className='grid grid-cols-2 border border-gray-300'>
                                    <div className='col-span-2 border'>
                                        M
                                    </div>
                                </div>
                            </th>
                            <th className="table-data w-28 border border-gray-300" >
                                <div className='grid grid-cols-2 border border-gray-300'>
                                    <div className='col-span-2 border'>
                                        AN
                                    </div>
                                </div>
                            </th>
                            <th className="table-data w-28 border border-gray-300" >
                                <div className='grid grid-cols-2 border border-gray-300'>
                                    <div className='col-span-2 border'>
                                        EV
                                    </div>
                                </div>
                            </th>
                            <th className="table-data w-28 border border-gray-300" >
                                <div className='grid grid-cols-2 border border-gray-300'>
                                    <div className='col-span-2 border'>
                                        N
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className='border border-gray-300 h-full w-full '>
                        {medicines.map((row, index) => (
                            <tr className="h-full w-full border border-gray-300" onClick={(e) =>
                                handleCheckBoxChange(!isItemChecked(row), row)}  >
                                <td>
                                    <div className='flex items-center justify-center '>
                                        <input type="checkbox" onChange={(e) =>
                                            handleCheckBoxChange(!isItemChecked(row), row)}
                                            checked={isItemChecked(row)} />
                                    </div>
                                </td>
                                <td className='border border-gray-300'>
                                    <input
                                        type="text"
                                        className="border rounded px-4 py-2 w-full"
                                        value={row?.Product?.name}
                                        readOnly
                                    />
                                </td>
                                <td className='border border-gray-300'>
                                    <input
                                        type="text"
                                        className="border  rounded px-4 py-2 w-full"
                                        value={row.unit}
                                        readOnly
                                    />
                                </td>
                                <td className='border border-gray-300'>
                                    <input
                                        type="text"
                                        className="border  rounded px-4 py-2 w-full"
                                        value={row.medicineTiming}
                                        readOnly
                                    />
                                </td>
                                <td
                                    tabIndex={"0"}
                                    className='table-data w-14  border border-gray-300'>
                                    {row.M ? <div className='text-lg text-green-500 text-center '>{TICK_ICON}</div> : ""}
                                </td>
                                <td
                                    tabIndex={"0"}
                                    className='table-data w-14  border border-gray-300'>
                                    {row.A ? <div className='text-lg text-green-500 text-center '>{TICK_ICON}</div> : ""}
                                </td>

                                <td
                                    tabIndex={"0"}
                                    className='table-data w-14  border border-gray-300'>
                                    {row.E ? <div className='text-lg text-green-500 text-center  '>{TICK_ICON}</div> : ""}
                                </td>
                                <td
                                    tabIndex={"0"}
                                    className='table-data w-14  border border-gray-300'>
                                    {row.N ? <div className='text-lg text-green-500 text-center '>{TICK_ICON}</div> : ""}
                                </td>
                            </tr>))}
                    </tbody>
                </table>
                <div className='flex justify-end'> <button className='bg-blue-600 text-white p-1 my-3 rounded-md' onClick={handleDone}> Done</button> </div>
            </div>
        </div>
    )
}

export default PrescriptionSelection
