import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";
import { LAB_TEST_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const LabTestMasterApi = createApi({
  reducerPath: "LabTestMaster",
  baseQuery,
  tagTypes: ["LabTestMaster"],
  endpoints: (builder) => ({
    getLabTestMaster: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: LAB_TEST_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: LAB_TEST_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["LabTestMaster"],
    }),
    getLabTestMasterById: builder.query({
      query: (id) => {
        return {
          url: `${LAB_TEST_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["LabTestMaster"],
    }),
    addLabTestMaster: builder.mutation({
      query: (payload) => ({
        url: LAB_TEST_API,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["LabTestMaster"],
    }),
    updateLabTestMaster: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${LAB_TEST_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["LabTestMaster"],
    }),
    deleteLabTestMaster: builder.mutation({
      query: (id) => ({
        url: `${LAB_TEST_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["LabTestMaster"],
    }),

  }),
});

export const {
  useGetLabTestMasterQuery,
  useGetLabTestMasterByIdQuery,
  useAddLabTestMasterMutation,
  useUpdateLabTestMasterMutation,
  useDeleteLabTestMasterMutation,
} = LabTestMasterApi;

export default LabTestMasterApi;
