import React, { useEffect } from 'react';
import { PLUS } from '../../../icons';
import MedicationRow from './MedicationRow';


const Medication = ({ medicines, setMedicines, readOnly }) => {
    console.log(medicines, 'medicines');

    const handleInputChange = (value, index, field) => {
        setMedicines(prev => {
            const newMedicines = structuredClone(prev);
            newMedicines[index][field] = value;
            return newMedicines
        });
    };

    useEffect(() => {
        if (readOnly) return
        else {
            if (medicines.length === 0) {
                setMedicines([{ drug: '', unit: "", dosage: "", M: false, AN: false, EV: false, N: false }]);
            }
        }
    }, [medicines, readOnly])

    const addRow = () => {
        const newRow = { drug: '', unit: "", dosage: "", M: false, AN: false, EV: false, N: false, };
        setMedicines([...medicines, newRow]);
    };
    const handleDeleteRow = id => {
        const filteredRows = medicines.filter((row, index) => index !== id);
        setMedicines(filteredRows);
    };
    const onKeyDown = (e) => {
        if (e.key === "Tab") {
            addRow();
        }
    };
    return (
        <>
            <div className='flex justify-end w-full' >
                {readOnly ?
                    "" :
                    <div onClick={addRow}
                        className='hover:cursor-pointer flex items-center bg-green-600 text-white rounded p-2'>
                        {PLUS}&nbsp;Add&nbsp;Drug
                    </div>
                }
            </div>
            {
                medicines.length !== 0 ?
                    <div className='w-full mt-2' >
                        <table className="w-full text-sm border border-gray-300">
                            <thead className='bg-gray-200'>
                                <tr className='text-sm border border-gray-300'>
                                    <th className="table-data w-60 border border-gray-300">Drugs</th>
                                    <th className="table-data w-60 border border-gray-300">Drug Type</th>
                                    <th className="table-data border border-gray-300">Unit (Tablet / Syrup)</th>
                                    <th className="table-data border border-gray-300">Time</th>
                                    <th className="table-data w-28 border border-gray-300"  >
                                        <div className='grid grid-cols-2 border border-gray-300'>
                                            <div className='col-span-2 border'>
                                                M
                                            </div>
                                        </div>
                                    </th>
                                    <th className="table-data w-28 border border-gray-300" >
                                        <div className='grid grid-cols-2 border border-gray-300'>
                                            <div className='col-span-2 border'>
                                                AN
                                            </div>
                                        </div>
                                    </th>
                                    <th className="table-data w-28 border border-gray-300" >
                                        <div className='grid grid-cols-2 border border-gray-300'>
                                            <div className='col-span-2 border'>
                                                EV
                                            </div>
                                        </div>
                                    </th>
                                    <th className="table-data w-28 border border-gray-300" >
                                        <div className='grid grid-cols-2 border border-gray-300'>
                                            <div className='col-span-2 border'>
                                                N
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='border border-gray-300 h-full w-full '>
                                {medicines.map((row, index) => (
                                    <MedicationRow key={index} row={row} handleDeleteRow={handleDeleteRow} handleInputChange={handleInputChange} index={index} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    :
                    <div></div>
            }
        </>
    )
}

export default Medication
