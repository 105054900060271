import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";
import { OPENING_STOCK } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const openingStockApi = createApi({
  reducerPath: "OpeningStock",
  baseQuery,
  tagTypes: ["openingStock"],
  endpoints: (builder) => ({
    getOpeningStock: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: OPENING_STOCK + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: OPENING_STOCK,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["openingStock"],
    }),
    getOpeningStockById: builder.query({
      query: (id) => {
        return {
          url: `${OPENING_STOCK}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["openingStock"],
    }),
    addOpeningStock: builder.mutation({
      query: (payload) => ({
        url: OPENING_STOCK,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["openingStock"],
    }),
    updateOpeningStock: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${OPENING_STOCK}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["openingStock"],
    }),
    deleteOpeningStock: builder.mutation({
      query: (id) => ({
        url: `${OPENING_STOCK}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["openingStock"],
    }),
  }),
});

export const {
  useGetOpeningStockQuery,
  useGetOpeningStockByIdQuery,
  useAddOpeningStockMutation,
  useUpdateOpeningStockMutation,
  useDeleteOpeningStockMutation,
} = openingStockApi;

export default openingStockApi;
