import React from 'react'
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { EMPTY_ICON } from '../../../icons';
import { ACTIVE, INACTIVE } from '../../../Strings';
import { useGetBinMasterQuery } from '../../../redux/PharmacyServices/BinMasterServices';

const FormReport = ({ searchValue, setSearchValue, onNew, onClick }) => {

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };
    const tableHeaders = [
        "Location", "Store", "Rack", "Bin", "Status"
    ]
    const { data: allData, isLoading, isFetching } = useGetBinMasterQuery({ params, searchParams: searchValue });

    return (
        <div className="flex flex-col frame w-full h-[90%] overflow-auto">
            <div className="md:text-center md:gap-8">
                <input
                    type="text"
                    className="text-sm bg-gray-100 focus:outline-none border w-full"
                    id="id"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => {
                        setSearchValue(e.target.value);
                    }}
                />
            </div>
            <> {
                (isLoading || isFetching)
                    ?
                    <Loader />
                    :
                    <>
                        {allData.data.length === 0 ? (
                            <div className="flex-1 flex justify-center text-blue-900 items-center text-3xl">
                                <p>{EMPTY_ICON} No Data Found...! </p>
                            </div>
                        ) : (
                            <div className="md:grid md:justify-items-stretch ">
                                <table className="table-auto text-center">
                                    <thead className="border-2 table-header">
                                        <tr>
                                            {tableHeaders.map((head, index) => (
                                                <th
                                                    key={index}
                                                    className="border-2  top-0 stick-bg"
                                                >
                                                    {head}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className="border-2">
                                        {allData.data.map((bin, index) => (
                                            <tr
                                                key={index}
                                                className="border-2 table-row"
                                                onClick={() => onClick(bin.id)}>
                                                <td className='table-data' >
                                                    {bin?.Rack?.Store?.Location?.branchName}
                                                </td>
                                                <td className='table-data'>
                                                    {bin?.Rack?.Store?.storeName}
                                                </td >
                                                <td className='table-data'>
                                                    {bin?.Rack?.name}
                                                </td >
                                                <td className='table-data'>
                                                    {bin?.name}
                                                </td >
                                                <td className='table-data'>
                                                    {(bin.active) ? ACTIVE : INACTIVE}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </>
            }
            </>
        </div>)
}


export default FormReport