import validator from 'validator';
import React, { useRef, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { findFromList, findFromListWithSearchProperty } from '../Utils/helper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Ensure styles are imported

import 'react-datepicker/dist/react-datepicker.css';
import { dropDownListObject, multiSelectOption } from '../Utils/contructObject';
import { Input } from "@material-tailwind/react"
import { ThemeProvider } from "@material-tailwind/react";
import { Autocomplete, FormControl, InputLabel, MenuItem, TextField } from '@mui/material';
import Select from '@mui/material/Select';
import ReactSelect from 'react-select';
import './input.css'
export const handleOnChange = (event, setValue) => {
    const inputValue = event.target.value;
    const inputSelectionStart = event.target.selectionStart;
    const inputSelectionEnd = event.target.selectionEnd;

    const upperCaseValue = inputValue.toUpperCase();

    const valueBeforeCursor = upperCaseValue.slice(0, inputSelectionStart);
    const valueAfterCursor = upperCaseValue.slice(inputSelectionEnd);

    setValue(valueBeforeCursor + inputValue.slice(inputSelectionStart, inputSelectionEnd) + valueAfterCursor);

    // Set the cursor position to the end of the input value
    setTimeout(() => {
        event.target.setSelectionRange(valueBeforeCursor.length + inputValue.slice(inputSelectionStart, inputSelectionEnd).length, valueBeforeCursor.length + inputValue.slice(inputSelectionStart, inputSelectionEnd).length);
    });
};

export const MultiSelectDropdown = ({ name, selected, setSelected, options, readOnly = false, tabIndex = null, className = "", inputClass }) => {
    return (
        <div className={`m-1  -cols-1 md:-cols-3 items-center z-0 md:my-0.5 md:py-3 data ${className}`}>
            <label className='md:text-start flex' >{name}</label>
            <MultiSelect
                className={`focus:outline-none  border border-gray-400 p-1  rounded text-black  ${inputClass}`}
                options={options}
                value={selected}
                onChange={readOnly ? () => { } : setSelected}
                labelledBy="Select"
            />
        </div>
    );
};




export const TextInput = ({
    name,
    type,
    value,
    setValue,
    readOnly,
    className,
    font,
    required = false,
    disabled = false,
    tabIndex = -1,
    onBlur = null,
    onKeyDown,
    min,
    classNameInput,
}) => {
    const handleOnChange = (e, setValue) => {
        setValue(e.target.value);
    };

    return (
        <div className="items-center h-6 my-3 text-[12px] w-full px-2">
            <TextField
                size="small"
                label={name}
                placeholder={name}
                id="outlined-basic"
                variant="outlined"
                onKeyDown={onKeyDown}
                tabIndex={tabIndex || 0}
                type={type}
                disabled={disabled}
                required={required}
                InputProps={{
                    style: {
                        fontSize: '12px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                    },
                }}
                InputLabelProps={{
                    style: {
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0px'
                    },
                }}
                className={`flex focus:outline-none border-gray-400 w-full border rounded ${classNameInput}`}
                value={value}
                onChange={(e) =>
                    type === 'number'
                        ? setValue(e.target.value)
                        : handleOnChange(e, setValue)
                }
                readOnly={readOnly}
            />
        </div>
    );
};
//
export const NumberInput = ({
    name,
    type,
    value,
    setValue,
    readOnly,
    className,
    required = false,
    disabled = false,
    tabIndex = null,
    onBlur = null,
    onKeyDown,
    min = 0,
    classNameInput,
}) => {

    const handleOnChange = (e) => {
        const inputValue = e.target.value;
        if (type === "number" && inputValue >= min) {
            setValue(inputValue);
        } else if (type !== "number") {
            setValue(inputValue);
        }
    };

    return (
        <div className=' items-center h-6  text-[12px] w-full px-2 my-2'>


            <TextField
                size='small'
                label={name}
                placeholder={name}
                id="outlined-basic"
                variant="outlined"
                onKeyDown={onKeyDown}
                tabIndex={tabIndex ? tabIndex : 0}
                type={type}
                disabled={disabled}
                required={required}
                InputProps={{
                    style: {
                        fontSize: '13px',

                    }
                }}
                InputLabelProps={{
                    style: {
                        fontSize: '15px',
                        padding: '0.2rem'
                    }
                }}

                min={min}



                className={`input-field focus:outline-none md:col-span-2 border-gray-400 p-1 w-full border rounded ${classNameInput}`}
                value={value}
                onChange={handleOnChange}
                readOnly={readOnly}
            />
        </div >
    );
};

export const LongTextInput = ({ name, type, value, setValue, className, readOnly, required = false, disabled = false, tabIndex = null }) => {
    return (
        <div className=' -cols-1 md:-cols-2 items-center md:my-0.5 md:px-1 data gap-1'>
            <label className='md:text-start flex'>{required ? <RequiredLabel name={name} /> : name}</label>
            <input tabIndex={tabIndex ? tabIndex : undefined} type={type} disabled={disabled} required={required} className={className} value={value} onChange={(e) => { type === "number" ? setValue(e.target.value) : handleOnChange(e, setValue) }} readOnly={readOnly} />
        </div>
    )
}

export const DisabledInput = ({ name, type, value, color, className = "", textClassName = "", tabIndex = null }) => {
    return (
        <div className={`w-full items-center text-[12px] text-black px-2 my-2 ${className}`}>
            {/* <label className={`md:text-start flex mb-1 uppercase ${className} `}>{name}</label> */}
            <TextField
                size='small'
                label={name}
                placeholder={name}
                id="outlined-basic"
                variant="outlined"
                type={type}
                fullWidth
                disabled={true}
                InputProps={{
                    style: {
                        fontSize: '13px',
                        color: { color },
                        fontWeight: 'bold',
                        width: '100%',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',

                    }

                }}
                InputLabelProps={{
                    style: {
                        fontSize: '14px',

                    }
                }}
                tabIndex={tabIndex ? tabIndex : 0}
                className={`text-blue-900 focus:outline-none md:col-span-2 border border-gray-600   rounded text-center`} value={value} />
        </div>
    )
}

export const LongDisabledInput = ({ name, type, value, className, tabIndex = null }) => {
    return (
        <div className={` -flow-col  items-center md:my-0.5 md:px-1 data ${className}`}>
            <label className={`md:text-start flex mb-1 ${className} `}>{name}</label>
            <input type={type} className={`h-6 border border-gray-400 p-1  rounded`} value={value} disabled />
        </div>
    )
}

export const TextArea = ({
    name,
    value,
    setValue,
    readOnly,
    required = false,
    disabled = false,
    rows = 2,
    cols = 30,
    tabIndex = null,
    className
}) => {
    return (
        <div className={`-cols-1 md:-cols-3 md:my-1 data px-2 ${className}`}>
            <label className='md:text-start flex mb-1 text-[13px]'>
                {required ? <RequiredLabel name={name} /> : `${name}`}
            </label>
            <textarea
                tabIndex={tabIndex ? tabIndex : undefined}
                name={name}
                disabled={disabled}
                required={required}
                className='w-full focus:outline-none md:col-span-2 border-b border-l border-r border-gray-400 p-2  rounded h-[3rem] '
                cols={cols}
                rows={rows}
                value={value}
                onChange={(e) => { handleOnChange(e, setValue); }}
                readOnly={readOnly}>
            </textarea>
        </div>
    )
}








export const DropdownInput = ({
    name,
    options,
    value,
    setValue,
    className = '',
    readOnly = false,
    required = false,
    disabled = false,
    clear = false,
    autoFocus = false
}) => {
    const optionList = options.map(option => ({
        label: option.show,
        value: option.value,
    }));

    return (
        <div className='items-center h-6 my-3 text-[12px] w-full px-2'>  <Autocomplete
            className={`w-full ${className}`}
            options={optionList}
            disableClearable={!clear}
            disabled={disabled || readOnly}
            autoHighlight
            openOnFocus
            autoFocus={autoFocus}
            value={optionList.find(opt => opt.value === value) || null}
            onChange={(event, newValue) => {
                setValue(newValue ? newValue.value : '');
            }}

            renderInput={(params) => (
                <TextField
                    {...params}
                    label={required ? `${name} *` : name}
                    variant="outlined"
                    size="small"
                    sx={{
                        '& .MuiInputBase-input': { fontSize: '12px' },  // Input text size
                        '& .MuiInputLabel-root': { fontSize: '14px' }   // Label text size
                    }}
                    fullWidth
                />
            )}
            ListboxProps={{
                sx: {
                    '& .MuiAutocomplete-option': {
                        fontSize: '12px',  // Option text size
                    },
                },
            }}
            getOptionLabel={(option) => option.label || ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
        /></div>
    );
};






export const LongDropdownInput = ({ name, options, value, setValue, defaultValue, className, readOnly, required = false,
    disabled = false, clear = false, tabIndex = null }) => {
    const handleOnChange = (e) => {
        setValue(e.target.value);
    }
    return (
        <div className=' -cols-12 items-center md:my-1 md:px-1 data'>
            <label className={`text-start col-span-2 mb-1 uppercase `}>{required ? <RequiredLabel name={name} /> : `${name}`}</label>
            <select tabIndex={tabIndex ? tabIndex : undefined} defaultValue={defaultValue} id='dd' required={required} name="name"
                className={`border border-gray-400 p-1  h-6 rounded ${className} col-span-10`} value={value} onChange={(e) => { handleOnChange(e); }} disabled={readOnly}>
                <option value="">Select</option>
                {options.map((option, index) => <option key={index} value={option.value} >
                    {option.show}
                </option>)}
            </select>
        </div>
    )
}

export const RadioButton = ({ label, value, onChange, readOnly, className, tabIndex = null }) => {
    return (
        <div className={`flex items-center gap-1 ${className}`}>
            <input type="radio" tabIndex={tabIndex ? tabIndex : undefined} checked={value} onChange={onChange} />
            <label>
                {label}
            </label>
        </div>
    );
};


export const DropdownInputWithoutLabel = ({ options, value, setValue, readOnly, required = false, disabled = false, tabIndex = null }) => {
    const handleOnChange = (e) => {
        setValue(e.target.value);
    }
    return (
        <div className=' -cols-1 md:-cols-3 items-center md:my-1 md:px-1 data'>
            <select tabIndex={tabIndex ? tabIndex : undefined} required={required} name="name" className='input-field md:col-span-2 border col-span-1 rounded' value={value} onChange={(e) => { handleOnChange(e); }} disabled={readOnly}>
                <option value="" hidden>Select</option>
                {options.map((option, index) => <option key={index} value={option.value} >{option.show}</option>)}
            </select>
        </div>
    )
}


export const CurrencyInput = ({ name, value, setValue, readOnly, required = false, disabled = false, tabIndex = null }) => {
    const handleOnChange = (e) => {
        setValue(e.target.value);
    }
    return (
        <div className=' -cols-1 md:-cols-3 items-center md:my-1 md:px-1 data'>
            <label htmlFor="id" className='md:text-start flex mb-1'>{required ? <RequiredLabel name={name} /> : `${name}`}</label>
            <input tabIndex={tabIndex ? tabIndex : undefined} type="number" disabled={disabled} required={required} className='input-field focus:outline-none md:col-span-2 border rounded' min="1" step="any" id='id' value={value} onChange={(e) => { handleOnChange(e); }} readOnly={readOnly} />
        </div>
    )
}

const RequiredLabel = ({ name }) => <p>{`${name}`}<span className="text-red-400">*</span> </p>




export const DateInput = ({
    name,
    value,
    setValue,
    readOnly,
    required = false,
    disabled = false,
    hideLabel,
    dateFormat = "dd/MMM/YYYY",
}) => {
    const [isOpen, setIsOpen] = useState(false); // State to control the date picker

    // Set the minimum date to 1900-01-01
    const minDate = new Date(1900, 0, 1); // January is 0

    return (
        <div className='md:-cols-3 items-center  px-2  data gap-1 '>

            {isOpen && <div className="datepicker-overlay" onClick={() => setIsOpen(false)} />} {/* Overlay to cover background */}
            <DatePicker
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                dateFormat={dateFormat}
                selected={value}
                onChange={(date) => {
                    setValue(date);
                    setIsOpen(false); // Close date picker on select
                }}
                onClick={() => setIsOpen(true)} // Open date picker on click
                className=' border border-gray-300 p-2 md:col-span-2 col-span-1 rounded h-9 w-24'
                placeholderText={name}
                readOnly={readOnly}
                required={required}
                disabled={disabled}
                minDate={minDate} // Set the minimum date
                yearDropdownItemNumber={100}
                popperPlacement="top-end"


            />
        </div>
    );
};

export const CheckBox = ({ name, value, setValue, readOnly = false, className, required = false, disabled = false, tabIndex = null }) => {
    const handleOnChange = (e) => {
        setValue(!value);
    }
    return (
        <div className='items-center md:my-1 md:px-1 data my-3'>
            <label htmlFor="id" className={`md:text-start items-center ${className}`}>
                <input tabIndex={tabIndex ? tabIndex : undefined} type="checkbox" required={required} className='mx-2' checked={value} onChange={(e) => { handleOnChange(e); }} disabled={readOnly} />
                {name}

            </label>
        </div>
    )
}



export const validateEmail = (data) => {
    return validator.isEmail(data);
}

export const validateMobile = (data) => {
    let regMobile = /^[6-9]\d{9}$/;
    return regMobile.test(data);
}

export const validatePan = (data) => {
    let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    return regpan.test(data);
}

export const validatePincode = (data) => {
    return data.toString().length === 6;
}

export const DropdownWithSearch = ({
    options,
    value,
    setValue,
    readOnly,
    searchBy = 'name',
    labelField = 'name',
    valueField = 'id',
    onKeyDown
}) => {

    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '10px',
            height: '10px',
            display: 'flex',
            alignItems: 'center'
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '10px',
            display: 'flex',
            alignItems: 'center'
        })
    };

    return (
        <div>
            {readOnly ? (
                <span>
                    {findFromListWithSearchProperty(value, options, labelField, valueField)}
                </span>
            ) : (
                <ReactSelect
                    isDisabled={readOnly}
                    isSearchable
                    isClearable
                    // styles={customStyles}
                    onChange={(selectedValue) => {
                        setValue(selectedValue ? selectedValue.value : "");
                    }}
                    value={{ value, label: findFromListWithSearchProperty(value, options, labelField, valueField) }}
                    options={multiSelectOption(options, labelField, valueField)}
                />
            )}
        </div>
    );
};