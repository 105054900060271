import React from 'react'
import MedicineDropdown from './MedicineDropdown'
import { DELETE } from '../../../icons'
import MedicineTimingDropdown from '../../../ReusableComponents/MedicineTimingDropdown'
import { DisabledInput } from '../../../Inputs'
import { useGetProductQuery } from '../../../redux/PharmacyServices/ProductMasterService'
import { TabletCosumptionData } from '../../../ReusableComponents/MedicineCosumptionData'

const MedicationRow = ({ index, readOnly, handleInputChange, handleDeleteRow, row }) => {
    const { data } = useGetProductQuery({})
    const medicineList = data?.data || [];

    const selectedMedicine = medicineList.find(medicine => medicine.id === row.productId) || {};


    const defaultValue = '';

    return (
        <tr className="h-full w-full border border-gray-300">
            <td className='border border-gray-300'>
                <MedicineDropdown
                    readOnly={row.patientVisitId}
                    row={row}
                    index={index}
                    handleInputChange={handleInputChange}
                    medicineList={medicineList}
                />
            </td>

            <td className='border border-gray-300'>
                <input
                    className="border rounded px-4 py-2 w-full"
                    type="text"
                    value={selectedMedicine?.productType || defaultValue}
                    readOnly
                />
            </td>

            <td className='border border-gray-300'>
                <input
                    type="text"
                    className="border rounded px-4 py-2 w-full"
                    value={row.unit || defaultValue}
                    disabled={row.patientVisitId}
                    onChange={(event) =>
                        handleInputChange(event.target.value, index, "unit")
                    }
                />
            </td>

            <td className='border border-gray-300'>
                <MedicineTimingDropdown
                    readOnly={row.patientVisitId}
                    row={row}
                    index={index}
                    value={row.medicineTiming || defaultValue}
                    setValue={(value) => handleInputChange(value, index, 'medicineTiming')}
                />
            </td>

            <td className='table-data w-14 border border-gray-300'>
                {selectedMedicine?.productType === "TABLET" ? (
                    <TabletCosumptionData
                        readOnly={row.patientVisitId}
                        index={index}
                        value={row.M || defaultValue}
                        setValue={(value) => handleInputChange(value, index, 'M')}
                    />
                ) : (
                    <input
                        type="text"
                        className="border rounded px-4 py-2 w-full"
                        value={row.M || defaultValue}
                        disabled={row.patientVisitId}
                        onChange={(event) =>
                            handleInputChange(event.target.value, index, "M")
                        }
                    />
                )}
            </td>

            <td className='table-data w-14 border border-gray-300'>
                {selectedMedicine?.productType === "TABLET" ? (
                    <TabletCosumptionData
                        readOnly={row.patientVisitId}
                        index={index}
                        value={row.A || defaultValue}
                        setValue={(value) => handleInputChange(value, index, 'A')}
                    />
                ) : (
                    <input
                        type="text"
                        className="border rounded px-4 py-2 w-full"
                        value={row.A || defaultValue}
                        disabled={row.patientVisitId}
                        onChange={(event) =>
                            handleInputChange(event.target.value, index, "A")
                        }
                    />
                )}
            </td>

            <td className='table-data w-14 border border-gray-300'>
                {selectedMedicine?.productType === "TABLET" ? (
                    <TabletCosumptionData
                        readOnly={row.patientVisitId}
                        index={index}
                        value={row.E || defaultValue}
                        setValue={(value) => handleInputChange(value, index, 'E')}
                    />
                ) : (
                    <input
                        type="text"
                        className="border rounded px-4 py-2 w-full"
                        value={row.E || defaultValue}
                        disabled={row.patientVisitId}
                        onChange={(event) =>
                            handleInputChange(event.target.value, index, "E")
                        }
                    />
                )}
            </td>

            <td className='table-data w-14 border border-gray-300'>
                {selectedMedicine?.productType === "TABLET" ? (
                    <TabletCosumptionData
                        readOnly={row.patientVisitId}
                        index={index}
                        value={row.N || defaultValue}
                        setValue={(value) => handleInputChange(value, index, 'N')}
                    />
                ) : (
                    <input
                        type="text"
                        className="border rounded px-4 py-2 w-full"
                        value={row.N || defaultValue}
                        disabled={row.patientVisitId}
                        onChange={(event) =>
                            handleInputChange(event.target.value, index, "N")
                        }
                    />
                )}
            </td>

            {!readOnly && (
                <td className='border border-gray-300 item-center'>
                    <button className='pl-2' tabIndex={"-1"} onClick={() => handleDeleteRow(index)}>{DELETE}</button>
                </td>
            )}
        </tr>
    );
};

export default MedicationRow;
