import React, { useState } from "react";
import { AppHeader } from "../../components";
import Modal from "../../../UiComponents/Modal";
import { BranchAndFinyearForm, LogoutConfirm } from "../../components";
import ActiveTabList from "../../components/ActiveTabList";
import secureLocalStorage from "react-secure-storage";
import SuperAdminHeader from "../../components/SuperAdminHeader";
import PrintFormatSalesBill from "../../../Pharmacy/Components/PrintFormatSalesBill";
import { PDFViewer } from '@react-pdf/renderer';
import tw from "../../../Utils/tailwind-react-pdf";
const Home = () => {
  const [isGlobalOpen, setIsGlobalOpen] = useState(false);
  const [logout, setLogout] = useState(false);
  const [printModalOpen, setPrintModalOpen] = useState(true);
  const isSuperAdmin = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "superAdmin"
  );
  return (
    <>
      <Modal
        isOpen={isGlobalOpen}
        onClose={() => {
          setIsGlobalOpen(false);
        }}
        widthClass={""}
      >
        <BranchAndFinyearForm setIsGlobalOpen={setIsGlobalOpen} />
      </Modal>
      <Modal
        isOpen={logout}
        onClose={() => {
          setLogout(false);
        }}
        widthClass={""}
      >
        <LogoutConfirm setLogout={setLogout} />
      </Modal>
      <div>
        {isSuperAdmin ? (
          <SuperAdminHeader
            setIsGlobalOpen={setIsGlobalOpen}
            setLogout={setLogout}
          />
        ) : (
          <AppHeader setIsGlobalOpen={setIsGlobalOpen} setLogout={setLogout} />
        )}
        <ActiveTabList />

      </div>
    </>
  );
};
export default Home;
