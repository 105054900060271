import React from 'react';
import { EMPTY_PROFILE_IMAGE, IMAGE_ICON } from '../../../icons';
import { getImageUrlPath } from '../../../Constants';

const SingleImageFileUploadComponent = ({ image, disabled, setWebCam, editProfileImage = true, size = "180px" }) => {
    const imageFormatter = () => {
        if (image) {
            if (image instanceof File) {
                return URL.createObjectURL(image)
            } else {
                return getImageUrlPath(image)
            }
        }
        return null
    }
    const imageWidth = size
    const imageHeight = '160px';
    return (
        <div className='flex gap-1 flex-col items-center mt-2'>
            <div>
                {Boolean(image) ?
                    <img style={{ height: imageHeight, width: imageWidth, objectFit: 'object-cover h-56 w-96' }}
                        src={imageFormatter()}
                        alt=''
                    />
                    :
                    <EMPTY_PROFILE_IMAGE height={imageHeight} width={imageWidth} />
                }
            </div>
            {
                // (editProfileImage && (!disabled)) ?
                <div>
                    <button style={{ width: imageWidth }} className="text-sm w-full border bg-blue-800 p-1 rounded text-white" disabled={disabled} onClick={() => { setWebCam(true) }} htmlFor="profileImage" >{IMAGE_ICON} Profile </button>
                </div>
                // :
                // ""
            }
        </div>
    );
};

export default SingleImageFileUploadComponent;