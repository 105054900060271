import React from 'react'
import { View, Text, Image } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import { ACC_NO, APP_NAME, BANK_NAME, BRANCH_NAME, G_PAY_NO, IFSC_CODE } from '../../../Strings';
import numToWordConverter from "num-words"
import moment from 'moment';
import { findFromList, numberFormatter, substract } from '../../../Utils/helper';
import paymentQr from "../../../assets/paymentQr.jpeg"

const TaxGrid = ({ data }) => {

    function findDays(unit, item) {
        let count = 0;


        if (item?.M) {
            count = count + 1
        }
        if (item?.A) {
            count = count + 1
        }
        if (item?.E) {
            count = count + 1
        }
        if (item?.N) {
            count = count + 1
        }



        return parseInt(parseInt(unit) / parseInt(count))


    }



    // function findReviewDays(array) {
    //     let maxDays = [];
    //     array.forEach(val => {
    //         let days = findDays(val.unit, val)
    //         maxDays.push(days)

    //     })

    //     const maxValue = Math.max(...maxDays);
    //     return maxValue

    // }

    // const amount = taxDetails.reduce((a, c) => a + parseFloat(c.amount || 0), 0);
    // const totalAmount = taxDetails.reduce((a, c) => a + parseFloat(c.amount || 0), 0);
    // const finalAmount = (data?.isTaxBill ? totalAmount : amount);
    // const finalRoundedAmount = Math.round(data?.isTaxBill ? totalAmount : amount);
    // const roundOffAmount = parseFloat(Math.abs(substract(finalAmount, finalRoundedAmount)));
    const size = "70px";
    return (
        <>
            <View style={tw(' border border-gray-500 w-full')} >

                <View style={tw('flex flex-row text-xs bg-gray-300')}>
                    <Text style={[tw('border-r border-b border-gray-500 p-2 w-9'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>
                        S.NO
                    </Text>

                    <Text style={[tw('border-r border-b border-gray-500 text-center p-2 w-80'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                        Description
                    </Text>
                    <Text style={[tw('border-r border-b border-gray-500 text-center p-2 w-12'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                        M
                    </Text>
                    <Text style={[tw('border-r border-b border-gray-500 text-center p-2 w-12'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                        N
                    </Text>
                    <Text style={[tw('border-r border-b border-gray-500 text-center p-2 w-12'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                        E
                    </Text>
                    <Text style={[tw('border-r border-b border-gray-500 text-center p-2 w-12'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                        N
                    </Text>
                    <Text style={[tw('border-r border-b border-gray-500 text-center p-2 w-12'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                        A/B
                    </Text>
                    <Text style={[tw('border-r border-b border-gray-500 text-center p-2 w-24'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                        Qty
                    </Text>


                </View>



                {(data?.Prescriptions || [])?.map((item, index) =>
                    <View style={tw('flex flex-row text-xs')}>
                        <Text style={tw('border-r border-b border-gray-500 p-2 w-9')}>
                            {index + 1}
                        </Text>
                        <Text style={tw('border-r border-b  border-gray-500 p-2 w-80')}>
                            {item?.Product?.name || ""}
                        </Text>
                        <Text style={tw('border-r border-b border-gray-500 p-2 w-12')}>
                            {item?.M}

                        </Text>
                        <Text style={tw('border-r border-b border-gray-500 p-2 w-12 text-right')}>
                            {item?.A}

                        </Text>
                        <Text style={tw('border-r border-b border-gray-500 p-2 w-12 text-right')}>
                            {item?.E}

                        </Text>
                        <Text style={tw('border-r border-b border-gray-500 p-2 w-12 text-right')}>
                            {item?.N}

                        </Text>
                        <Text style={tw('border-r border-b border-gray-500 p-2 w-12 text-right')}>
                            {item?.medicineTiming == "After Food" ? "A" : "B"}

                        </Text>
                        <Text style={tw('border-r border-b border-gray-500 p-2 w-24 text-right')}>
                            {item?.unit || 0}

                        </Text>

                    </View>
                )}


                {/* <View style={tw('flex flex-row text-xs bg-gray-300')}>
                    <Text style={[tw('border-r border-b border-gray-500 p-0.5 w-16'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                    </Text>

                    <Text style={[tw('border-r border-b border-gray-500 p-0.5 w-2/4'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>


                    </Text>
                    <Text style={[tw('border-r border-b border-gray-500 px-2 w-1/4 text-sm text-center mt-2'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                        Bill Amount
                    </Text>
                    <Text style={[tw('border-r border-b border-gray-500 px-2 w-1/4 text-right text-sm mt-2'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>

                        {parseFloat(data?.consultFee || "").toFixed(2)}
                    </Text>

                </View> */}




                {/* <View style={tw('flex flex-row text-xs')}>

                    <Text style={tw('col-span-2  p-2 w-2/4')}>
                        Pay Mode
                    </Text>
                    <Text style={tw('  p-2 w-1/4')}>
                        {data?.paymentMode || ""}({numberFormatter(data?.consultFee?.toFixed(2))})
                    </Text>
                    <Text style={tw('border-r  p-2 w-1/4 ')}>


                    </Text>

                </View> */}




                <View style={tw("flex flex-row justify-start  gap-x-2 w-full  border-t-2  border-b-2 border-gray-500 p-2")} >
                    <View style={tw("flex flex-col w-3/4")}>
                        {/* <View style={tw('flex flex-row text-xs')}>

                            <Text style={tw('p-2 w-4/4 capitalize text-sm')}>
                                Rupees  In Words :  Rs  {numToWordConverter(data?.consultFee) || 0} Only.
                            </Text>

                        </View>

                        <View style={tw('flex flex-row text-xs')}>

                            <Text style={tw('p-2 w-4/4')}>
                                Bill Prepared By {findFromList(userId, userData?.data, "username")} on {moment.utc(data?.createdAt).format("hh:mm A")}
                            </Text>

                        </View> */}

                        <View style={tw('flex flex-row text-xs w-full text-center')}>

                            <Text style={tw('p-2 w-4/4')}>
                                Review Date {moment.utc(data?.reviewDate).format("DD/MM/YYYY")}

                            </Text>

                        </View>



                        <View style={tw('flex flex-row text-xs w-full text-center')}>

                            <Text style={tw('p-2 w-4/4')}>
                                Don't Stop Medication Without Doctor's Advice

                            </Text>

                        </View>

                        <View style={tw('flex flex-row text-xs w-full text-center')}>

                            <Text style={tw('p-2 w-4/4')}>
                                For Emergency care: 0421- 242560
                            </Text>

                        </View>
                    </View>

                    <View style={tw("flex flex-col w-1/4 text-xs")}>
                        <Text style={tw('p-2 w-4/4')}>
                            For KC HOSPITAL

                        </Text>
                        <Text style={tw('p-2 w-4/4')}>


                        </Text>
                        <Text style={tw('p-2 w-4/4')}>
                            Authorized Signatory

                        </Text>

                    </View>
                </View>






            </View>

        </>


    )
}

export default TaxGrid
