import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../../assets/h-logo.png';
import map from '../../../assets/google-maps.png';
import mobile from '../../../assets/mobile.png';
import gmail from '../../../assets/gmail.png';
import internet from '../../../assets/internet-explorer.png';

const styles = StyleSheet.create({
  page: {
    padding: '20px',
    fontSize: '10px',
    fontFamily: 'Helvetica',
    color: '#374151',
    position: 'relative',
  },
  outerContainer: {
    border: '1px solid #E5E7EB',
    borderRadius: '8px',
    padding: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', // Adds shadow effect
  },
  watermark: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    height: '300px',
    opacity: 0.2,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '15px',
    paddingBottom: '5px',
    borderBottom: '1px solid #E5E7EB',
    zIndex: 10,
  },
  logo: {
    width: '70px',
    height: '70px',
  },
  addressContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '5px',
  },
  addressLine: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '3px',
  },
  addressIcon: {
    width: '10px',
    height: '10px',
    marginRight: '4px',
  },
  address: {
    fontSize: '8px',
    color: '#6B7280',
  },
  title: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#2563EB',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    zIndex: 10,
  },
  sectionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px',
    zIndex: 10,
  },
  section: {
    width: '48%',
    padding: '8px',
    backgroundColor: '#F9FAFB',
    borderRadius: '4px',
    border: '1px solid #E5E7EB',
    zIndex: 10,
  },
  sectionHeader: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '8px',
    color: 'white',
    backgroundColor: '#006F36',
    padding: '3px 8px',
    borderRadius: '3px',
    textTransform: 'uppercase',
    letterSpacing: '0.3px',
  },
  fieldGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '6px',
  },
  fieldLabel: {
    width: '45%',
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#374151',
  },
  fieldValue: {
    width: '50%',
    fontSize: '10px',
    textAlign: 'right',
    color: '#1F2937',
  },
  signatureSection: {
    marginTop: '25px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #E5E7EB',
  },
  signatureField: {
    width: '30%',
    textAlign: 'center',
    paddingTop: '15px',
    borderTop: '1px solid #D1D5DB',
    fontSize: '10px',
    color: '#4B5563',
  },
});

const PatientDetailsSection = ({ data }) => (
  <View style={styles.section}>
    <Text style={styles.sectionHeader}>Patient Details</Text>
    <View style={styles.fieldGroup}>
      <Text style={styles.fieldLabel}>Patient Name:</Text>
      <Text style={styles.fieldValue}>{data?.Patient?.name || 'N/A'}</Text>
    </View>
    <View style={styles.fieldGroup}>
      <Text style={styles.fieldLabel}>Patient ID:</Text>
      <Text style={styles.fieldValue}>{data?.Patient?.id || 'N/A'}</Text>
    </View>
    <View style={styles.fieldGroup}>
      <Text style={styles.fieldLabel}>Date of Admission:</Text>
      <Text style={styles.fieldValue}>
  {data?.createdAt ? new Date(data.createdAt).toISOString().split('T')[0] : 'N/A'}
</Text>
    </View>
    <View style={styles.fieldGroup}>
      <Text style={styles.fieldLabel}>Gender:</Text>
      <Text style={styles.fieldValue}>{data?.Patient?.gender || 'N/A'}</Text>
    </View>
  </View>
);

const AdmitDetailsSection = ({ data }) => (
  <View style={styles.section}>
    <Text style={styles.sectionHeader}>Admit Details</Text>
    <View style={styles.fieldGroup}>
      <Text style={styles.fieldLabel}>Room No:</Text>
      <Text style={styles.fieldValue}>{data?.Room?.name || 'N/A'}</Text>
    </View>
    <View style={styles.fieldGroup}>
      <Text style={styles.fieldLabel}>Ward:</Text>
      <Text style={styles.fieldValue}>{data?.Room?.RoomType?.name || 'N/A'}</Text>
    </View>
    <View style={styles.fieldGroup}>
      <Text style={styles.fieldLabel}>Doctor:</Text>
      <Text style={styles.fieldValue}>{data?.PatientVisit?.Employee?.name || 'N/A'}</Text>
    </View>
  </View>
);

const MedicalDetailsSection = ({ data }) => (
  <View style={styles.section}>
    <Text style={styles.sectionHeader}>Medical Details</Text>
    <View style={styles.fieldGroup}>
      <Text style={styles.fieldLabel}>Reason for Admit:</Text>
      <Text style={styles.fieldValue}>{data?.reasonForAdmit || 'N/A'}</Text>
    </View>
    <View style={styles.fieldGroup}>
      <Text style={styles.fieldLabel}>Prescribed Medication:</Text>
      <Text style={styles.fieldValue}>Tylenol, Ibuprofen</Text>
    </View>
  </View>
);
const PatientAddressDetails = ({ data }) => (
  <View style={styles.section}>
    <Text style={styles.sectionHeader}>Patient Address Details</Text>
    <View style={styles.fieldGroup}>
      <Text style={styles.fieldLabel}>Address:</Text>
      <Text style={styles.fieldValue}>{data?.Patient?.address || 'N/A'}</Text>
    </View>
    <View style={styles.fieldGroup}>
      <Text style={styles.fieldLabel}>City: </Text>
      <Text style={styles.fieldValue}>{data?.Patient?.City.name || 'N/A'}</Text>
    </View>
    <View style={styles.fieldGroup}>
      <Text style={styles.fieldLabel}>pinCode: </Text>
      <Text style={styles.fieldValue}>{data?.Patient?.pincode || 'N/A'}</Text>
    </View>
  </View>
);


const PatientAdmitForm = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.outerContainer}>
        <Image style={styles.watermark} src={logo} />

        <View style={styles.header}>
          <Image style={styles.logo} src={logo} />
          <View style={styles.addressContainer}>
            <View style={styles.addressLine}>
              <Image style={styles.addressIcon} src={map} />
              <Text style={styles.address}>33, Railway Feeder Road, Uttukuli Main Road, Tiruppur</Text>
            </View>
            <View style={styles.addressLine}>
              <Image style={styles.addressIcon} src={mobile} />
              <Text style={styles.address}>9150699976</Text>
            </View>
            <View style={styles.addressLine}>
              <Image style={styles.addressIcon} src={gmail} />
              <Text style={styles.address}>thedoctor46.riwan@gmail.com</Text>
            </View>
            <View style={styles.addressLine}>
              <Image style={styles.addressIcon} src={internet} />
              <Text style={styles.address}>www.kchospital.net</Text>
            </View>
          </View>
        </View>

        <Text style={styles.title}>Patient Admit Form</Text>

        <View style={styles.sectionContainer}>
          <PatientDetailsSection data={data} />
          <AdmitDetailsSection data={data} />
        </View>

        <View style={styles.sectionContainer}>
          <PatientAddressDetails data = {data} />
          <MedicalDetailsSection data={data} />
</View>

        <View style={styles.signatureSection}>
          <View style={styles.signatureField}>
            <Text>Doctor's Signature</Text>
          </View>
          <View style={styles.signatureField}>
            <Text>Patient's Signature</Text>
          </View>
          <View style={styles.signatureField}>
            <Text>Date</Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default PatientAdmitForm;
