import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    useGetHsnCodeByIdQuery,
    useAddHsnCodeMutation,
    useUpdateHsnCodeMutation,
    useDeleteHsnCodeMutation,
} from "../../../redux/PharmacyServices/HsnServices";

import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { TextInput, CheckBox } from "../../../Inputs";
import Report from "./Report";
import FormReport from "./FormReport";

const MODEL = "HSN Master";

export default function Form() {
    const [form, setForm] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [hsnCode, setHsnCode] = useState("");
    const [gst, setGst] = useState("");
    const [active, setActive] = useState(true);

    const [searchValue, setSearchValue] = useState("");
    const childRecord = useRef(0);

    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetHsnCodeByIdQuery(id, { skip: !id });

    const [addData] = useAddHsnCodeMutation();
    const [updateData] = useUpdateHsnCodeMutation();
    const [removeData] = useDeleteHsnCodeMutation();

    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            setHsnCode(data?.hsnCode || "");
            setGst(data?.gst || 0);
            setActive(id ? (data?.active ? data.active : false) : true);
        },
        [id]
    );

    useEffect(() => {
        if (id) {
            syncFormWithDb(singleData?.data);
        } else {
            syncFormWithDb(undefined);
        }
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        id, hsnCode,
        gst, active
    }

    const validateData = (data) => {
        if (data.hsnCode && data.gst) {
            return true;
        }
        return false;
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            const returnData = await callback(data).unwrap()
            if (returnData.statusCode === 0) {
                toast.success(text + "Successfully");
                setId("")
                syncFormWithDb(undefined)
            } else {
                toast.error(returnData.message);
            }
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        syncFormWithDb(undefined)
        setReadOnly(false);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }

    if (!form)
        return (
            <Report searchValue={searchValue} setSearchValue={setSearchValue} onNew={onNew} onClick={onDataClick} />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}
                />
                <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
                    <div className="col-span-3 grid md:grid-cols-2 border overflow-auto">
                        <div className='col-span-3 grid md:grid-cols-2 border overflow-auto'>
                            <div className='mr-1 md:ml-2'>
                                <fieldset className='frame my-1'>
                                    <legend className='sub-heading'>Info</legend>
                                    <div className='grid grid-cols-1 my-2'>
                                        <TextInput name="Hsn" type="text" value={hsnCode} setValue={setHsnCode} readOnly={readOnly}
                                            disabled={childRecord.current > 0}
                                        />
                                        <TextInput name="GST" type="number" min={0} value={gst} setValue={setGst} readOnly={readOnly}
                                            disabled={childRecord.current > 0}
                                        />
                                        <CheckBox name="Active" value={active} setValue={setActive} readOnly={readOnly} />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="frame hidden md:block overflow-x-hidden">
                        <FormReport searchValue={searchValue} setSearchValue={setSearchValue} onNew={onNew} onClick={onDataClick} />
                    </div>
                </div>
            </div>
        </div>
    );
}
