import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";
import { BARCODE_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const BarcodeMasterApi = createApi({
  reducerPath: "BarcodeMaster",
  baseQuery,
  tagTypes: ["BarcodeMaster"],
  endpoints: (builder) => ({
    getBarcodeMaster: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: BARCODE_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: BARCODE_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["BarcodeMaster"],
    }),
    getBarcodeMasterById: builder.query({
      query: (id) => {
        return {
          url: `${BARCODE_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["BarcodeMaster"],
    }),
    addBarcodeMaster: builder.mutation({
      query: (payload) => ({
        url: BARCODE_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["BarcodeMaster"],
    }),
    updateBarcodeMaster: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${BARCODE_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["BarcodeMaster"],
    }),
    deleteBarcodeMaster: builder.mutation({
      query: (id) => ({
        url: `${BARCODE_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BarcodeMaster"],
    }),
  }),
});

export const {
  useGetBarcodeMasterQuery,
  useGetBarcodeMasterByIdQuery,
  useAddBarcodeMasterMutation,
  useUpdateBarcodeMasterMutation,
  useDeleteBarcodeMasterMutation,
} = BarcodeMasterApi;

export default BarcodeMasterApi;
