import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useGetPatientQuery, useGetPatientByIdQuery } from '../../../redux/pharmaServices/PatientMaster';
import { PDFViewer } from '@react-pdf/renderer';
import tw from "../../../Utils/tailwind-react-pdf";
import PatientConsultedSelection from './PatientConsultedSelection';
import { useGetPatientVisitByIdQuery } from '../../../redux/pharmaServices/PatientVisitTransaction';
import {
  useGetPatientAdmitQuery,
  useGetPatientAdmitByIdQuery,
  useAddPatientAdmitMutation,
  useUpdatePatientAdmitMutation,
  useDeletePatientAdmitMutation,
} from "../../../redux/pharmaServices/PatientAdmitTransaction";
import { useGetRoomQuery } from '../../../redux/services/RoomMasterService';
import { FormHeader } from '../../../Basic/components';
import { toast } from "react-toastify"
import { DisabledInput, TextInput, TextArea, DropdownInput, DateInput, validateMobile } from '../../../Inputs';
import { genderList, maritalStatusList, bloodList } from '../../../Utils/DropdownData';
import Modal from "../../../UiComponents/Modal"
import io from 'socket.io-client';
import moment from 'moment';
import { findFromList, getCommonParams, getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import SingleImageFileUploadComponent from '../../../Basic/components/SingleImageUploadComponent';
import LiveWebCam from '../../../Basic/components/LiveWebCam';
import useOutsideClick from '../../../CustomHooks/handleOutsideClick';
import { useDispatch } from 'react-redux';
import RoomSelection from './RoomSelectionTable';
import PatientAdmitForm from './printFormat';
import RoomTypeMasterDropdown from '../../../ReusableComponents/RoomTypeMasterDropdown';
import SearchReport from './SearchReport';
import SearchDropDown from '../../../pharma/components/SearchDropDown';
import { dropDownListMergedObject } from '../../../Utils/contructObject';
import { useGetCityQuery } from '../../../redux/services/CityMasterService';
import BarcodeGenerator from '../BarCodeGenerator';
import PatientVisitDropdown from '../../../ReusableComponents/PatientVisitDropdown';


const MODEL = "Patient Admission Form";
const BASE_URL = process.env.REACT_APP_SERVER_URL;

const socket = io.connect(BASE_URL);

export default function Form() {
  const [printModalOpen, setPrintModalOpen] = useState(false);
  const [patientVisitPopUp, setPatientVisitPopUp] = useState(false);
  const [patientVisitId, setPatientVisitId] = useState('');

  const [id, setId] = useState("")
  const [docId, setDocId] = useState("");
  const [roomTypeId, setRoomTypeId] = useState("")
  const [docDate, setDocDate] = useState("");
  const [hideNavBar, sethideNavBar] = useState(true);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const ref = useOutsideClick(() => sethideNavBar(true));
  const [regNo, setregNo] = useState("");
  const [mobile, setMobile] = useState("");
  const [roomId, setRoomId] = useState({});
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState('');
  const [gender, setGender] = useState("");
  const [image, setImage] = useState("");
  const [reasonForAdmit, setReasonForAdmit] = useState("");
  const [doctor, setDoctor] = useState("");
  const [formReport, setFormReport] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const { finYearId, branchId, companyId } = getCommonParams();
  const dispatch = useDispatch()
  const { data: PatientVisitData } = useGetPatientVisitByIdQuery(patientVisitId, { skip: !patientVisitId });
  useEffect(() => {
    if (PatientVisitData && PatientVisitData?.data) {
      setPatientId(PatientVisitData?.data.patientId || "");
    }
  }, [PatientVisitData]);

  const params = { companyId, finYearId, branchId, }
  const { data: patientList, refetch } = useGetPatientQuery({ params: { branchId, mobile, finYearId } });

  const childRecord = useRef(0);

  const { data: roomList } = useGetRoomQuery({ params: { ...params, active: true, roomTypeId, roomAllocation: true, } });
  const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetPatientAdmitByIdQuery(id, { skip: !id });

  const { data: patientData, isLoading: isPatientLoading, isFetching: isPatientFetching } = useGetPatientByIdQuery(patientId, { skip: id && !patientId });

  const [addData] = useAddPatientAdmitMutation();
  const [updateData] = useUpdatePatientAdmitMutation();
  const [removeData] = useDeletePatientAdmitMutation();
  const syncFormWithDb = useCallback(
    (data) => {
      if (id) setReadOnly(true);
      else setReadOnly(false);
      if (data?.docId) {
        setDocId(data?.docId);
      }
      setRoomTypeId(data?.roomTypeId ? data?.roomTypeId : '')
      setRoomId(data?.roomId ? data?.roomId : "")
      setPatientVisitId(data?.patientVisitId || '');
      setDocDate(getDateFromDateTimeToDisplay(data?.createdAt || new Date()));
      setReasonForAdmit(data?.reasonForAdmit ? data.reasonForAdmit : "");
      setDoctor(data?.employeeId ? data.employeeId : "");
      setPatientId(data?.patientId ? data.patientId : "");
      setDob(data?.Patient?.dob ? moment.utc(data?.Patient.dob).format('YYYY-MM-DD') : "");
      setName(data?.Patient?.name ? data?.Patient?.name : "");
      setregNo(data?.Patient?.regNo ? data?.Patient?.regNo : "");
      setMobile(data?.Patient?.mobile ? data?.Patient.mobile : "");
      setFatherName(data?.Patient?.fatherName ? data?.Patient.fatherName : "");
      setGender(data?.Patient?.gender ? data?.Patient.gender : "");
      setMaritalStatus(data?.Patient?.maritalStatus ? data?.Patient.maritalStatus : "");
      setBloodGroup(data?.Patient?.bloodGroup ? data?.Patient.bloodGroup : "");
      setAddress(data?.Patient?.address ? data?.Patient.address : "");
      setPincode(data?.Patient?.pincode ? data?.Patient.pincode : "");
      setImage(data?.Patient?.image || "");
    }, [id])


  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData])

  const { data: docData } = useGetPatientAdmitQuery({ params: { finYearId, branchId, isGetNextDocId: true } });

  const getNextDocId = useCallback(() => {
    if (id) return
    if (docData?.nextDocId) {
      setDocId(docData?.nextDocId)
    }
  }, [docData, id])

  useEffect(getNextDocId, [getNextDocId])


  const syncFormPatientDetails = (data) => {

    // Patient Data
    setDob(data?.dob ? moment.utc(data?.dob).format('YYYY-MM-DD') : "");
    setName(data?.name || '');
    setregNo(data?.regNo || '');
    setMobile(data?.mobile || '');
    setFatherName(data?.fatherName || '');
    setGender(data?.gender || '');
    setMaritalStatus(data?.maritalStatus || '');
    setBloodGroup(data?.bloodGroup || '');
    setAddress(data?.address || '');
    setPincode(data?.pincode ? data.pincode : "");
    setCity(data?.cityId ? data.cityId : "");
    setImage(data?.image || "");
    sethideNavBar(true);
  }
  useEffect(() => {
    if (patientId && patientData?.data) {
      syncFormPatientDetails(patientData?.data);
    } else {
      syncFormPatientDetails(undefined);
    }
  }, [patientId, patientData, isPatientLoading, isPatientFetching, id])



  const data = {
    branchId, docId, name, mobile, id, patientVisitId,
    patientId, roomTypeId, reasonForAdmit,
    roomId, finYearId,
    fatherName, dob,
    address, pincode, gender, maritalStatus, bloodGroup,
    city
  }

  const validateData = (data) => {
    if (data.roomTypeId && data.roomId && data.name && data.mobile) {
      return true;
    }
    return false;
  }


  const handleSubmitCustom = async (callback, data, text) => {
    try {

      const formData = new FormData()
      const out = { ...data };

      for (let key in out) {
        formData.append(key, out[key]);
      }
      if (image instanceof File) {
        formData.append("image", image);
      } else if (!image) {
        formData.append("isImageDeleted", true);
      }
      let returnData;
      if (text === "Updated") {
        returnData = await callback({ id, body: formData }).unwrap();
      } else {
        returnData = await callback(formData).unwrap()
      }
      if (returnData.statusCode === 0) {
        setId('');
        syncFormWithDb(undefined)
        toast.success(text + "Successfully");
        onNew()
      } else {
        toast.error(returnData?.message)
      }
      dispatch({
        type: `roomMaster/invalidateTags`,
        payload: ['Room'],
      });

    } catch (error) {
      console.log("handle");
    }
  };


  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }

    if (!window.confirm("Are you sure save the details ...?")) {
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated")
    } else {
      handleSubmitCustom(addData, data, "Added")
    }
  }
  function onDataClick(id) {
    setId(id);
    onNew();
    setFormReport(false);
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return
      }
      try {
        await removeData(id).unwrap();
        setId("");
        toast.success("Deleted Successfully");
        onNew()
      } catch (error) {
        toast.error("something went wrong")
      }
      ;
    }

  }

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      event.preventDefault();
      saveData();
    }
  }
  const handlePrint = () => {
    setPrintModalOpen(true);
  }

  const onNew = () => {
    syncFormWithDb(undefined);
    setId("");
  }
  const dropDownDataOnclick = (localPatientId) => {
    setPatientId(localPatientId);
  }
  const { data: cityList } = useGetCityQuery({ ...params, branchId, active: true });

  useEffect(() => {
    socket.on('connect', () => {
      console.log("connected")
    });

    socket.on('disconnect', () => {
      console.log("disconnect")
    });
    return () => {
      socket.off('connect');
      socket.off('disconnect');
    }
  }, [])
  const handleInputChange = (e) => {
    setMobile(e.target.value);
    // Show the dropdown if the mobile number is valid
    if (validateMobile(e.target.value)) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };
  const handleFocus = () => {
    setShowDropdown(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
    setTimeout(() => {
      setShowDropdown(false);
    }, 200);
  };
  const handlePatientClick = (patient) => {
    // Logic for what happens when a patient is clicked
    console.log('Selected Patient:', patient);
    setMobile(patient.phone); // Set the mobile input to the selected patient phone
    setShowDropdown(false); // Close the dropdown
    setPatientId(patient.id); // Set the selected patient ID
  };
  return (
    <div onKeyDown={handleKeyDown} className='md:items-start md:justify-items-center grid overflow-auto bg-theme '>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"w-[90%] h-[90%]"} >
        <SearchReport onClick={(id) => { setId(id); setFormReport(false) }} />
      </Modal>
      <Modal isOpen={printModalOpen} onClose={() => setPrintModalOpen(false)} widthClass={"w-[90%] h-[90%]"} >
        <PDFViewer style={tw("w-full h-full")}>
          <PatientAdmitForm data={singleData?.data ? singleData.data : undefined} />
        </PDFViewer>
      </Modal>
      <Modal isOpen={cameraOpen} onClose={() => setCameraOpen(false)}>
        <LiveWebCam picture={image} setPicture={setImage} onClose={() => setCameraOpen(false)} />
      </Modal>
      <Modal
        isOpen={patientVisitPopUp}
        onClose={() => setPatientVisitPopUp(false)}
        widthClass={"px-2 h-[90%] w-[90%]"}
      >
        <PatientConsultedSelection onClick={(id) => { setPatientVisitId(id); setPatientVisitPopUp(false) }} />
      </Modal>
      <div className='flex flex-col frame w-full h-full frame'>
        <FormHeader onNew={onNew} model={MODEL} saveData={saveData} setReadOnly={setReadOnly} onClick={onDataClick}
          deleteData={deleteData} childRecord={childRecord.current} onPrint={id ? handlePrint : null}
          openReport={() => { setFormReport(true) }}
        />
        <div className='col-span-3 grid '>
          <fieldset className=' '>

            <div className="flex w-full   items-center justify-center ">

              <div className="flex flex-col w-[100%]">

                <fieldset className=" w-full h-full">

                  <div className="flex flex-col gap-2 m-2">

                    <fieldset className="flex w-full flex-col frame ">

                      <legend className="sub-heading">Patient Details</legend>

                      <div className='flex'> <div className=" ">
                        <SingleImageFileUploadComponent size="150px" setWebCam={setCameraOpen} disabled={readOnly} image={image} setImage={setImage} />
                        {patientData?.data?.regNo && (
                          <BarcodeGenerator isUi value={patientData?.data?.regNo || ""} />
                        )}
                      </div>
                        <div className="grid grid-cols-2  w-[100%] ">
                          {!readOnly && (
                            <div className="flex  gap-2  px-2 my-2">
                              <button
                                className="bg-sky-800 h-8 w-20 text-white text-xs rounded"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    setPatientVisitPopUp(true);
                                  }
                                }}
                                onClick={() => {
                                  setPatientVisitPopUp(true);
                                }}
                              >
                                Choose OP
                              </button>
                              <div className='flex  w-full'>

                                <div className='flex w-full'>
                                  <div className='w-full flex '><input
                                    name="Mobile No"
                                    type="number"
                                    value={mobile}
                                    setValue={setMobile}
                                    required={true}
                                    readOnly={readOnly}
                                    onChange={handleInputChange}
                                    list="mobile-suggestions"
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    className='border border-gray-400 rounded h-8 w-auto font-semibold text-center'
                                    placeholder='Type Patient Ph No'
                                    autoFocus
                                  /></div>



                                </div>


                                {showDropdown && validateMobile(mobile) && (
                                  <div className="absolute z-10  bg-white border border-gray-400  rounded max-h-60 overflow-auto w-44 mt-8">
                                    {patientList.data
                                      .map(patient => (
                                        <div
                                          key={patient.id}
                                          className="p-2 text-xs cursor-pointer hover:bg-gray-200  border border-gray-300 text-blue-600"
                                          onClick={() => handlePatientClick(patient)}
                                        >
                                          <span className=''>{patient.name}</span>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </div>

                              {/* <div className='w-full'>
                                <PatientVisitDropdown name="Patient Visit" readOnly selected={patientVisitId} />
                              </div> */}

                            </div>
                          )}
                          {!readOnly && (
                            <div className='flex gap-2 '>
                              <div className='w-[60%]'>
                                <DisabledInput name="Patient id" type="text" value={regNo} />

                              </div>

                              <div className='w-[40%]'>  <DisabledInput name="Date" type="text" value={docDate} /></div>

                              <div className=''> <SearchDropDown
                                dataOnClick={dropDownDataOnclick}
                                items={patientList ? patientList?.data : []}
                                hideNavBar={hideNavBar}
                                sethideNavBar={sethideNavBar}
                              /></div>
                            </div>
                          )}

                          <DisabledInput name="Doc Id" type="text" value={docId} className="w-full" />




                          <TextInput
                            name="Name"
                            type="text"
                            value={name}
                            setValue={setName}
                            required={true}
                            readOnly={readOnly}
                            className="w-full"
                          />



                          <TextInput
                            name="Father Name"
                            type="text"
                            value={fatherName}
                            setValue={setFatherName}
                            required={true}
                            readOnly={readOnly}
                            className="w-full"
                          />


                          <div className='flex  w-full'>
                            {/* <TextInput
                              name="Mobile No"
                              type="number"
                              value={mobile}
                              setValue={setMobile}
                              required={true}
                              readOnly={readOnly}
                              className="w-full"
                            /> */}

                            <DateInput
                              name="DOB"
                              value={dob}
                              setValue={setDob}
                              required={true}
                              readOnly={readOnly}
                              className="w-full"
                            />

                            <DropdownInput
                              name="Gender"
                              options={genderList}
                              value={gender}
                              setValue={setGender}
                              required={true}
                              readOnly={readOnly}
                              className="w-full"
                            />
                          </div>
                          <div className='flex gap-2'>


                            <DropdownInput
                              name="Marital Sts"
                              options={maritalStatusList}
                              value={maritalStatus}
                              setValue={setMaritalStatus}
                              required={true}
                              readOnly={readOnly}
                              className="w-full"
                            />

                            <DropdownInput
                              name="B-Group"
                              options={bloodList}
                              value={bloodGroup}
                              setValue={setBloodGroup}
                              readOnly={readOnly}
                              className="w-full"
                            />

                          </div>




                        </div>

                        <div className='flex w-full  gap-2'>
                          <fieldset className='frame w-[50%]  '>
                            <legend className="sub-heading">Address Details</legend>
                            <div className='flex flex-col w-full gap-3'>

                              <div className=' flex flex-col gap-3'>

                                <DropdownInput
                                  name="City/State"
                                  options={dropDownListMergedObject(cityList ? cityList.data : [])}
                                  value={city}
                                  setValue={setCity}
                                  readOnly={readOnly}
                                  className="w-full"
                                />


                                <TextInput
                                  name="Pincode"
                                  type="number"
                                  value={pincode}
                                  setValue={setPincode}
                                  readOnly={readOnly}
                                  className="w-full"
                                />

                              </div>
                              <div className="col-span-1">
                                <TextArea
                                  name="Address"
                                  value={address}
                                  setValue={setAddress}
                                  readOnly={readOnly}
                                  className="w-full"
                                />
                              </div>

                            </div>
                          </fieldset>


                          <fieldset className='frame  w-[50%]'>
                            <legend className='sub-heading'>Admission</legend>
                            <div className='flex flex-col w-full gap-3'>


                              <div className='flex  flex-col gap-3 '>
                                <RoomTypeMasterDropdown readOnly={readOnly} name={"Room Type"}
                                  selected={roomTypeId} setSelected={(value) => { setRoomTypeId(value); setRoomId("") }} />
                                <DisabledInput readOnly={readOnly} name={"Room"} value={findFromList(roomId, roomList?.data || [], "name")} disabled={true} /></div>
                              <div className="col-span-1 ">
                                <TextArea rows={1} name="Reason For Admit" type="text" value={reasonForAdmit} setValue={setReasonForAdmit} readOnly={readOnly} />
                              </div>
                            </div>
                          </fieldset>

                        </div>
                      </div>


                    </fieldset>

                    <div className='border-2 border-gray-200 flex flex-col w'>
                      {roomTypeId &&
                        <fieldset className='frame my-1 m-2'>
                          <legend className='sub-heading '>Room Details</legend>
                          <RoomSelection
                            id={id}
                            selectedRoom={roomId}
                            setSelectedRoom={setRoomId}
                            rooms={(roomList?.data || []).filter(i => i.roomTypeId == roomTypeId)}
                            readOnly={readOnly} />
                        </fieldset>
                      }
                    </div>
                  </div>

                </fieldset>
              </div>
            </div>

          </fieldset>

        </div>
      </div>
    </div>
  )
}
