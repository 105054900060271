import React, { useEffect } from 'react'
import { useGetStockQuery } from '../../../redux/PharmacyServices/StockService'
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import { useState } from 'react';
import { TablePagination } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';

const OutOfStockReport = ({ storeId, locationId }) => {
    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [searchProduct, setSearchProduct] = useState('');

    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    );
    const searchFields = { searchProduct, }

    const { data: expiredData } = useGetStockQuery({ params: { isGetStockExpiryData: true, pagination: true, dataPerPage, pageNumber: currentPageNumber + 1, outOfStock: true, storeId, branchId, ...searchFields } })



    const handleChange = (event, value) => {
        setCurrentPageNumber(value);
    };
    const handleChangeRowsPerPage = (event) => {
        setDataPerPage(parseInt(event.target.value, 10));
        setCurrentPageNumber(0);
    };

    let stockItems = ''
    stockItems = expiredData?.data?.stockItems || [];
    stockItems = stockItems?.filter(item => item.stockQty == 0)
    return (
        <section className="bg-gray-100 py-4 ">

            <div className="text-center mx-auto max-w-screen-2xl">
                <div className="relative bg-white shadow-md ">
                    <div className="overflow-x-auto overflow-auto  mt-3">
                        {stockItems.length > 0 ? <table className="w-full text-xs text-left border border-White border-collapse">
                            <thead className="text-xs uppercase text-white bg-gray-700 dark:bg-gray-700 dark:text-gray-400">
                                <tr className={`sticky top-0  font-thin tracking-wider `}>
                                    <th scope="col" className="p-2 text-center  border border-White font-semibold">S.No.</th>
                                    <th scope="col" className="p-2 text-center  border border-White font-semibold">Name<input
                                        type="text"
                                        className="text-black h-6 focus:outline-none border md:ml-3 border-white rounded-lg"
                                        placeholder="Search"
                                        value={searchProduct}
                                        onChange={(e) => {
                                            setSearchProduct(e.target.value);
                                        }}
                                    /></th>

                                    <th scope="col" className="p-2 text-center  border border-White font-semibold">Stock Qty</th>

                                </tr>
                            </thead>
                            <tbody>
                                {stockItems?.map((stockItem, index) => (
                                    <tr
                                        key={index}
                                        className={`border-b border-White dark:border-gray-600 hover:bg-gray-100  dark:hover:bg-gray-700  bg-slate-200
                                            }`}
                                    >
                                        <td className="p-2 text-center border border-white">{index + 1}</td>
                                        <td className="p-2 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white border border-White">
                                            {stockItem?.productName}
                                        </td>

                                        <td className="p-2 text-right font-medium text-gray-900 whitespace-nowrap dark:text-white border border-White">
                                            {stockItem?.stockQty}
                                        </td>

                                    </tr>
                                ))}



                            </tbody>
                        </table> : <div className='font-bold flex items-center justify-center w-full text-xl h-[50%]'> Select Filter Options to Get Data!!! </div>
                        }

                    </div>
                    <div className='flex items-center justify-end'>
                        <TablePagination
                            component="div"
                            count={totalCount}
                            page={currentPageNumber}
                            onPageChange={handleChange}
                            rowsPerPage={dataPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OutOfStockReport
